// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Components
import { TableCell as MTableCell } from "@material-ui/core";
import { IconButton as MIconButton } from "@material-ui/core";
import { TableRow as MTableRow } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// MARK: Icons
import { Edit as MEditIcon } from "@material-ui/icons";
import { Delete as MDeleteIcon } from "@material-ui/icons";
import { Search as MDetailsIcon } from "@material-ui/icons";
import { Done as MDoneIcon } from "@material-ui/icons";
import { Clear as MClearIcon } from "@material-ui/icons";

export enum ColumnType {
	image,
	avatar,
	text,
	link,
	boolean,
	toggle,
}

export interface IRowItem {
	id: string;
	columns: IColumnItem[];
}

export interface IColumnItem {
	type?: ColumnType;
	// tslint:disable-next-line: max-union-size
	value: string | ILinkValue | boolean | IToggleValue | null;
}

interface ILinkValue {
	src: string;
	name: string;
}

interface IToggleValue {
	label: string;
	state: boolean;
	onChange: (userId: string, ads: boolean) => void;
}

interface IProps {
	rowItem: IRowItem;
	onClick?: (id: string) => void;
	onEditClick?: (id: string) => void;
	onDetailsClick?: (id: string) => void;
	onDeleteClick?: (id: string, name?: string) => void;
	onAccepClick?: (id: string) => void;
	onRejectClick?: (id: string) => void;
}

@observer
export default class TableRow extends React.Component<IProps> {
	/**
	 * Each item of the array is a column value in the in the Table row
	 * with you choose to add a edit/delete buttonm make sure adding -1 item on the
	 * data array, to the row matches with the header size.
	 */

	// tslint:disable-next-line: cognitive-complexity
	public render() {
		const { rowItem, onEditClick, onDeleteClick, onDetailsClick, onClick, onAccepClick, onRejectClick } = this.props;

		return (
			<MTableRow hover={true} tabIndex={-1} onClick={() => onClick && onClick(rowItem.id)}>
				{
					/**
					 * The additional Cell will only be placed if
					 * there is one of the callback functions: "onEditClick"
					 * or "onDeleteClick"
					 */
					(onEditClick || onDeleteClick || onAccepClick || onRejectClick) && (
						<MTableCell
							key={`${rowItem.id}-edit-or-delete`}
							align="center"
						>
							{onEditClick && (
								<MIconButton
									onClick={(event) => {
										event.stopPropagation();
										onEditClick(rowItem.id);
									}}
								>
									<MEditIcon />
								</MIconButton>
							)}
							{onDetailsClick && (
								<MIconButton
									onClick={(event) => {
										event.stopPropagation();
										onDetailsClick(rowItem.id);
									}}
								>
									<MDetailsIcon />
								</MIconButton>
							)}
							{onDeleteClick && (
								<MIconButton
									onClick={() => onDeleteClick(rowItem.id)}
								>
									<MDeleteIcon />
								</MIconButton>
							)}
							{onAccepClick && (
								<MIconButton
									onClick={() => onAccepClick(rowItem.id)}
								>
									<MDoneIcon />
								</MIconButton>
							)}
							{onRejectClick && (
								<MIconButton
									onClick={() => onRejectClick(rowItem.id)}
								>
									<MClearIcon />
								</MIconButton>
							)}
						</MTableCell>
					)}
				{rowItem.columns.map((columnValue, index) => (
					<MTableCell
						className="tableCell"
						key={`${rowItem.id}${index}`}
						align="center"
					>
						{(() => {
							switch (columnValue.type) {
								case ColumnType.image:
								case ColumnType.avatar: {
									return (
										<div className="roundImageContainer">
											<img className="roundImage" src={columnValue.value as string || "/UserPlaceholder.svg"} alt="avatar-image" />
										</div>
									);
								}
								case ColumnType.link: {
									return columnValue.value && (
										<a href={(columnValue.value as ILinkValue).src} target="_blank" rel="noreferrer" onClick={(event) => event.stopPropagation()}>
											{(columnValue.value as ILinkValue).name}
										</a>
									);
								}
								case ColumnType.toggle: {
									return columnValue.value && (
										<FormControlLabel
											control={(
												<Switch
													name="checkedB"
													color="primary"
													checked={(columnValue.value as IToggleValue).state}
													onChange={() => {
														(columnValue.value as IToggleValue).state = !(columnValue.value as IToggleValue).state;
														this.setState({});
													}}
													onClick={() => {
														(columnValue.value as IToggleValue).onChange(rowItem.id, !(columnValue.value as IToggleValue).state);
													}}
												/>
											)}
											label={(columnValue.value as IToggleValue).label}
										/>
									);
								}
								case ColumnType.text: {
									return columnValue.value;
								}
								default: {
									return columnValue.value;
								}
							}
						})()}
					</MTableCell>
				))}
			</MTableRow>
		);
	}
}
