// MARK: React
import * as React from "react";

// MARK: API
import { Credit } from "@startapp/apuama-admin-api";

// MARK: Style
import "./style.scss";

// MARK: Resources
import strings from "../../resources/strings";
import { currencyForBR } from "../../resources/format";

// Mark: LIB
import moment from "moment";

// MARK: Components
import ItemDetail from "../ItemDetail";

interface IProps {
	credit: Credit;
}

export default class CreditDetailsCard extends React.Component<IProps> {
	public render() {
		const { credit } = this.props;
		return (
			<>
				<div className="creditDataContainer">
					<div className="creditContainerData">
						<ItemDetail
							title={strings.fields.id}
							info={credit.id}
						/>
						<ItemDetail
							title={strings.fields.name}
							info={credit.name}
						/>
						<ItemDetail
							title={strings.fields.deadlineDate}
							info={moment(credit.deadlineDate).format(strings.moment.date)}
						/>
						<ItemDetail
							title={strings.fields.endedStatus}
							info={strings.credits.creditDetails.endedStatus(credit.ended)}
						/>
						<ItemDetail
							description={true}
							title={strings.fields.description}
							info={credit.description}
						/>
						{credit.incomeTaxDisclaimer
							&& (
								<ItemDetail
									description={true}
									title={strings.fields.incomeTaxDisclaimer}
									info={credit.incomeTaxDisclaimer}
								/>
							)}
					</div>
				</div>
			</>
		);
	}
}
