// MARK: React
import React from "react";
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MArk: Store
import CreditStore from "../../../../stores/CreditStore";
import CreditsUsersStore from "../../../../stores/CreditsUsersStore";

import { routerStore } from "../../../../stores/_rootStore";

// MARK: Router
import { match } from "react-router";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Components
import { PageTitle } from "../../../../components/PageTitle";
import CreditDetailsCard from "../../../../components/CreditDetailsCard";
import Button from "../../../../components/Button";
import PageTable from "../../../../components/PageTable";

// MARK: Implementation
interface IProps {
	creditStore: CreditStore;
	creditsUsersStore: CreditsUsersStore;
	match: match<{ creditId?: string }>;
}

@inject("creditStore", "creditsUsersStore")
@observer
export default class CreditDetails extends React.Component<IProps> {
	public onAdd = () => {
		const creditId = this.props.match.params.creditId;

		if (creditId) {
			this.props.creditStore.startCreateCreditLog(creditId);
		}
	};

	public componentDidMount = async () => {
		const creditId = this.props.match.params.creditId;

		if (creditId) {
			await this.props.creditStore.getCredit(creditId);
		}

		await this.props.creditStore.getCreditLogs(0, creditId);
		await this.props.creditStore.creditUsersStore.refresh();
	};

	public render() {
		const { creditStore } = this.props;

		return (creditStore.selectedCredit && (
			<div className="creditContainerWrapper">
				<PageTitle text={strings.credits.creditDetails.title(creditStore.selectedCredit.name)} />
				<CreditDetailsCard credit={creditStore.selectedCredit} />
				<div className="creditLogsTable">
					<PageTable
						tableConfig={{
							title: strings.credits.titleLogs,
							loading: creditStore.creditLogsLoading,
							header: creditStore.headerCreditLogs,
							nextPage: creditStore.nextPageCredit,
							prevPage: creditStore.previousPageCredit,
							rows: creditStore.creditLogsTableRows,
							pageOffset: creditStore.pageOffsetCredit,
							onEditClick: (creditLogId) => creditStore.startEditCreditLog(creditLogId),
							onDetailClick: (creditLogId) => creditStore.redirectCreditLogDetails(creditLogId),
							onDeleteClick: (creditLogId) => creditStore.deleteCreditLogs(creditLogId),
							onAddClick: this.onAdd,
						}}
					/>
				</div>
				<div className="creditUsersTable">
					<PageTable
						tableConfig={{
							title: strings.credits.creditUsers,
							loading: creditStore.creditUsersStore.loading,
							header: creditStore.creditUsersStore.tableHeader,
							nextPage: creditStore.creditUsersStore.nextPage,
							prevPage: creditStore.creditUsersStore.previousPage,
							rows: creditStore.creditUsersStore.tableRows,
							pageOffset: creditStore.creditUsersStore.pageOffset,
							onEditClick: (creditUserId) => this.props.creditsUsersStore.startCreateOrEditCreditUser({
								creditUser: creditStore.creditUsersStore.rowItems.find((creditUser) => creditUser.id === creditUserId),
							}),
							onDeleteClick: (creditUserId) => creditStore.creditUsersStore.deleteCreditUser(creditUserId),
							onAddClick: () => this.props.creditsUsersStore.startCreateOrEditCreditUser({ credit: creditStore.selectedCredit }),
						}}
					/>
				</div>
				<div className="buttonContainer">
					<div className="backButton">
						<Button
							type="submit"
							variant="outlined"
							className="backButton"
							color="primary"
							onClick={() => routerStore.push("/dashboard/credits")}
						>
							{strings.register.back}
						</Button>
					</div>
				</div>
			</div>
		));
	}
}
