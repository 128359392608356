// MARK: System Stores
import { RouterStore } from "mobx-react-router";
import UIStore from "./_UIStore";

export const routerStore = new RouterStore();
export const uiStore = new UIStore();

// MARK: Stores
import AuthStore from "./AuthStore";
export const authStore = new AuthStore();

import AdminUsersStore from "./AdminUsersStore";
import UserStore from "./UserStore";
import CreditStore from "./CreditStore";
import CreditsUsersStore from "./CreditsUsersStore";

import ArticlesStore from "./ArticlesStore";

export const adminUsersStore = new AdminUsersStore();
export const userStore = new UserStore();
export const creditStore = new CreditStore();
export const creditsUsersStore = new CreditsUsersStore();

export const articlesStore = new ArticlesStore();

export const rootStore = {
	routerStore,
	uiStore,
	authStore,
	adminUsersStore,
	userStore,
	creditStore,
	articlesStore,
	creditsUsersStore,
};
