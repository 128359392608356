// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Router
import { match } from "react-router";

// MARK: Components
import PageTable from "../../../../components/PageTable";

// MARK: Stores
import UserStore from "../../../../stores/UserStore";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Api
import * as api from "@startapp/apuama-admin-api";

interface IProps {
	userStore: UserStore;
	match: match<{ userId?: string }>;
}

@inject("userStore")
@observer
export default class UsersTable extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.userStore.fetchInitialData();
	}

	public render() {
		const { userStore } = this.props;
		return (
			<div className="usersTableContainer">
				<div className="usersTable">
					<PageTable
						tableConfig={{
							title: strings.users.title,
							loading: userStore.loading,
							header: userStore.tableHeader,
							nextPage: userStore.nextPage,
							prevPage: userStore.previousPage,
							rows: userStore.tableRows,
							pageOffset: userStore.pageOffset,
							onAddClick: () => userStore.startUserCreateOrEdit(),
							onDetailClick: (userId) => userStore.redirectToUserDetails(userId),
							onEditClick: (userId) => userStore.startUserCreateOrEdit(userId),
							onDeleteClick: (userId) => userStore.deleteUser(userId),
						}}
					/>
				</div>
			</div>
		);
	}
}
