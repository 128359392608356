import * as React from "react";
import "./style.scss";

// MARK: Tools
import ImageUploader from "../../resources/models/ImageUploader";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Components
import { CircularProgress as MCircularProgress } from "@material-ui/core";
import { IconButton as MIconButton } from "@material-ui/core";

// MARK: Icons
import { Refresh as MRefreshIcon } from "@material-ui/icons";
import { Delete as MDeleteIcon } from "@material-ui/icons";

interface IProps {
	uploader: ImageUploader;
	removeItem: () => void;
	reloadItem: () => void;
}

@observer
export default class ImageItem extends React.Component<IProps> {

	private renderer = () => {

		const { uploader, reloadItem } = this.props;

		switch (uploader.status) {

			case "uploading":
				return (
					<div className="darkOverlay">
						<MCircularProgress
							variant="static"
							value={uploader.progress}
						/>
					</div>
				);

			case "failed":
				return (
					<div className="darkOverlay">
						<MIconButton onClick={reloadItem}>
							<MRefreshIcon
								style={{ height: 42, width: 42 }}
							/>
						</MIconButton>
					</div>
				);

			case "success":
				return null;

			default:
				return(
					<div className="darkOverlay">
						<MCircularProgress
							value={uploader.progress}
						/>
					</div>
				);
		}
	}

	public render() {

		const { uploader, removeItem } = this.props;

		return (
			<div className="imageItem">
				<img src={uploader.src} alt="file_uploaded" />
				<div className="deleteBtnContainer">
					<MIconButton onClick={removeItem}>
						<MDeleteIcon />
					</MIconButton>
				</div>
				{
					this.renderer()
				}
			</div>
		);
	}
}
