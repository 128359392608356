// MARK: Mobx
import { observer } from "mobx-react";

// MARK: React
import * as React from "react";

// MARK: Material
import Card from "@material-ui/core/Card";

// MARK: Components
import ComponentCard from "../../../ComponentCard";

// Style
import "./style.scss";

export interface IStatsTable {
	label: string;
	value?: string;
	title?: string;
}

interface IProps {
	statsList: IStatsTable[];
	statsLoading?: boolean;
}

@observer
export default class StatsTable extends React.Component<IProps> {
	public render() {

		return (
			<div className="salesContent">
				<div className="salesBlock salesDetailLine">

					{this.props.statsList.map((status) => (
						<Card
							key={status.label}
						>
							<ComponentCard
								key={status.label}
								className="card"
								title={status.title}
								descriptionDate={status.label}
								descriptionLabel={status.value}
								loadingLabel={this.props.statsLoading}
							/>
						</Card>
					))}
				</div>
			</div>
		);
	}
}
