// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Router
import { match } from "react-router";
import { routerStore } from "../../../../stores/_rootStore";

// MARK: Components
import PageTable from "../../../../components/PageTable";

// MARK: Stores
import ArticlesStore from "../../../../stores/ArticlesStore";

// MARK: Resources
import strings from "../../../../resources/strings";

interface IProps {
	articlesStore: ArticlesStore;
	match: match<{ articleId?: string }>;
}

@inject("articlesStore")
@observer
export default class ArticleTable extends React.Component<IProps> {
	private pushToDetails(articleId: string) {
		routerStore.push(`/dashboard/articles/details/${articleId}`);
	}

	public componentDidMount = async () => {
		await this.props.articlesStore.fetchInitialData();
	}

	public render() {
		const { articlesStore } = this.props;
		return (
			<div className="articlesTableContainer">
				<div className="articlesTable">
					<PageTable
						tableConfig={{
							title: strings.articles.title,
							loading: articlesStore.loading,
							header: articlesStore.tableHeader,
							nextPage: articlesStore.nextPage,
							prevPage: articlesStore.previousPage,
							rows: articlesStore.tableRows,
							pageOffset: articlesStore.pageOffset,
							onDetailClick: this.pushToDetails,
							onAddClick: () => articlesStore.startArticleCreateOrEdit(),
							onEditClick: (articleId) => articlesStore.startArticleCreateOrEdit(articleId),
							onDeleteClick: (articleId) => articlesStore.deleteArticle(articleId),
						}}
					/>
				</div>
			</div>
		);
	}
}
