// MARK: React
import React from "react";
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MArk: Store
import CreditStore from "../../../../stores/CreditStore";
import { routerStore } from "../../../../stores/_rootStore";

// MARK: Router
import { match } from "react-router";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Components
import { PageTitle } from "../../../../components/PageTitle";
import CreditLogDetailsCard from "../../../../components/CreditLogDetailsCard";
import Button from "../../../../components/Button";

// MARK: Implementation
interface IProps {
	creditStore: CreditStore;
	match: match<{ creditLogId?: string }>;
}

@inject("creditStore")
@observer
export default class CreditLogDetails extends React.Component<IProps> {
	public componentDidMount = async () => {
		const creditLogId = this.props.match.params.creditLogId;

		if (creditLogId) {
			await this.props.creditStore.getCreditLog(creditLogId);
		}
	};

	public render() {
		const { creditStore } = this.props;

		return (creditStore.selectedCreditLog && (
			<div className="creditLogContainerWrapper">
				<PageTitle text={creditStore.selectedCreditLog.title} />
				<CreditLogDetailsCard creditLog={creditStore.selectedCreditLog} />
				<Button
					type="submit"
					variant="outlined"
					className="backButton"
					color="primary"
					onClick={() => routerStore.push("/dashboard/credits")}
				>
					{strings.register.back}
				</Button>
			</div>
		));
	}
}
