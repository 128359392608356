export const openMultipleInputFileDialog = (onResolve: (files: File[]) => void, onError: (message: string) => void) => {

	const input = document.createElement("input");
	input.setAttribute("multiple", "true");
	input.setAttribute("type", "file");
	input.click();
	input.onchange = () => {

		if (input.files) {

			const files: File[] = [];

			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < input.files.length; i++) {
				files.push(input.files[i]);
			}

			onResolve(files);
		} else {
			onError("Seleção inválida");
		}
	};
};

export const craftFileBuffer = async ( file: Blob, onResolve: (buffer: Buffer) => void) => {
	const reader = new FileReader();

	reader.onload = () => {
		onResolve(Buffer.from((reader.result as string).split(",")[1], "base64"));
	};

	reader.readAsDataURL(file);
};

export const openInputFileDialog = (onResolve: (file: File, url: string) => void, onError: (message: string) => void) => {

	const input = document.createElement("input");
	input.setAttribute("type", "file");
	input.click();
	input.onchange = () => {

		if (input.files && input.files.length > 0) {

			const file = input.files.item[0];
			const url = URL.createObjectURL(file);

			onResolve(file, url);
		} else {
			onError("Arquivo selecionado é inválido");
		}
	};
};
