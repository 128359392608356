// MARK: API
import {ErrorType} from "@startapp/apuama-admin-api";

// MARK: Libraries
import {inspect} from "util";

// MARK: Resources
import strings from "../resources/strings";

export default function handleError(e: any): string {
	if (process.env.NODE_ENV !== "production") {
		// tslint:disable-next-line:no-console
		console.error(inspect(e));
	}

	const apiError: { type?: ErrorType, message?: string } = e;

	if (apiError.message && apiError.type && apiError.type !== ErrorType.Connection && apiError.type !== ErrorType.Fatal) {
		return apiError.message;
	}

	return process.env.NODE_ENV === "production" ? strings.error.default : inspect(e);
}
