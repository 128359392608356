// MARK: React
import React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: API
import * as api from "@startapp/apuama-admin-api";

// MARK: Resources
import strings from "../../../../../resources/strings";

// MARK: Components
import { Modal } from "@material-ui/core";
import TextField from "../../../../../components/TextField";
import Button from "../../../../../components/Button";

// MARK: Stores
import AdminUserPickerStore from "../../../../../stores/UserStore/AdminUserPickerStore";

// MARK: Implementation
interface IProps {
	adminUserPickerStore: AdminUserPickerStore;
	adminUser: api.AdminUser | null;
	selectAdminUser: (adminUser: api.AdminUser) => void;
	disabled: boolean;
}

@observer
export default class AdminUserPicker extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.adminUserPickerStore.getData();
	}

	public render() {
		const { adminUserPickerStore, disabled, adminUser, selectAdminUser } = this.props;
		const { open, toggleOpen, search, handleValueChange } = adminUserPickerStore;

		return (
			<>
				<Modal open={open} onClose={toggleOpen}>
					<div className="adminUserPickerWrapper">
						<div className="serachWrapper">
							<TextField
								className="serachTextField"
								label={strings.fields.name}
								name="search"
								value={search}
								onChange={handleValueChange}
								disabled={disabled}
							/>
							<Button
								type="button"
								variant="outlined"
								color="primary"
								onClick={adminUserPickerStore.refreshData}
								loading={disabled}
							>
								{strings.register.changeAdminUser.filter}
							</Button>
						</div>
						<div className="adminUsersWrapper">
							{adminUserPickerStore.rowItems.map((adminUserRow) => (
								<div key={adminUserRow.id} className="adminUserRow">
									<p>{adminUserRow.name}</p>
									<Button
										type="button"
										variant="outlined"
										color="primary"
										onClick={() => {
											selectAdminUser(adminUserRow);
											adminUserPickerStore.clear();
											adminUserPickerStore.refreshData();
										}}
										loading={disabled}
									>
										{strings.register.changeAdminUser.pick}
									</Button>
								</div>
							))}
							<div className="paginationWrapper">
								<Button
									type="button"
									variant="text"
									color="primary"
									onClick={adminUserPickerStore.previousPage}
									loading={disabled}
								>
									{strings.register.changeAdminUser.previous}
								</Button>
								<p>{adminUserPickerStore.pageOffset + 1}</p>
								<Button
									type="button"
									variant="text"
									color="primary"
									onClick={adminUserPickerStore.nextPage}
									loading={disabled}
								>
									{strings.register.changeAdminUser.next}
								</Button>
							</div>
						</div>
						<Button
							type="button"
							variant="contained"
							color="primary"
							onClick={toggleOpen}
							loading={disabled}
						>
							{strings.register.changeAdminUser.close}
						</Button>
					</div>
				</Modal>
				<div className="selectedUserWrapper">
					<h3>{strings.register.changeAdminUser.title}</h3>
					<div className="selectedUserContent">
						{adminUser && (
							<p>{adminUser.name}</p>
						)}
						<Button
							type="submit"
							variant="outlined"
							color="primary"
							onClick={toggleOpen}
							loading={disabled}
						>
							{adminUser ?
								strings.register.changeAdminUser.change :
								strings.register.changeAdminUser.select}
						</Button>
					</div>
				</div>
			</>
		);
	}
}
