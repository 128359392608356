// MARK: Mobx
import { observer } from "mobx-react";

// MARK: React
import * as React from "react";

// MARK: React
import FilterEnumListService from "../../../../services/FilterService/FilterEnumListService";

// MARK: Services
import "./style.scss";

interface IProps {
	service: FilterEnumListService<any>;
	rowWrapEnum?: boolean;
}

@observer
export default class FilterEnumListPicker extends React.Component<IProps> {
	public render() {
		const { service, rowWrapEnum } = this.props;

		return (
			<div className={"filterEnumContainer"}>
				<div className={rowWrapEnum ? "enumFieldRow" : "enumField"}>
					{service.enumValues().map((value) => (
						<div
							className={service.enumsList.includes(value) ? "selectedEnum" : "enum"}
							key={value}
							role={"button"}
							onClick={() => service.toggleEnum(value)}
						>
							{service.translatorEnum(value)}
						</div>
					))}
				</div>
			</div>
		);
	}
}
