// MARK: React
import * as React from "react";

// MARK: API
import { CreditLog } from "@startapp/apuama-admin-api";

// MARK: Style
import "./style.scss";

// MARK: Resources
import strings from "../../resources/strings";
import { currencyForBR } from "../../resources/format";

// Mark: LIB
import moment from "moment";

// MARK: Components
import ItemDetail from "../ItemDetail";
import ArrayDocDetail from "../ArrayDocDetail";

interface IProps {
	creditLog: CreditLog;
}

export default class CreditLogDetailsCard extends React.Component<IProps> {
	public render() {
		const { creditLog } = this.props;
		return (
			<>
				<div className="creditDataContainer">
					<div className="creditContainerData">
						<ItemDetail
							title={strings.fields.title}
							info={creditLog.title}
						/>
						<ItemDetail
							description={true}
							title={strings.fields.text}
							info={creditLog.text}
						/>
						<ArrayDocDetail
							title={strings.fields.seeMore}
							files={creditLog.attachedFiles}
						/>
					</div>
				</div>
			</>
		);
	}
}
