// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MARK: Components
import { AppBar, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar } from "@material-ui/core";

// MARK: Icons
import {
	Home as HomeIcon,
	ChevronLeft as ChevronLeftIcon,
	CreditCard as CreditIcon,
	ExitToApp as ExitToAppIcon,
	Menu as MenuIcon,
	SupervisorAccountSharp as AdminIcon,
	PersonAdd as UserIcon,
	Description as ArticleIcon,
} from "@material-ui/icons";

// MARK: Resources
import strings from "../../../resources/strings";

// MARK: Stores
import { routerStore } from "../../../stores/_rootStore";
import AuthStore from "../../../stores/AuthStore";

// MARK: Implementation
interface IStores {
	authStore: AuthStore;
}

interface IProps extends IStores {
	hiddenMenu?: boolean;
}

interface IState {
	open: boolean;
}

@inject("authStore")
@observer
export default class Navbar extends React.Component<IProps, IState> {

	public static defaultProps: IStores;

	constructor(props: IProps) {
		super(props);

		this.state = {
			open: false,
		};
	}

	private handleDrawerOpen = () => this.setState({ open: true });
	private handleDrawerClose = () => this.setState({ open: false });

	public onItemClick = (route: string) => {
		routerStore.push(route);
		this.handleDrawerClose();
	};

	public render() {
		const { hiddenMenu } = this.props;
		return (
			<>
				<AppBar elevation={1} position="fixed">
					<Toolbar className="toolbar">
						{!hiddenMenu && (
							<IconButton className="menuIcon" aria-label="Menu" onClick={this.handleDrawerOpen}>
								<MenuIcon className="icon" />
							</IconButton>
						)}
						<div className="logoView">
							<img
								onClick={() => this.onItemClick("/")}
								src={require("../../../../static/ApuamaSimpleLogo.svg")}
								alt="logo"
							/>
						</div>
					</Toolbar>
				</AppBar>
				<Drawer
					variant="persistent"
					anchor="left"
					className="sidedrawer"
					open={this.state.open}
				>
					<div className="closeButtonContainer">
						<IconButton onClick={this.handleDrawerClose}><ChevronLeftIcon className="icon" /></IconButton>
					</div>
					<div className="logoMenu">
						<img src={require("../../../../static/ApuamaLogo.svg")} alt="logo" />
					</div>
					<List>
						<ListItem
							button={true}
							onClick={() => this.onItemClick("/dashboard")}
						>
							<ListItemIcon><HomeIcon className="icon" /></ListItemIcon>
							<ListItemText primary={strings.nav.home} className="iconText" />
						</ListItem>
						<ListItem
							button={true}
							onClick={() => this.onItemClick("/dashboard/adminUsers")}
						>
							<ListItemIcon><AdminIcon className="icon" /></ListItemIcon>
							<ListItemText className="iconText" primary={strings.adminUsers.title} />
						</ListItem>
						<ListItem
							button={true}
							onClick={() => this.onItemClick("/dashboard/users")}
						>
							<ListItemIcon><UserIcon className="icon" /></ListItemIcon>
							<ListItemText className="iconText" primary={strings.users.title} />
						</ListItem>
						<ListItem
							button={true}
							onClick={() => this.onItemClick("/dashboard/credits")}
						>
							<ListItemIcon><CreditIcon className="icon" /></ListItemIcon>
							<ListItemText className="iconText" primary={strings.credits.title} />
						</ListItem>
						<ListItem
							button={true}
							onClick={() => this.onItemClick("/dashboard/articles")}
						>
							<ListItemIcon><ArticleIcon className="icon" /></ListItemIcon>
							<ListItemText className="iconText" primary={strings.articles.title} />
						</ListItem>
						<ListItem
							button={true}
							onClick={this.props.authStore.logout}
						>
							<ListItemIcon><ExitToAppIcon className="icon" /></ListItemIcon>
							<ListItemText primary={strings.nav.logout} className="iconText" />
						</ListItem>
					</List>
				</Drawer>
			</>
		);
	}
}
