// MARK: API
import * as api from "@startapp/apuama-admin-api";

// MARK: Mobx
import { observable, action, computed } from "mobx";
import handleError from "../../resources/handleError";

// MARK: Stores
import { uiStore, routerStore } from "../_rootStore";

// MARK: Resources
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import strings from "../../resources/strings";

export default class AuthStore extends VariableChangeHandler {
	// Control
	@observable public adminUser: api.AdminUser | null = null;

	// Variables
	@observable public loading: boolean = false;
	@observable public email: string = "";
	@observable public password: string = "";

	// Recovery
	@observable public newPassword: string = "";
	@observable public confirmNewPassword: string = "";

	@observable public receivedToken: string = "";

	constructor() {
		super();

		const adminUser = localStorage.getItem("adminUser");
		this.adminUser = adminUser ? JSON.parse(localStorage.getItem("adminUser") as string) : null;
	}

	@computed
	public get isLoginFormReady() {
		return this.email.length > 5 && this.password.length >= 6;
	}

	@action
	public login = async (navigate: boolean = true) => {
		this.loading = true;

		try {
			this.adminUser = await api.login(
				this.email.trim(),
				this.password.trim(),
			);
			localStorage.setItem("adminUser", JSON.stringify(this.adminUser));

			if (navigate) {
				routerStore.push("/");
			}
			this.clear();
		} catch (e) {
			uiStore.showSnackbar(handleError(e));
		} finally {
			this.loading = false;
		}
	};

	@action
	public logout = async () => {
		const onConfirm = async () => {
			try {
				await api.logout();

				this.adminUser = null;
				localStorage.clear();

				routerStore.push("/login");
			} catch (e) {
				uiStore.showErrorSnackbar(e);
			}
		};

		uiStore.showDialog(
			strings.logoutDialog,
			onConfirm,
		);
	};

	@action
	public loginNewAdminUser = async (adminUser: api.NewAdminUser | null) => {
		if (this.loading) {
			return;
		}

		this.loading = true;

		if (adminUser) {
			this.email = adminUser.email;
			this.password = adminUser.password;
		} else {
			return;
		}

		try {
			await this.login(false);
		} catch (e) {
			uiStore.showSnackbar(handleError(e));
		} finally {
			this.loading = false;
		}
	}

	@action
	public isLogged = async () => {
		if (this.adminUser) {
			return !!this.adminUser;
		}

		this.loading = true;

		let adminUser: api.AdminUser | null = null;

		try {
			adminUser = await api.getCurrentAdminUser();
		} catch (e) {
			localStorage.clear();
		} finally {
			this.loading = false;
			this.adminUser = adminUser;
		}

		return !!this.adminUser;
	};

	@action
	public authenticate = async () => {
		if (!(await this.isLogged())) {
			routerStore.replace("/login");
		}
	};

	@action
	public openDashboardIfAuthenticated = async () => {
		if (await this.isLogged()) {
			routerStore.replace("/dashboard");
		}
	};

	@action
	public resetPassword = async () => {
		this.loading = true;

		try {
			await api.sendResetPasswordEmail(this.email);

			uiStore.showAlert({
				title: strings.recoverPassword.confimationDialog.title,
				message: strings.recoverPassword.confimationDialog.message,
			}, () => {
				routerStore.push("/login");
			});
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	};

	@action
	public sendNewPassword = async () => {
		if (this.loading) {
			return;
		}

		this.loading = true;

		try {
			await api.resetPasswordEmail(this.receivedToken, this.newPassword);
			routerStore.push("/login");
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	}

	@action
	private clear = () => {
		this.email = "";
		this.password = "";
	}
}
