// MARK: React
import * as React from "react";

// MARK: API
import * as api from "@startapp/apuama-admin-api";

// MARK: Components
import DocDetail from "../DocDetail";

interface IProps {
	files: api.File[];
	title: string;
}
export default class ArrayDocDetail extends React.Component<IProps> {
	public render() {
		const { files, title } = this.props;
		return (
			<>
				{files.length > 0 &&
					(
						<>
							{files.map((file) => (
								<DocDetail
									title={file.name}
									url={file && file.url}
									key={file.name}
									name={title}
								/>
							))}
						</>
					)
				}
			</>
		);
	}
}
