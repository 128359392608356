// MARK: React
import React from "react";
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MArk: Store
import UserStore from "../../../../stores/UserStore";
import CreditStore from "../../../../stores/CreditStore";
import CreditsUsersStore from "../../../../stores/CreditsUsersStore";

// MARK: Router
import { match } from "react-router";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Components
import { PageTitle } from "../../../../components/PageTitle";
import UserDetailsCard from "../../../../components/UserDetailsCard";
import PageTable from "../../../../components/PageTable";

// MARK: Implementation
interface IProps {
	userStore: UserStore;
	creditStore: CreditStore;
	creditsUsersStore: CreditsUsersStore;
	match: match<{ userId?: string }>;
}

@inject("userStore", "creditStore", "creditsUsersStore")
@observer
export default class UserDetails extends React.Component<IProps> {
	public componentDidMount = async () => {
		const userId = this.props.match.params.userId;

		if (userId) {
			await this.props.userStore.getUser(userId);
		}

		await this.props.userStore.creditsUserStore.refresh();
	};

	public render() {
		const { userStore, creditStore } = this.props;

		return (userStore.selectedUser && (
			<div className="userContainerWrapper">
				<PageTitle text={strings.users.userDetails.title(userStore.selectedUser.name)} />
				<UserDetailsCard user={userStore.selectedUser} />
				<div className="creditUsersTable">
					<PageTable
						tableConfig={{
							title: strings.credits.userCredits,
							loading: userStore.creditsUserStore.loading,
							header: userStore.creditsUserStore.tableHeader,
							nextPage: userStore.creditsUserStore.nextPage,
							prevPage: userStore.creditsUserStore.previousPage,
							rows: userStore.creditsUserStore.tableRows,
							pageOffset: userStore.creditsUserStore.pageOffset,
							onEditClick: (creditUserId) => this.props.creditsUsersStore.startCreateOrEditCreditUser({
								creditUser: userStore.creditsUserStore.rowItems.find((creditUser) => creditUser.id === creditUserId),
							}),
							onDeleteClick: (creditUserId) => creditStore.deleteCreditUser(creditUserId),
							onAddClick: () => this.props.creditsUsersStore.startCreateOrEditCreditUser({ user: userStore.selectedUser }),
						}}
					/>
				</div>
			</div>
		));
	}
}
