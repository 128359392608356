import * as React from "react";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import AuthStore from "../../stores/AuthStore";
import UIStore from "../../stores/_UIStore";

// MARK: Layout
import MainLayout from "../../layouts/MainLayout";

// MARK: Components
import { CircularProgress } from "@material-ui/core";

// MARK: Pages
import Home from "./Home";
import AdminUsers from "./AdminUsers";
import Users from "./Users";
import Articles from "./Articles";
import Credits from "./Credits";
import CreditsUsers from "./CreditsUsers";

interface IProps {
	authStore: AuthStore;
	uiStore: UIStore;
}

@inject("authStore", "uiStore")
@observer
export default class Dashboard extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.authStore.authenticate();
	};

	public render() {
		return (
			<MainLayout hiddenLanguageSelector={true}>
				<Switch>
					<Route exact={true} path="/dashboard" component={Home} />
					<Route path="/dashboard/adminUsers" component={AdminUsers}/>
					<Route path="/dashboard/users" component={Users}/>
					<Route path="/dashboard/credits" component={Credits}/>
					<Route path="/dashboard/creditsUsers" component={CreditsUsers}/>
					<Route path="/dashboard/articles" component={Articles}/>
				</Switch>
				{this.props.uiStore.circularLoading && <CircularProgress />}
			</MainLayout>
		);
	}
}
