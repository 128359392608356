// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Components
import BaseLayout from "../BaseLayout";
import Navbar from "./Navbar";

interface IProps {
	hiddenMenu?: boolean;
	hiddenLanguageSelector?: boolean;
}

export default class MainLayout extends React.Component<IProps> {
	public render() {
		const { children, hiddenMenu } = this.props;

		return (
			<div>
				<div className="mainLayoutContainer">
					<Navbar hiddenMenu={hiddenMenu}/>
					<div className="mainLayoutChildrenContainer">
						{children}
					</div>
				</div>
				<BaseLayout />
			</div>
		);
	}
}
