// MARK: Api
import * as api from "@startapp/apuama-admin-api";

// MARK: Mobx
import { observable, action } from "mobx";

// MARK: Stores
import { uiStore, routerStore } from "../_rootStore";

// MARK: Resources
import TableDataStore from "../../resources/TableDataStore";
import strings from "../../resources/strings";

// MARK: Components
import { IRowItem } from "../../components/Table/TableRow";

// MARK: Implementation
export default class AdminUsersStore extends TableDataStore<api.AdminUser> {
	// MARK: Controls
	@observable public adminUsersLoading: boolean = false;
	@observable public tableLoading: boolean = false;
	@observable public pageOffSet: number = 0;

	// MARK: Variables

	// SelectedAdminUser
	@observable public selectedAdminUser: api.AdminUser | null = null;

	// Admin Users
	@observable public adminUsers: api.AdminUser[] = [];

	// Create or Edit
	@observable public name: string = "";
	@observable public email: string = "";
	@observable public phone: string = "";
	@observable public whatsapp: string = "";
	@observable public password: string = "";

	// MARK: Methods
	protected formatTableHeader(): string[] {
		const header = strings.fields;
		return [
			header.id,
			header.name,
			header.email,
			header.phone,
			header.whatsapp,
		];
	}

	protected formatDataToRow(rowItem: api.AdminUser): IRowItem {
		return ({
			id: rowItem.id,
			columns: [
				{ value: rowItem.id },
				{ value: rowItem.name },
				{ value: rowItem.email },
				{ value: rowItem.phone ? rowItem.phone : strings.fields.notRegistered },
				{ value: rowItem.whatsapp ? rowItem.whatsapp : strings.fields.notRegistered },
			],
		});
	}
	protected getDataItemsPerPage(pageOffset: number): Promise<api.AdminUser[]> {
		return api.getAdminUsers(pageOffset, null);
	}
	protected getSingleItemById(id: string): Promise<api.AdminUser> {
		throw new Error("Method not implemented.");
	}

	@action
	public getAdminUser = async (adminUserId: string) => {
		if (this.adminUsersLoading) {
			return;
		}

		this.adminUsersLoading = true;

		try {
			this.selectedAdminUser = await api.getAdminUser(adminUserId);
			this.setEditorFields(this.selectedAdminUser);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.adminUsersLoading = false;
		}
	};

	@action
	public createOrEditAdminUser = async () => {
		if (this.adminUsersLoading) {
			return;
		}

		this.adminUsersLoading = true;

		try {
			if (this.selectedAdminUser) {
				const editAdminUser: api.EditAdminUser = {
					name: this.name,
					email: this.email,
					phone: this.phone === "" ? null : this.phone.replace(/[^0-9]/g, "").trim(),
					whatsapp: this.whatsapp === "" ? null : this.whatsapp.replace(/[^0-9]/g, "").trim(),
				};
				await api.editAdminUser(this.selectedAdminUser.id, editAdminUser);
				uiStore.showSnackbar(strings.register.edited);
			} else {
				const newAdminUser: api.NewAdminUser = {
					name: this.name,
					email: this.email,
					phone: this.phone === "" ? null : this.phone.replace(/[^0-9]/g, "").trim(),
					whatsapp: this.whatsapp === "" ? null : this.whatsapp.replace(/[^0-9]/g, "").trim(),
					password: this.password,
				};
				await api.createAdminUser(newAdminUser);
				uiStore.showSnackbar(strings.register.success);
			}
			routerStore.replace("/dashboard/adminUsers");
			this.clear();
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.adminUsersLoading = false;
		}
	}

	@action
	public startAdminUserCreateOrEdit = (adminUserId?: string) => {
		if (adminUserId) {
			routerStore.push(`/dashboard/adminUsers/editor/${adminUserId}`);
		} else {
			this.clear();
			this.clearAdminUser();
			routerStore.push("/dashboard/adminUsers/editor");
		}
	}

	@action
	public setEditorFields = (adminUser: api.AdminUser) => {
		this.clear();
		this.name = adminUser.name;
		this.email = adminUser.email;
		if (adminUser.whatsapp) {
			this.whatsapp = adminUser.whatsapp;
		}
		if (adminUser.phone) {
			this.phone = adminUser.phone;
		}
	}

	@action
	public deleteAdminUser = async (adminUserId: string) => {

		if (this.adminUsersLoading) {
			return;
		}

		this.adminUsersLoading = true;

		try {
			uiStore.showDialog(
				strings.adminUsers.deleteDialogAdmin,
				async () => {
					try {
						await api.deleteAdminUser(adminUserId);
						this.rowItems = this.rowItems.filter((admin) => admin.id !== adminUserId);
					} catch (e) {
						uiStore.showErrorSnackbar(e);
					}
				},
			);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.adminUsersLoading = false;
		}
	}

	@action
	public clearAdminUser = () => {
		this.selectedAdminUser = null;
	}

	@action
	public clear = () => {
		this.name = "";
		this.email = "";
		this.password = "";
		this.phone = "";
		this.whatsapp = "";
	}
}
