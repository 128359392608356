import LocalizedStrings from "localized-strings";
import * as api from "@startapp/apuama-admin-api";

// MARK: Implementation
const strings = new LocalizedStrings({
	ptBR: {
		percentage: (value: number) => {
			const formattedValue = strings.currency(value, { showSymbol: false });

			return formattedValue.concat("%");
		},
		currency: (cents: number, options?: { showSymbol?: boolean, withCents?: boolean }) => {
			const withCents = options === undefined
				|| options.withCents === undefined
				|| options.withCents;
			const showSymbol = options === undefined
				|| options.showSymbol === undefined
				|| options.showSymbol;

			const numeroTokens: string[] = `${withCents ? (cents / 100).toFixed(2) : Math.round(cents / 100)}`.split(".");
			numeroTokens[0] = `${showSymbol ? "R$ " : ""}${numeroTokens[0].split(/(?=(?:...)*$)/).join(".")}`;
			return numeroTokens.join(",");
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		fields: {
			fulfilledAmount: "Valor Bruto",
			fulfilledTaxAmount: "Valor Impostos",
			fulfilledDate: "Data de Finalização",
			fulfilled: "Finalizado",
			investedAt: "Data do Investimento",
			type: "Tipo",
			url: "Url",
			id: "Id",
			image: "Imagem",
			images: "Imagens",
			phone: "Telefone",
			whatsapp: "Whatsapp",
			name: "Nome",
			credit: "Crédito",
			cpfOrCnpj: "CPF ou CNPJ",
			seeMore: "Veja mais",
			exit: "Sair",
			notRegistered: "Não cadastrado",
			save: "Salvar",
			email: "Email",
			agencyDV: "Dígito da Agência",
			agency: "Agência",
			bank: "Banco",
			account: "Conta",
			accountDV: "Dígito da Conta",
			text: "Texto",
			title: "Título",
			endedStatus: "Status do Crédito",
			ended: "Crédito Finalizado?",
			investedAmount: "Valor Investido",
			withdrawalAmount: "Valor a Levantar",
			amountRaised: "Valor Levantado",
			periodResult: "Resultado do Período",
			incomeTaxDisclaimer: "Imposto de Renda",
			description: "Descrição",
			deadlineDate: "Data de Prazo",
			startDate: "Iniciado em",
			sold: "Vendido",
			expectedReturn: "Percentual de retorno esperado",
			soldCredit: (sold: boolean) => sold ? "Sim" : "Não",
			user: "Usuário",
		},
		dialogs: {
			deleteItem: {
				title: "Apagar Item",
				message: "Tem certeza que deseja apagar esse item?",
			},
			delete: {
				title: "Confirmação de exclusão",
				message: "Deseja mesmo excluir este usuário?",
			},
			operationHasBeenASuccess: "Operação realizada com sucesso.",
		},
		imageCase: {
			addImagePlaceholder: "Clique em adicionar para escolher uma imagem",
			addImagesPlaceholder: "Clique em adicionar para escolher uma ou mais imagens",
			addFilePlaceholder: "Clique em adicionar para escolher um arquivo",
			addFilesPlaceholder: "Clique em adicionar para escolher um ou mais arquivos",
			addImageBtn: "Adicionar",
			missingImage: "A imagem é obrigatória",
		},
		logoutDialog: {
			title: "Logout",
			message: "Tem certeza que deseja sair?",
		},
		loading: "Carregando",
		login: "Login",
		recoverPassword: {
			confimationDialog: {
				title: "Recuperação de Senha",
				message: "Caso esse email pertença a um administrador, o link de recuperação de senha foi enviado com sucesso",
			},
			recoverPasswordButton: "Recuperar Senha",
			newPasswordButton: "Alterar Senha",
			backButton: "Voltar",
			email: "Email",
			newPassword: "Nova senha",
			confirmNewPassword: "Confirme sua nova senha",
		},
		// tslint:disable-next-line: no-hardcoded-credentials
		password: "Senha",
		recovery: "Recuperar Senha",
		components: {
			table: {
				noMoreResults: "Sem mais resultados",
				create: "Criar",
				delete: "Deletar",
				edit: "Editar",
				export: "Exportar",
				// tslint:disable-next-line: no-duplicate-string
				editAndDelete: "Editar / Deletar",
				detailsAndDelete: "Detalhes / Deletar",
				detailsAndEdit: "Detalhes / Editar",
				detailsAndEditAndDelete: "Editar / Detalhes / Delete",
				AcceptAndReject: "Aceitar / Recusar",
				AcceptAndRejectaandDetails: "Detalhes / Aceitar / Recusar ",
				Active: "Ativo",
				deletedSuccessfully: "Deletado com sucesso",

				title: {
					isActive: "Ativo atualmente, clique para desativar.",
					isNotActive: "Desativado atualmente, clique para ativar.",
					selectedColumns: "Colunas Selecionadas",
					availableColumns: "Colunas Disponíveis",
				},

				titleIcon: {
					openFilter: "Abrir Opções de Filtragem",
					exportCSV: "Exportar CSV",
					editTable: "Editar Tabela",
				},
			},
			datePicker: {
				format: "dd/MM/yyyy",
				invalidDate: "Data inválida",
			},
		},
		languages: {
			title: "Idiomas",
			ptBr: "Português",
			enUs: "Inglês",
		},
		cancel: "Cancelar",
		confirm: "Confirmar",
		ok: "Ok",
		logo: {
			alt: "Logo Alt Aqui!",
			squared: "/logo_squared.png",
			horizontalWhite: "/logo_horizontal_white.png",
		},
		error: {
			default: "Houve um erro, tente novamente mais tarde.",
			audioError: "Para cadastrar um treino insira um audio.",
			emptyDate: "Houve um erro, data incorreta",
		},
		nav: {
			title: "Apuama",
			home: "Inicio",
			users: "Usuários",
			adminUsers: "Usuários Admin",
			logout: "Sair",
		},
		home: {
			welcome: "Bem vindo ao dashboard do Apuama",
		},
		articles: {
			title: "Artigos",
			imageHeader: "Imagem da Capa",
			editorTitle: (editing: boolean) => `${editing ? "Editar" : "Criar"} Artigo`,
			deleteArticleDialog: {
				title: "Deletar Artigo",
				message: "Tem certeza que deseja deletar este artigo?",
			},
			successCreated: "Artigo criado com sucesso",
			successEdited: "Artigo editado com sucesso",
		},
		users: {
			title: "Usuários",
			titleCredit: (name: string) => `Crédito de ${name}`,
			pendingTitle: "Advogados Pendentes",
			noImage: "A imagem é obrigatória",
			noFile: "O Arquivo é obrigatória ",
			deleteDialogUser: {
				title: "Deletar Usuário",
				message: "Tem certeza que deseja deletar este usuário?",
			},
			userDetails: {
				title: (name: string) => `Página de ${name}`,
			},
			table: {
				header: {
					id: "Id",
					finish: "Finalizado",
					image: "Imagem",
					whatsapp: "Whatsapp",
					name: "Nome",
					email: "Email",
					date: "Data",
					phone: "Telefone",
					birthday: "Nascimento",
					gender: "Gênero",
					plan: "Plano",
					edit: "Editar / Excluir",
					status: "Status",
					ads: "Ads",
					seeMore: "Veja mais",
					description: "Descrição",
					price: "Preço",
					comment: "Comentário",
				},
			},
		},
		message: {
			noMoreResults: "Sem mais resultados",
		},
		pendingLawyerPage: {
			uiMessage: (value: boolean) => `Usuário ${value === true ? "aceito " : "recusado "} com sucesso`,
		},
		buttons: {
			edit: "Editar",
			save: "Salvar",
			filter: "Filtrar",
			close: "Fechar",
			search: "Procurar",
		},
		filter: {
			title: "Filtro",
			dateStart: "Inicio",
			dateEnd: "Fim",
			addFilter: "Adicionar um filtro",
			statusFilterTitle: "Status do Crédito",
			nameFilterTitle: "Nome",
			emailFilterTitle: "Email",
			textField: {
				apply: "Aplicar",
				add: "Adicionar",
				clear: "Limpar",
			},
			autosuggest: {
				apply: "Aplicar",
				add: "Adicionar",
			},
			components: {
				filterText: {
					addText: "Adicionar",
				},
			},
			enumPicker: {
				apply: "Aplicar",
				add: "Adicionar",
			},
			badges: {
				statusActive: (value: boolean | null) => `${value === false ? "Status: Em andamento" : "Status: Finalizado"}`,
				endedFilter: (value: boolean | null) => `${value === false ? "Crédito: Em andamento" : "Crédito: Finalizado"}`,
				name: (text: string) => `Nome: ${text}`,
				email: (text: string) => `Email: ${text}`,
			},
			filter: "Filtrar",
		},
		register: {
			changeAdminUser: {
				title: "Assessor",
				filter: "Filtrar",
				select: "Selecionar Assessor",
				change: "Alterar Assessor",
				previous: "Anterior",
				next: "Próxima",
				close: "Fechar",
				pick: "Escolher",
			},
			missingAdmin: "Assessor não selecionado",
			title: "Seus Dados",
			documentNumber: "CPF ou CNPJ",
			phone: "Telefone",
			permissions: "Permissões",
			payment: {
				title: "Pagamento",
				cardNumber: "Número do Cartão",
				holderName: "Nome do Titular",
				cvv: "Código de Segurança",
				expirationDate: "Data de Validade",
				plans: "Planos",
			},
			company: "Sua Empresa",
			create: "Criar Conta",
			back: "Voltar",
			next: "Continuar",
			finish: "Concluir",
			finishCredit: "Concluir Crédito",
			resumeCredit: "Retomar Crédito",
			creditCompletedSucess: "Crédito concluído com sucesso",
			creditLogSucess: "Aviso concluído com sucesso",
			creditResumeSucess: "Crédito retomado com sucesso",
			createCredit: "Criar crédito",
			registered: "Conta criada com sucesso",
			success: "Administrador criado com sucesso",
			successUser: "Usúario criado com sucesso",
			successCredit: "Crédito criado com sucesso",
			successEditCredit: "Crédito editado com sucesso",
			edited: "Administrador editado com sucesso",
			editedUser: "Usúario editado com suscesso",
		},
		creditsUsers: {
			editor: {
				getTitle: (onEditMode = true) => onEditMode ? "Editar Crédito do Usuário" : "Novo Crédito do Usuário",
				changeCredit: {
					title: "Crédito",
					filter: "Filtrar",
					select: "Selecionar Crédito",
					change: "Alterar Crédito",
					previous: "Anterior",
					next: "Próxima",
					close: "Fechar",
					pick: "Escolher",
				},
				changeUser: {
					title: "Usuário",
					filter: "Filtrar",
					select: "Selecionar Usuário",
					change: "Alterar Usuário",
					previous: "Anterior",
					next: "Próxima",
					close: "Fechar",
					pick: "Escolher",
				},
				errors: {
					missingUser: "Usuário não selecionado",
					missingCredit: "Crédito não selecionado",
				},
			},
		},
		credits: {
			title: "Créditos",
			titleLogs: "Avisos",
			creditUsers: "Usuários Vinculados",
			userCredits: "Créditos Vinculados",
			create: "Criar Crédito",
			editor: {
				getTitle: (onEditMode = true) => onEditMode ? "Editar Crédito" : "Novo Crédito",
			},
			deleteDialogCredit: {
				title: "Deletar Crédito",
				message: "Tem certeza que deseja deletar este Crédito?",
			},
			deleteDialogCreditLogs: {
				title: "Deletar Aviso",
				message: "Tem certeza que deseja deletar este Aviso?",
			},
			filters: {
				isActive: "Finalizado",
				isNotActive: "Em andamento",
			},
			creditDetails: {
				title: (name: string) => `Crédito de ${name}`,
				endedStatus: (value: boolean) => value ? `Finalizado` : `Em andamento`  ,
			},
		},
		adminUsers: {
			title: "Administradores",
			table: {
				header: {
					name: "Nome",
					phone: "Telefone",
					email: "Email",
					editDelete: "Editar / Deletar",
				},
				tableIsEmpty: "Não foi possivel encontrar mais administradores",
			},
			deleteDialogAdmin: {
				title: "Deletar Administrador",
				message: "Tem certeza que deseja deletar este administrador?",
			},
			csvHeaders: [
				{ label: "Id", key: "id" },
				{ label: "Nome", key: "name" },
				{ label: "E-mail", key: "email" },
			],
		},
		enum: {
			Language: {
				ptBr: "Português",
			},
		},
	},
});

api.setStrings(strings);
export default strings;
