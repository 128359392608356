// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Components
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import ReactInputMask from "react-input-mask";

export interface IProps {
	label: string;
	name: string;
	value: Date | null;
	handleChangeDate: (value: any) => void;
	minDate?: Date;
	maxDate?: Date;
	startDate?: Date;
	disable?: boolean;
}

interface IState {
	isOpen: boolean;
}

export default class DateSelect extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			isOpen: false,
		};

		this.toggleCalendar = this.toggleCalendar.bind(this);
	}

	public toggleCalendar() {
		this.setState({ isOpen: !this.state.isOpen });
	}

	public render = () => {
		const { label, handleChangeDate, value, name } = this.props;
		return (
			<div className="dateSelectContainer">
				<label>{label}</label>
				<ReactDatePicker
					disabledKeyboardNavigation={true}
					className="datePicker"
					selected={value}
					onChange={(data: Date) => {
						handleChangeDate(data);
						this.toggleCalendar();
					}}
					onClickOutside={this.toggleCalendar}
					name={name}
					locale="pt-BR"
					showMonthDropdown={true}
					showYearDropdown={true}
					dropdownMode="select"
					dateFormat="dd/MM/yyyy"
					customInput={<ReactInputMask mask="99/99/9999"/>}
					minDate={this.props.minDate}
					maxDate={this.props.maxDate}
					disable={this.props.disable}
				/>
				<ReactDatePicker
					className="datePickerMobile"
					selected={value}
					onChange={(data: Date) => {
						handleChangeDate(data);
						this.toggleCalendar();
					}}
					onClickOutside={this.toggleCalendar}
					name={name}
					locale="pt-BR"
					showMonthDropdown={true}
					showYearDropdown={true}
					dropdownMode="select"
					dateFormat="dd/MM/yyyy"
					customInput={<ReactInputMask mask="99/99/9999"/>}
					minDate={this.props.minDate}
					maxDate={this.props.maxDate}
					withPortal={true}
					startDate={this.props.startDate}
					disable={this.props.disable}
				/>
			</div>
		);
	}
}
