// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Icons
import {
	FindInPage as SeeMoreIcon,
} from "@material-ui/icons";
interface IProps {
	title: string;
	className?: string;
	url: string;
	name?: string;
}
export default class DocDetail extends React.Component<IProps> {
	public render() {
		const { url, title, name } = this.props;
		return (
			<>
				<div className={`docDetail ${this.props.className}`}>
					<h3 className="title">{title}:</h3>
					<a
						href={url}
						target="_blank"
						rel="noopener noreferrer"
					>
						<SeeMoreIcon />
						{name}
					</a>
				</div>
			</>
		);
	}
}
