// MARK: React
import React from "react";
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";
import ArticlesStore from "../../../../stores/ArticlesStore";

// MARK: Router
import { match } from "react-router";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Components
import { PageTitle } from "../../../../components/PageTitle";
import TextField from "../../../../components/TextField";

// MARK: Implementation
interface IProps {
	articlesStore: ArticlesStore;
	match: match<{ articleId?: string }>;
}

@inject("articlesStore")
@observer
export default class ArticleDetails extends React.Component<IProps> {
	public componentDidMount = async () => {
		const articleId = this.props.match.params.articleId;

		if (articleId) {
			await this.props.articlesStore.getArticle(articleId);
		}
	};

	public render() {
		const { articlesStore } = this.props;

		return (articlesStore.selectedArticle && (
			<div className="articleContainer">
				{articlesStore.selectedArticle.image && (
					<div className="imageContainer">
						<img
							src={articlesStore.selectedArticle.image.url}
							alt="article_image"
						/>
					</div>
				)}
				<PageTitle text={articlesStore.selectedArticle.title} />
				<TextField
					className="articleText"
					value={articlesStore.selectedArticle.text}
					multiline={true}
					disabled={true}
				/>
			</div>
		));
	}
}
