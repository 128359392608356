// MARK: Components
import { Button, Menu, MenuItem, Box } from "@material-ui/core";
import TextField from "../../../../components/TextField";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: React
import * as React from "react";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Icons
import { Search as SearchIcon } from "@material-ui/icons";

// MARK: Service
import FilterTextAutoCompleteService from "../../../../services/FilterService/FilterTextAutoCompleteService";

// Style
import "./style.scss";

interface IProps {
	service: FilterTextAutoCompleteService;
}

@observer
export default class FilterTextAutoComplete extends React.Component<IProps> {

	public render() {
		const { service } = this.props;

		return (
			<div
					style={service.autoCompleteSuggested.length > 0 ?
						{ boxShadow: "0px 0px 6px #00000029" } :
						{ boxShadow: "0px 3px 6px #00000029" }
					}
					className="navbarSearch"
			>
					<div className="navbarInput">
						<form
							onSubmit={(e) => {
								e.preventDefault();
								service.onSetText(service.searchText);
							}}
						>
							<input
								name="search"
								placeholder={"Pesquisa"}
								value={service.searchText || ""}
								onChange={service.onSearch}
								onFocus={() => {}}
								onBlur={() => {}}
							/>
						</form>
					</div>
					<div
						className="navbarIcon"
						role="button"
						onClick={() => service.onSetText(service.searchText)}
					>
						<SearchIcon className="onSearchIcon" />
					</div>
					{( service.autoCompleteSuggested.length > 0) && (
						<div className="searchAutocomplete">
							{service.autoCompleteSuggested.map((suggestion, i) => {
								const completeSearch = () => {
									service.onSetText(suggestion);
								};
								return (
										<p
											key={`${suggestion}-${i}`}
											role="button"
											onClick={completeSearch}
										>
											{suggestion}
										</p>
									);
							})}
						</div>
					)}
			</div>
		);
	}
}
