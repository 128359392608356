// MARK: React
import React from "react";
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";
import { routerStore } from "../../../../stores/_rootStore";
import CreditsUsersStore from "../../../../stores/CreditsUsersStore";

// MARK: Router
import { match } from "react-router";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Components
import Button from "../../../../components/Button";
import DateSelect from "../../../../components/DateSelect";
import { PageTitle } from "../../../../components/PageTitle";
import ToggleComponent from "../../../../components/ToggleComponent";
import TextField from "../../../../components/TextField";
import FileCase from "../../../../components/FileCase";

import CreditPicker from "./CreditPicker";
import UserPicker from "./UserPicker";

// MARK: Implementation
interface IProps {
	creditsUsersStore: CreditsUsersStore;
	match: match<{ creditUserId?: string }>;
}

@inject("creditsUsersStore")
@observer
export default class CreditUserCreateOrEdit extends React.Component<IProps> {
	private creditUserCreateOrEdit = async () => {
		await this.props.creditsUsersStore.creditUserCreateOrEdit();
	}

	private currencyMask = (value: string) => {
		const currency = parseInt(value.replace(/[^0-9]/g, "").trim() || "0");

		return strings.currency(currency);
	}

	private percentageMask = (value: string) => {
		const percentage = parseInt(value.replace(/[^0-9]/g, "").trim() || "0");

		return strings.percentage(percentage);
	}

	public componentDidMount = async () => {
		const creditUserId = this.props.match.params.creditUserId;

		if (creditUserId) {
			await this.props.creditsUsersStore.getCreditUser(creditUserId);
		}
	};

	public render() {
		const { creditsUsersStore } = this.props;
		const onEditMode = !!creditsUsersStore.selectedCreditUser;

		return (
			<>
				<div className="creditUserCreateWrapper">
					<PageTitle text={strings.creditsUsers.editor.getTitle(onEditMode)} />
					<div className="creditFormContainer">
						<CreditPicker
							creditPickerStore={creditsUsersStore.creditPickerStore}
							credit={creditsUsersStore.credit}
							selectCredit={creditsUsersStore.selectCredit}
							disabled={creditsUsersStore.creditUserLoading}
							editing={onEditMode}
						/>
						<UserPicker
							userPickerStore={creditsUsersStore.userPickerStore}
							user={creditsUsersStore.user}
							selectUser={creditsUsersStore.selectUser}
							disabled={creditsUsersStore.creditUserLoading}
							editing={onEditMode}
						/>
						<TextField
							label={strings.fields.investedAmount}
							name="investedAmount"
							value={this.currencyMask(creditsUsersStore.investedAmount)}
							onChange={creditsUsersStore.handleValueChange}
							disabled={creditsUsersStore.creditUserLoading}
						/>
						<DateSelect
							label={strings.fields.investedAt}
							name="investedAt"
							value={creditsUsersStore.investedAt}
							handleChangeDate={creditsUsersStore.handleInvestedAt}
							disable={creditsUsersStore.creditUserLoading}
						/>
						<TextField
							label={strings.fields.expectedReturn}
							name="expectedReturn"
							value={this.percentageMask(creditsUsersStore.expectedReturn)}
							onChange={creditsUsersStore.handleValueChange}
							disabled={creditsUsersStore.creditUserLoading}
						/>
						<FileCase
							fileUploaders={creditsUsersStore.fileService.fileUploaders}
							onAddItem={creditsUsersStore.fileService.selectFiles}
							onReloadItem={creditsUsersStore.fileService.reloadFile}
							onRemoveItem={creditsUsersStore.fileService.removeFile}
						/>
						<ToggleComponent
							title={strings.fields.fulfilled}
							checked={creditsUsersStore.fulfilled}
							onChange={creditsUsersStore.toggleFulfilled}
						/>
						{creditsUsersStore.fulfilled && (
							<>
								<TextField
									label={strings.fields.fulfilledAmount}
									name="fulfilledAmount"
									value={this.currencyMask(creditsUsersStore.fulfilledAmount)}
									onChange={creditsUsersStore.handleValueChange}
									disabled={creditsUsersStore.creditUserLoading}
								/>
								<DateSelect
									label={strings.fields.fulfilledDate}
									name="fulfilledDate"
									value={creditsUsersStore.fulfilledDate}
									handleChangeDate={creditsUsersStore.handleFulfilledDateChange}
									disable={creditsUsersStore.creditUserLoading}
								/>
							</>
						)}
					</div>
					<div className="buttons">
						<Button
							type="submit"
							variant="outlined"
							color="primary"
							onClick={() => routerStore.goBack()}
							loading={creditsUsersStore.creditUserLoading}
						>
							{strings.register.back}
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							onClick={this.creditUserCreateOrEdit}
							loading={creditsUsersStore.creditUserLoading}
						>
							{strings.register.finish}
						</Button>
					</div>
				</div>
			</>
		);
	}
}
