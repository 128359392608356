// MARK: Components
import Button from "../../components/Button";
import Table from "../../components/Table";
import { IColumnItem, IRowItem } from "../../components/Table/TableRow";
import Chip from "@material-ui/core/Chip";
import { Paper } from "@material-ui/core";
import StatsTable, { IStatsTable } from "./componets/StatsTable";
import FilterTextPopOver from "./componets/FilterTextPopOver";
import FilterEnumPicker from "./componets/FilterEnumPicker";
import FilterEnumListPicker from "./componets/FilterEnumListPicker";
import FilterBooleanComponent from "./componets/FilterBooleanComponent";
import FilterTextAutoComplete from "./componets/FilterTextAutoComplete";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: React
import * as React from "react";

// MARK: Resources
import strings from "../../resources/strings";

// MARK: Services
import FilterTextService from "../../services/FilterService/FilterTextService";
import FilterEnumService from "../../services/FilterService/FilterEnumService";
import FilterBooleanService from "../../services/FilterService/FilterBooleanService";
import FilterEnumListService from "../../services/FilterService/FilterEnumListService";
import FilterTextAutoCompleteService from "../../services/FilterService/FilterTextAutoCompleteService";

// MARK: Icons
import { CloudDownload as DownloadIcon } from "@material-ui/icons";
import { FilterList as FilterIcon } from "@material-ui/icons";
import { Edit as EditIcon } from "@material-ui/icons";

// Style
import "./style.scss";

type IFilterType = "text" | "enum" | "enumList" | "boolean" | "autoComplete";
interface IFilter {
	type: IFilterType;
	title: string;
	rowWrapEnum?: boolean;
	filterTextService?: FilterTextService;
	filterBooleanService?: FilterBooleanService;
	filterEnumService?: FilterEnumService<any>;
	filterEnumListService?: FilterEnumListService<any>;
	filterTextAutoCompleteService?: FilterTextAutoCompleteService;
}

export interface IBadgesFilter {
	label: string;
	onClear: () => void;
}

export interface IFilterComponent {
	title: string;
	component: React.ReactNode;
}

export interface IColumnDynamicItem extends IColumnItem {
	header: string;
}

interface IProps {
	editColumnsTable?: {
		selectedColumns: string[];
		allColumns: string[];
		saveSelectedColumns: (selectedColumns: string[]) => void;
	};
	statsList?: IStatsTable[];
	statsLoading?: boolean;

	filter?: {
		badgesFilter: IBadgesFilter[];
		filters: IFilter[];
		onFilter: () => void;
		clearFilter: () => void;
	};
	topListTable?: React.ReactNode;
	onExportTable?: () => void;
	tableConfig: {
		title: string;
		header: string[];
		nextPage?: () => void;
		loading: boolean;
		prevPage?: () => void;
		rows: IRowItem[];
		pageOffset?: number;
		onAddClick?: () => void;
		onClick?: (id: string) => void;
		onEditClick?: (itemId: string) => void;
		onDetailClick?: (itemId: string) => void;
		onDeleteClick?: (id: string, name: string) => void;
		onSelectClick?: (id: string) => void;
		onActiveClick?: (id: string, name: string) => void;
		activeSelectionsToolbar?: React.ReactNode;
		onAcceptClick?: (id: string) => void;
		onRejectClick?: (id: string) => void;
	};
	inputFilter?: IFilter;
}

interface IState {
	filterOpen: boolean;
	editTableOpen: boolean;
}

@observer
export default class PageTable extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

		this.state = {
			filterOpen: !isMobile ? !!props.filter : false,
			editTableOpen: false,
		};
	}

	private toggleFilter = () => {
		this.setState({
			filterOpen: !this.state.filterOpen,
		});
	};

	private toggleEditTable = () => {
		this.setState({
			editTableOpen: !this.state.editTableOpen,
		});
	};

	private clearButtonElement = () => {
		return this.props.filter ? (
			<div
				className="onClear"
				onClick={this.props.filter.clearFilter}
				role={"button"}
			>
				<h3>
					{strings.filter.textField.clear}
				</h3>
			</div>
		) : null;
	};

	private getFilterComponent = (filter: IFilter) => {
		switch (filter.type) {
			case "text":
				return <FilterTextPopOver service={filter.filterTextService!} />;
			case "enum":
				return <FilterEnumPicker service={filter.filterEnumService!} />;
			case "enumList":
				return <FilterEnumListPicker service={filter.filterEnumListService!} rowWrapEnum={filter.rowWrapEnum} />;
			case "boolean":
				return <FilterBooleanComponent service={filter.filterBooleanService!} />;
			case "autoComplete":
				return null;
		}
	}

	public render = () => {
		const { topListTable, onExportTable, tableConfig, filter, editColumnsTable, statsList } = this.props;
		const { filterOpen, editTableOpen } = this.state;

		return (
			<>
				<div className="pageTableContainer">
					<Paper className={filterOpen ? "pageTableFilter" : "pageTableFilterClosed"}>
						<h1>{strings.filter.title}</h1>
						{filter && filter.filters.map((mapFilter) => {
							return (
								<div className="filterSection" key={mapFilter.title}>
									<h4>{mapFilter.title}</h4>
									<div className="filterForm">
										{this.getFilterComponent(mapFilter)}
									</div>
								</div>
							);
						})}
						{this.clearButtonElement()}
						{filter && (
							<Button
								className="submitFilterButtonMobile"
								onClick={() => {
									this.toggleFilter();
								}}
							>
								{"Fechar"}
							</Button>
						)}
					</Paper>
					<div className={filterOpen ? "pageTableOnFilter" : "pageTable"}>
						{topListTable}
						{statsList && (
							<div className="statsContainer">
								<StatsTable
									statsList={statsList}
									statsLoading={this.props.statsLoading}
								/>
							</div>
						)}
						<div className="table">
							{this.props.inputFilter && this.props.inputFilter.filterTextAutoCompleteService && (
								<div className="searchInput">
									<FilterTextAutoComplete service={this.props.inputFilter.filterTextAutoCompleteService} />
								</div>
							)}
								{(filter && filter.badgesFilter.length > 0) && (
								<div className="badgesView">
									{filter.badgesFilter.map((badge) => (
										<Chip
											key={badge.label}
											className="metaTagChip"
											label={badge.label}
											onDelete={badge.onClear}
										/>
									))}
								</div>
							)}
							<Table
								toolbar={(
									<div>
										{filter && (
											<FilterIcon
												titleAccess={strings.components.table.titleIcon.openFilter}
												className={filterOpen ? "toolbarIconSelected" : "toolbarIcon"}
												onClick={this.toggleFilter}
											/>
										)}
										{editColumnsTable && (
											<EditIcon
												titleAccess={strings.components.table.titleIcon.editTable}
												className={editTableOpen ? "toolbarIconSelected" : "toolbarIcon"}
												onClick={this.toggleEditTable}
											/>
										)}
										{tableConfig.rows.length > 0 && onExportTable && (
											<DownloadIcon
												titleAccess={strings.components.table.titleIcon.exportCSV}
												className="toolbarIcon"
												onClick={onExportTable}
											/>
										)}
									</div>
								)}
								tableIsEmptyMessage={strings.message.noMoreResults}
								header={tableConfig.header}
								title={tableConfig.title}
								loading={tableConfig.loading}
								nextPage={tableConfig.nextPage}
								prevPage={tableConfig.prevPage}
								rows={tableConfig.rows}
								pageOffset={tableConfig.pageOffset}
								onAddClick={tableConfig.onAddClick}
								onEditClick={tableConfig.onEditClick}
								onDetailsClick={tableConfig.onDetailClick}
								onClick={tableConfig.onClick}
								onDeleteClick={tableConfig.onDeleteClick}
								onAccepClick={tableConfig.onAcceptClick}
								onRejectClick={tableConfig.onRejectClick}
							/>
						</div>
					</div>
				</div>
			</>
		);
	};
}
