// MARK: React
import * as React from "react";
import { match } from "react-router";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Stores
import AuthStore from "../../stores/AuthStore";
import { routerStore } from "../../stores/_rootStore";

// MARK: Layout
import MainLayout from "../../layouts/MainLayout";

// MARK: Components
import Typography from "@material-ui/core/Typography";
import CircularProgress from "../../components/CircularProgress";
import TextField from "../../components/TextField";
import Button from "../../components/Button";

// Resources
import strings from "../../resources/strings";

interface IProps {
	match: match<{ token: string }>;
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Recovery extends React.Component<IProps> {
	public componentWillMount = async () => {
		await this.props.authStore.openDashboardIfAuthenticated();
		this.props.authStore.receivedToken = this.props.match.params.token;
	};

	public render() {
		const { authStore } = this.props;

		return (
			<MainLayout
				hiddenMenu={true}
				hiddenLanguageSelector={true}
			>
				{
					authStore.loading ?
						<CircularProgress /> : (
							<div className="emailRecovery">
								<div className="emailRecoveryCard">
									<Typography className="loginTitle" variant="h5">
										{strings.nav.title}
									</Typography>
									{
										(authStore.receivedToken && authStore.receivedToken.length > 0) ? (
											<>
												<TextField
													label={strings.recoverPassword.newPassword}
													type="password"
													name="newPassword"
													value={authStore.newPassword}
													onChange={authStore.handleValueChange}
													disabled={authStore.loading}
												/>
												<TextField
													label={strings.recoverPassword.confirmNewPassword}
													type="password"
													name="confirmNewPassword"
													value={authStore.confirmNewPassword}
													onChange={authStore.handleValueChange}
													disabled={authStore.loading}
												/>
												<Button className="emailRecoveryButton" variant="outlined" onClick={() => authStore.sendNewPassword()}>
													{strings.recoverPassword.newPasswordButton}
												</Button>
											</>
										) : (
												<>
													<TextField
														label={strings.recoverPassword.email}
														type="email"
														name="email"
														value={authStore.email}
														onChange={authStore.handleValueChange}
														disabled={authStore.loading}
													/>
													<Button className="emailRecoveryButton" variant="outlined" onClick={() => authStore.resetPassword()}>
														{strings.recoverPassword.recoverPasswordButton}
													</Button>
												</>
											)
									}
									<Button onClick={() => routerStore.push("./login")}>
										{strings.recoverPassword.backButton}
									</Button>
								</div>
							</div>
						)}
			</MainLayout>
		);
	}
}
