// MARK: Mobx
import { action, observable } from "mobx";

export default class FilterBooleanService {
	private readonly onFilter: () => Promise<void>;

	@observable public isValue: boolean | null = null;

	@observable public stringValueTrue: string = "";
	@observable public stringValueFalse: string = "";

	constructor(onFilter: () => Promise<void>, stringValueTrue: string, stringValueFalse: string) {
		this.stringValueTrue = stringValueTrue;
		this.stringValueFalse = stringValueFalse;

		this.onFilter = onFilter;
	}

	@action
	public clear = () => {
		this.isValue = null;
	};

	@action
	public toggleValue = async (isValue: boolean) => {
		if (this.isValue === isValue) {
			this.isValue = null;
		} else {
			this.isValue = isValue;
		}

		await this.onFilter();
	}
}
