// MARK: React
import * as React from "react";
import ReactDOM from "react-dom";
import "./style.scss";

// MARK: Mobx
import { Provider } from "mobx-react";
import { rootStore } from "./stores/_rootStore";

// MARK: Pages
import Dashboard from "./pages/Dashboard";
import Auth from "./pages/Auth";
import Login from "./pages/Login";
import Recovery from "./pages/Recovery";

// MARK: Resources
import strings from "./resources/strings";

// MARK: Routing
import { Router, Route, Switch } from "react-router-dom";
import * as history from "history";
import { syncHistoryWithStore } from "mobx-react-router";
const browserHistory = history.createBrowserHistory();
syncHistoryWithStore(browserHistory, rootStore.routerStore);

// MARK: API
import * as api from "@startapp/apuama-admin-api";
api.setStrings(strings);

const productionUrl: string = "https://api.apuama.startapp.one/admin";
const stagingUrl: string = "https://api.apuama.startapp.one/admin-staging";
api.setUrl(process.env.NODE_ENV ?
	((): string => {
		switch (process.env.NODE_ENV) {
			case "production": {
				return productionUrl;
			}
			case "staging":
			default: {
				return stagingUrl;
			}
		}
	})() : stagingUrl,
);

ReactDOM.render(
	(
		<Provider {...rootStore}>
			<Router history={rootStore.routerStore.history}>
				<Switch>
					<Route exact={true} path="/" component={Auth} />
					<Route path="/login" component={Login} />
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/recovery/:token" component={Recovery} />
					<Route path="/recovery" component={Recovery} />
				</Switch>
			</Router>
		</Provider>
	),
	document.getElementById("app"),
);
