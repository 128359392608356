// MARK: React
import * as React from "react";

// MARK: Style
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MARK: Router
import { match } from "react-router";

// MARK: Components
import CircularProgress from "../../../../components/CircularProgress";
import Button from "../../../../components/Button";
import TextField from "../../../../components/TextField";
import MaskedTextField from "../../../../components/MaskedTextField";
import CheckboxGroup from "../../../../components/CheckboxGroup";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Stores
import { routerStore } from "../../../../stores/_rootStore";
import AuthStore from "../../../../stores/AuthStore";
import AdminUsersStore from "../../../../stores/AdminUsersStore";

interface IProps {
	authStore: AuthStore;
	adminUsersStore: AdminUsersStore;
	match: match<{ adminUserId?: string }>;
}

@inject("authStore", "adminUsersStore")
@observer
export default class AdminUserCreateOrEdit extends React.Component<IProps> {
	private createOrEditAdminUser = async () => {
		if (this.props.authStore.adminUser) {
			await this.props.adminUsersStore.createOrEditAdminUser();
		}
	}

	public componentDidMount = async () => {
		const adminUserId = this.props.match.params.adminUserId;
		if (adminUserId) {
			await this.props.adminUsersStore.getAdminUser(adminUserId);
		}
	};

	public render() {
		const { adminUsersStore } = this.props;

		return (
			<>
				{adminUsersStore.adminUsersLoading ?
					<CircularProgress /> : (
						<div className="adminUserCreateOrEditWrapper">
							<div className="adminUserCreateOrEditHeader">
								<h1 className="adminUserCreateOrEditTitle">{adminUsersStore.selectedAdminUser ? "Editar Administrador" : "Cadastrar Administrador"}</h1>
							</div>
							<div className="adminUserFormContainer">
								<TextField
									label={strings.fields.name}
									name="name"
									value={adminUsersStore.name}
									onChange={adminUsersStore.handleValueChange}
									disabled={adminUsersStore.adminUsersLoading}
								/>
								<TextField
									label={strings.fields.email}
									name="email"
									value={adminUsersStore.email}
									onChange={adminUsersStore.handleValueChange}
									disabled={adminUsersStore.adminUsersLoading}
								/>
								<MaskedTextField
									label={strings.fields.phone}
									name="phone"
									mask="(99) 99999-9999"
									value={adminUsersStore.phone}
									onChange={adminUsersStore.handleValueChange}
									disabled={adminUsersStore.adminUsersLoading}
								/>
								<MaskedTextField
									label={strings.fields.whatsapp}
									name="whatsapp"
									mask="(99) 99999-9999"
									value={adminUsersStore.whatsapp}
									onChange={adminUsersStore.handleValueChange}
									disabled={adminUsersStore.adminUsersLoading}
								/>
								{!adminUsersStore.selectedAdminUser && (
									<>
										<TextField
											label={strings.password}
											name="password"
											type="password"
											value={adminUsersStore.password}
											onChange={adminUsersStore.handleValueChange}
											disabled={adminUsersStore.adminUsersLoading}
										/>
									</>
								)}
							</div>
							<div className="buttons">
								<Button
									type="submit"
									variant="outlined"
									onClick={() => routerStore.push("/dashboard/adminUsers")}
									loading={adminUsersStore.adminUsersLoading}
								>
									{strings.register.back}
								</Button>
								<Button
									type="submit"
									variant="outlined"
									onClick={this.createOrEditAdminUser}
									loading={adminUsersStore.adminUsersLoading}
								>
									{strings.register.finish}
								</Button>
							</div>
						</div>
					)
				}
			</>
		);
	}
}
