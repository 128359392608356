// MARK: React
import * as React from "react";
import { observer, inject } from "mobx-react";
import "./style.scss";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";

// MARK: Components
import CreditTable from "./CreditTable";
import CreditCreateOrEdit from "./CreditCreateOrEdit";
import CreditDetails from "./CreditDetails";
import CreditLogCreateOrEdit from "./CreditLogCreateOrEdit";
import CreditLogDetails from "./CreditLogDetails";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Credits extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.authStore.authenticate();
	};

	public render() {
		return (
			<div className="credits">
				<Switch>
					<Route exact={true} path="/dashboard/credits" component={CreditTable}/>
					<Route path="/dashboard/credits/editor/:creditId" component={CreditCreateOrEdit}/>
					<Route path="/dashboard/credits/editor" component={CreditCreateOrEdit}/>
					<Route path="/dashboard/credits/creditDetails/:creditId" component={CreditDetails}/>
					<Route path="/dashboard/credits/creditLog/editor/:creditLogId" component={CreditLogCreateOrEdit}/>
					<Route path="/dashboard/credits/creditLogDetails/:creditLogId" component={CreditLogDetails}/>
					<Route path="/dashboard/credits/creditLog/:creditId" component={CreditLogCreateOrEdit}/>
				</Switch>
			</div>
		);
	}
}
