// MARK: React
import * as React from "react";
import { observer, inject } from "mobx-react";
import "./style.scss";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";

// MARK: Components
import ArticleTable from "./ArticleTable";
import ArticleDetails from "./ArticleDetails";
import ArticleCreateOrEdit from "./ArticleCreateOrEdit";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Articles extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.authStore.authenticate();
	};

	public render() {
		return (
			<div className="articles">
				<Switch>
					<Route exact={true} path="/dashboard/articles" component={ArticleTable}/>
					<Route exact={true} path="/dashboard/articles/editor/:articleId" component={ArticleCreateOrEdit}/>
					<Route exact={true} path="/dashboard/articles/editor/" component={ArticleCreateOrEdit}/>
					<Route exact={true} path="/dashboard/articles/details/:articleId" component={ArticleDetails}/>
				</Switch>
			</div>
		);
	}
}
