// MARK: React
import React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: API
import * as api from "@startapp/apuama-admin-api";

// MARK: Resources
import strings from "../../../../../resources/strings";

// MARK: Components
import { Modal } from "@material-ui/core";
import TextField from "../../../../../components/TextField";
import Button from "../../../../../components/Button";

// MARK: Stores
import UserPickerStore from "../../../../../stores/CreditsUsersStore/UserPickerStore";

// MARK: Implementation
interface IProps {
	userPickerStore: UserPickerStore;
	user: api.User | null;
	selectUser: (user: api.User) => void;
	disabled: boolean;
	editing: boolean;
}

@observer
export default class UserPicker extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.userPickerStore.getData();
	}

	public render() {
		const { userPickerStore, disabled, editing, user, selectUser } = this.props;
		const { open, toggleOpen, search, handleValueChange } = userPickerStore;

		return (
			<>
				<Modal open={open} onClose={toggleOpen}>
					<div className="userPickerWrapper">
						<div className="serachWrapper">
							<TextField
								className="serachTextField"
								label={strings.fields.name}
								name="search"
								value={search}
								onChange={handleValueChange}
								disabled={disabled}
							/>
							<Button
								type="button"
								variant="outlined"
								color="primary"
								onClick={userPickerStore.refreshData}
								loading={disabled}
							>
								{strings.creditsUsers.editor.changeUser.filter}
							</Button>
						</div>
						<div className="usersWrapper">
							{userPickerStore.rowItems.map((userRow) => (
								<div key={userRow.id} className="userRow">
									<p>{userRow.name}</p>
									<Button
										type="button"
										variant="outlined"
										color="primary"
										onClick={() => {
											selectUser(userRow);
											userPickerStore.clear();
											userPickerStore.refreshData();
										}}
										loading={disabled}
									>
										{strings.creditsUsers.editor.changeUser.pick}
									</Button>
								</div>
							))}
							<div className="paginationWrapper">
								<Button
									type="button"
									variant="text"
									color="primary"
									onClick={userPickerStore.previousPage}
									loading={disabled}
								>
									{strings.creditsUsers.editor.changeUser.previous}
								</Button>
								<p>{userPickerStore.pageOffset + 1}</p>
								<Button
									type="button"
									variant="text"
									color="primary"
									onClick={userPickerStore.nextPage}
									loading={disabled}
								>
									{strings.creditsUsers.editor.changeUser.next}
								</Button>
							</div>
						</div>
						<Button
							type="button"
							variant="contained"
							color="primary"
							onClick={toggleOpen}
							loading={disabled}
						>
							{strings.creditsUsers.editor.changeUser.close}
						</Button>
					</div>
				</Modal>
				<div className="selectedUserWrapper">
					<h3>{strings.creditsUsers.editor.changeUser.title}</h3>
					<div className="selectedUserContent">
						{user && (
							<p>{user.name}</p>
						)}
						{!editing && (
							<Button
								type="submit"
								variant="outlined"
								color="primary"
								onClick={toggleOpen}
								loading={disabled}
							>
								{user ?
									strings.creditsUsers.editor.changeUser.change :
									strings.creditsUsers.editor.changeUser.select}
							</Button>
						)}
					</div>
				</div>
			</>
		);
	}
}
