// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Material-UI
import { TableCell as MTableCell } from "@material-ui/core";
import { TableHead as MTableHead } from "@material-ui/core";
import { TableRow as MTableRow } from "@material-ui/core";

// MARK: Resources
import strings from "../../../resources/strings";

interface IProps {
	header: string[];
	hasDetails: boolean;
	hasEdit: boolean;
	hasDelete: boolean;
	hasAccept: boolean;
	hasReject: boolean;
}

@observer
export default class TableHeader extends React.Component<IProps> {
	// tslint:disable-next-line: cognitive-complexity
	public render() {
		const { header, hasDetails, hasEdit, hasDelete, hasAccept, hasReject } = this.props;

		return (
			<MTableHead>
				<MTableRow>
					{hasDetails && hasEdit && hasDelete ?
						(
							<MTableCell
								key={"header_cell_editAndDelete"}
								align="center"
							>
								{strings.components.table.detailsAndEditAndDelete}
							</MTableCell>
						) :
						hasAccept && hasReject && hasDetails ?
							(
								<MTableCell
									key={"header_cell_editAndDelete"}
									align="center"
								>
									{strings.components.table.AcceptAndRejectaandDetails}
								</MTableCell>
							) :
						hasAccept && hasReject ?
							(
								<MTableCell
									key={"header_cell_editAndDelete"}
									align="center"
								>
									{strings.components.table.AcceptAndReject}
								</MTableCell>
							) :
							hasDetails && hasEdit ?
								(
									<MTableCell
										key={"header_cell_editAndDelete"}
										align="center"
									>
										{strings.components.table.detailsAndEdit}
									</MTableCell>
								) :
								hasDetails && hasDelete ?
									(
										<MTableCell
											key={"header_cell_editAndDelete"}
											align="center"
										>
											{strings.components.table.detailsAndDelete}
										</MTableCell>
									) :
									hasEdit && hasDelete ?
										(
											<MTableCell
												key={"header_cell_editAndDelete"}
												align="center"
											>
												{strings.components.table.editAndDelete}
											</MTableCell>
										) :
										hasEdit ?
											(
												<MTableCell
													key={"header_cell_edit"}
													align="center"
												>
													{strings.components.table.edit}
												</MTableCell>
											) :
											hasDelete && (
												<MTableCell
													key={"header_cell_delete"}
													align="center"
												>
													{strings.components.table.delete}
												</MTableCell>
											)
					}
					{header.map((headerItem, index) => (
						<MTableCell
							key={"header_cell_" + index}
							align="center"
						>
							{headerItem}
						</MTableCell>
					))}
				</MTableRow>
			</MTableHead>
		);
	}
}
