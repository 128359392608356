// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Stores
import AuthStore from "../../stores/AuthStore";

// MARK: Layouts
import MainLayout from "../../layouts/MainLayout";

// MARK: Components
import CircularProgress from "../../components/CircularProgress";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Auth extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.authStore.authenticate();
		await this.props.authStore.openDashboardIfAuthenticated();
	};

	public render() {
		return (
			<MainLayout>
				<CircularProgress />
			</MainLayout>
		);
	}
}
