// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Components
import Table from "../../../../components/Table";
import CircularProgress from "../../../../components/CircularProgress";

// MARK: Stores
import AdminUsersStore from "../../../../stores/AdminUsersStore";

// MARK: Resources
import strings from "../../../../resources/strings";

interface IProps {
	adminUsersStore: AdminUsersStore;
}

@inject("adminUsersStore")
@observer
export default class AdminUsersTable extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.adminUsersStore.fetchInitialData();
	}

	public render() {
		const { adminUsersStore } = this.props;

		return (
			<>
				{adminUsersStore.tableLoading || adminUsersStore.adminUsersLoading ?
					<CircularProgress /> : (
						<div className="adminUsersTableContainer">
							<h1 className="adminUsersTitle">{strings.adminUsers.title}</h1>
							<div className="adminUsersTable">
								<Table
									loading={adminUsersStore.tableLoading}
									rows={adminUsersStore.tableRows}
									header={adminUsersStore.tableHeader}
									pageOffset={adminUsersStore.pageOffSet}
									prevPage={adminUsersStore.previousPage}
									nextPage={adminUsersStore.nextPage}
									onAddClick={() => adminUsersStore.startAdminUserCreateOrEdit()}
									onEditClick={(adminUserId) => adminUsersStore.startAdminUserCreateOrEdit(adminUserId)}
									onDeleteClick={(userId) => adminUsersStore.deleteAdminUser(userId)}
									tableIsEmptyMessage={strings.adminUsers.table.tableIsEmpty}
								/>
							</div>
						</div>
					)
				}
			</>
		);
	}
}
