// MARK: Components
import { Button, Popover } from "@material-ui/core";
import TextField from "../../../../components/TextField";
import Chip from "@material-ui/core/Chip";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: React
import * as React from "react";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Service
import FilterTextService from "../../../../services/FilterService/FilterTextService";

// Style
import "./style.scss";

interface IState {
	popoverOpen: boolean;
	text: string;
}

interface IProps {
	service: FilterTextService;
}

@observer
export default class FilterTextPopOver extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			popoverOpen: false,
			text: "",
		};
	}

	public async componentDidMount() {
		const { service } = this.props;

		this.setState({
			text:
				service.searchText ?
					service.searchText : "",
		});
	}

	private textHandleChange = (text: string) => {
		this.setState({ text });
	};

	private popoverAnchor: HTMLElement | null;

	private openPopover = (event) => {
		const { service } = this.props;

		this.setState({
			popoverOpen: true,
			text:
				service.searchText ?
					service.searchText : "",
		});

		this.popoverAnchor = event.currentTarget;
	};

	private closePopover = () => {
		this.setState({ popoverOpen: false });
		this.popoverAnchor = null;
	};

	private onSearch = () => {
		const { service } = this.props;

		service.onSetText(this.state.text);
		this.closePopover();
	};

	private onClearState = () => {
		this.setState({ text: "" });
	};

	public render() {
		const { service } = this.props;

		return (
			<div className="containerFilterText">

				{service.searchText ? (
					<Chip
						className="metaTagChip"
						label={service.searchText}
						onDelete={() => {
							service.clear();
							this.onClearState();
						}}
					/>
				)
					: (
						<div className="filterTextRow">
							<Button
								className="periodTextSelect"
								onClick={(e) => this.openPopover(e)}
							>

								{strings.filter.components.filterText.addText}
							</Button>
						</div>
					)
				}
				<Popover
					open={this.state.popoverOpen}
					onClose={this.closePopover}
					className="popoverComponent"
					anchorEl={this.popoverAnchor}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
				>
					<div className="popoverFilterTextContainer">
						<TextField
							value={this.state.text}
							name={this.state.text}
							onChange={(e) => this.textHandleChange(e.target.value)}
						/>
						<div className="popoverButton">
							<Button
								className="periodButtonConfirm"
								onClick={this.onSearch}
							>
								{strings.buttons.search}
							</Button>
						</div>
					</div>
				</Popover>
			</div>
		);
	}
}
