// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Material-UI
import { Table as MTable } from "@material-ui/core";
import { TableBody as MTableBody } from "@material-ui/core";
import { Toolbar as MToolbar } from "@material-ui/core";
import { Typography as MTypography } from "@material-ui/core";
import { Paper as MPaper } from "@material-ui/core";
import { LinearProgress as MLinearProgress } from "@material-ui/core";

// MARK: Components
import TableHeader from "./TableHeader";
import TableRow, { IRowItem } from "./TableRow";
import TableFooter, { ICsv } from "./TableFooter";

interface IProps {
	rows: IRowItem[];
	header: string[];
	title?: string;
	loading: boolean;
	pageOffset?: number;
	tableIsEmptyMessage?: string;
	toolbar?: React.ReactNode;
	headerView?: React.ReactNode;
	onlyPage?: boolean;
	nextPage?: () => void;
	prevPage?: () => void;
	onAddClick?: () => void;
	onClick?: (id: string) => void;
	onEditClick?: (id: string) => void;
	onDetailsClick?: (id: string) => void;
	onDeleteClick?: (id: string, name: string) => void;
	onAccepClick?: (id: string) => void;
	onRejectClick?: (id: string) => void;
	csv?: ICsv;
}

@observer
export default class Table extends React.Component<IProps> {
	public render() {
		const {
			pageOffset, nextPage, prevPage,
			loading, rows, onAddClick,
			onEditClick, onDeleteClick, onClick,
			title, headerView, header,
			toolbar, onDetailsClick,
			csv, tableIsEmptyMessage,
			onAccepClick, onRejectClick,
		} = this.props;

		return (
			<MPaper>
				<MLinearProgress style={{ visibility: loading ? "visible" : "hidden", borderRadius: "20px" }} />
				{title && (
					<MToolbar style={{ display: "flex", justifyContent: "space-between", boxSizing: "border-box", width: "100%" }}>
						<MTypography variant="h6">{title}</MTypography>
						<div className="tableTools">
							{toolbar}
						</div>
					</MToolbar>
				)}
				{headerView}
				{rows.length > 0 ? (
					<div className="overflowX">
						<MTable>
							<TableHeader
								header={header}
								hasDetails={!!onDetailsClick}
								hasEdit={!!onEditClick}
								hasDelete={!!onDeleteClick}
								hasAccept={!!onAccepClick}
								hasReject={!!onRejectClick}
							/>
							<MTableBody>
								{rows.map((item, index) => (
									<TableRow
										key={`${item.id}-row-${index}`}
										rowItem={item}
										onDeleteClick={onDeleteClick}
										onEditClick={onEditClick}
										onDetailsClick={onDetailsClick}
										onClick={onClick}
										onAccepClick={onAccepClick}
										onRejectClick={onRejectClick}
									/>
								))}
							</MTableBody>
						</MTable>
					</div>
				) :
					!loading && (
						<>
							<MTypography align="center">{tableIsEmptyMessage}</MTypography>
						</>
					)
				}
				{pageOffset || pageOffset === 0 ? (
					<TableFooter
						pageOffset={pageOffset + 1}
						onNextClick={nextPage}
						onPrevClick={prevPage}
						onAddClick={onAddClick}
						csv={csv}
						numberOfColumns={rows.length > 0 ? rows[0].columns.length : 0}
					/>
				) :
					<></>
				}
			</MPaper>
		);
	}
}
