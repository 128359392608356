// MARK: Mobx
import { observer } from "mobx-react";

// MARK: React
import * as React from "react";

// MARK: React
import FilterBooleanService from "../../../../services/FilterService/FilterBooleanService";

// MARK: Components
import Chip from "@material-ui/core/Chip";

// MARK: Services
import "./style.scss";

interface IProps {
	service: FilterBooleanService;
}

@observer
export default class FilterBooleanComponent extends React.Component<IProps> {
	public render() {
		const { service } = this.props;

		return (
			<div className="filterContainer">
				<div className="containerField">
					<div
						className={service.isValue === true ? "fieldActive" : "field"}
						role={"button"}
						onClick={() => service.toggleValue(true)}
					>
						{service.stringValueTrue}
					</div>
					<div
						className={service.isValue === false ? "fieldActive" : "field"}
						role={"button"}
						onClick={() => service.toggleValue(false)}
					>
						{service.stringValueFalse}
					</div>
				</div>
			</div>
		);
	}
}
