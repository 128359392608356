// MARK: Api
import * as api from "@startapp/apuama-admin-api";

// MAKR: Libraries
import moment from "moment";

// MARK: Mobx
import { action, observable } from "mobx";

// MARK: Resources
import strings from "../../resources/strings";
import VariableChangeHandler from "../../resources/VariableChangeHandler";

// MARK: Stores
import { uiStore, routerStore } from "../_rootStore";
import UserPickerStore from "./UserPickerStore";
import CreditPickerStore from "./CreditPickerStore";

// MARK: Services
import FileService from "../../services/FileService";

// MARK: Implementation
export default class CreditsUsersStore extends VariableChangeHandler {
	// MARK: SubStores
	public userPickerStore: UserPickerStore;
	public creditPickerStore: CreditPickerStore;

	@observable public selectedCreditUser: api.CreditUser | null;
	@observable public creditUserLoading: boolean;

	// MARK: Create Or Edit CreditUser
	@observable public credit: api.Credit | null;
	@observable public user: api.User | null;

	@observable public investedAmount: string;
	@observable public investedAt: Date;
	@observable public expectedReturn: string;

	@observable public fulfilled: boolean;
	@observable public fulfilledAmount: string;
	@observable public fulfilledTaxAmount: string;
	@observable public fulfilledDate: Date;

	// MARK: Services
	@observable public fileService: FileService = new FileService();

	// MARK: Setters
	@action
	public handleFulfilledDateChange = (fulfilledDate: Date) => {
		this.fulfilledDate = fulfilledDate;
	}

	@action
	public handleInvestedAt = (investedAt: Date) => {
		this.investedAt = investedAt;
	}

	@action
	public toggleFulfilled = () => {
		this.fulfilled = !this.fulfilled;
	}

	@action
	public selectUser = (user: api.User) => {
		this.user = user;
	}

	@action
	public selectCredit = (credit: api.Credit) => {
		this.credit = credit;
	}

	constructor() {
		super();

		this.userPickerStore = new UserPickerStore();
		this.creditPickerStore = new CreditPickerStore();

		this.clear();
	}

	@action
	public startCreateOrEditCreditUser = (args?: { creditUser?: api.CreditUser | null,  user?: api.User | null, credit?: api.Credit | null } | null) => {
		this.clear();

		if (args) {
			this.credit = args.credit || null;
			this.user = args.user || null;
		}

		if (args && args.creditUser) {
			this.setValues(args.creditUser);
			routerStore.push(`/dashboard/creditsUsers/editor/${args.creditUser.id}`);
		} else {
			routerStore.push(`/dashboard/creditsUsers/editor`);
		}
	}

	@action
	public clear = () => {
		this.selectedCreditUser = null;
		this.creditUserLoading = false;

		this.credit = null;
		this.user = null;

		this.investedAmount = "";
		this.investedAt = moment().startOf("day").toDate();
		this.expectedReturn = "";
		this.fileService.clear();

		this.fulfilled = false;
		this.fulfilledAmount = "";
		this.fulfilledTaxAmount = "";
		this.fulfilledDate = moment().startOf("day").toDate();
	}

	@action
	public setValues = (creditUser: api.CreditUser) => {
		this.clear();

		this.selectedCreditUser = creditUser;
		this.creditUserLoading = false;

		this.credit = creditUser.credit;
		this.user = creditUser.user;

		this.investedAmount = creditUser.investedAmount.toString();
		this.investedAt = creditUser.investedAt;
		this.expectedReturn = Math.round(Number(creditUser.expectedReturn) * 10000).toString();
		this.fileService.setFiles(creditUser.attachedFiles);

		this.fulfilled = !!creditUser.fulfilled;

		if (creditUser.fulfilled) {
			this.fulfilledAmount = creditUser.fulfilled.amount.toString();
			this.fulfilledTaxAmount = Math.round(Number(creditUser.fulfilled.taxAmount) * 10000).toString();
			this.fulfilledDate = creditUser.fulfilled.date;
		}
	}

	@action
	public getCreditUser = async (creditUserId: string) => {
		if (this.selectedCreditUser && this.selectedCreditUser.id === creditUserId) {
			this.setValues(this.selectedCreditUser);
		} else {
			this.setValues(await api.getCreditUser(creditUserId));
		}
	}

	@action
	public creditUserCreateOrEdit = async () => {
		if (this.creditUserLoading) {
			return;
		}

		this.creditUserLoading = true;

		function stringToInt(value: string) {
			return parseInt(value.replace(/[^0-9]/g, "").trim());
		}

		const uploadedFiles = this.fileService.getUncertainfiedFile();

		try {
			const editedCreditUser: api.EditedCreditUser = {
				investedAmount: stringToInt(this.investedAmount),
				investedAt: this.investedAt,
				expectedReturn: stringToInt(this.expectedReturn) / 10000,
				attachedFiles: uploadedFiles.map((file) => {
					return {
						file,
						fileData: null,
					};
				}),
				fulfilled: this.fulfilled ? {
					amount: stringToInt(this.fulfilledAmount),
					taxAmount: stringToInt(this.fulfilledTaxAmount) * 100,
					date: this.fulfilledDate,
				} : null,
			};

			if (this.selectedCreditUser) {
				await api.editCreditUser(this.selectedCreditUser.id, editedCreditUser);

				this.clear();
				uiStore.showSnackbar(strings.register.successEditCredit);
			} else {
				if (!this.user) {
					uiStore.showSnackbar(strings.creditsUsers.editor.errors.missingUser);
					return;
				}

				if (!this.credit) {
					uiStore.showSnackbar(strings.creditsUsers.editor.errors.missingCredit);
					return;
				}

				await api.createCreditUser({
					userId: this.user.id,
					creditId: this.credit.id,
					...editedCreditUser,
				});

				this.clear();

				uiStore.showSnackbar(strings.register.successCredit);
			}

			routerStore.goBack();
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.creditUserLoading = false;
		}
	}
}
