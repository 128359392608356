// MARK: React
import * as React from "react";

// MARK: API
import { User } from "@startapp/apuama-admin-api";

// MARK: Style
import "./style.scss";

// MARK: Sevices
import strings from "../../resources/strings";

// MARK: Components
import ItemDetail from "../ItemDetail";
import DocDetail from "../DocDetail";

interface IProps {
	user: User;
}

export default class UserDetailsCard extends React.Component<IProps> {
	public render() {
		const { user } = this.props;
		return (
			<>
				<div className="userDataContainer">
					<div className="userContainerData">
						<ItemDetail
							title={strings.users.table.header.id}
							info={user.id}
						/>
						<ItemDetail
							title={strings.users.table.header.name}
							info={user.name}
						/>
						<ItemDetail
							title={strings.users.table.header.email}
							info={user.email}
						/>
						<ItemDetail
							title={strings.fields.cpfOrCnpj}
							info={user.documentNumber}
						/>
						{user.whatsapp
							&& (
								<ItemDetail
									title={strings.fields.whatsapp}
									info={user.whatsapp}
								/>
							)}
						{user.phone
							&& (
								<ItemDetail
									title={strings.fields.phone}
									info={user.phone}
								/>
							)}
						{user.bankAccount
							&& (
								<>
									<ItemDetail
										title={strings.fields.bank}
										info={user.bankAccount.bank}
									/>
									<ItemDetail
										title={strings.fields.agency}
										info={user.bankAccount.agencyDV ?
											`${user.bankAccount.agency}-${user.bankAccount.agencyDV}`
											:
											user.bankAccount.agency
										}
									/>
									<ItemDetail
										title={strings.fields.account}
										info={`${user.bankAccount.account}-${user.bankAccount.accountDV}`}
									/>
								</>
							)}
					</div>
				</div>
			</>
		);
	}
}
