// MARK: Mobx
import { action, observable } from "mobx";

export default class FilterTextService {
	private readonly onFilter: () => Promise<void>;

	@observable public searchText: string | null = null;

	constructor(onFilter: () => Promise<void>) {
		this.onFilter = onFilter;
	}

	@action
	public clear = () => {
		this.searchText = null;
		this.onSetText(null);
	};

	// OnSearch
	@action
	public onSetText = (text: string | null)  => {
		this.searchText = text;
		this.onFilter();
	}
}
