// MARK: Api
import * as api from "@startapp/apuama-admin-api";

// MARK: Mobx
import { observable, action } from "mobx";

// MARK: Stores
import { uiStore, routerStore } from "../_rootStore";

import { IRowItem, ColumnType } from "../../components/Table/TableRow";

// MARK: Services
import ImageService from "../../services/ImageService";

// MARK: Resources
import strings from "../../resources/strings";

// 	MARK: Types
import { default as TableDataStore } from "../../resources/TableDataStore";

export default class ArticlesStore extends TableDataStore<api.Article> {
	// MARK: Controls
	@observable public articlesLoading: boolean = false;
	@observable public tableLoading: boolean = false;
	@observable public pageOffSet: number = 0;

	// MARK: Variables

	// Services
	@observable public imageService: ImageService = new ImageService();

	// SelectedArticle
	@observable public selectedArticle: api.Article | null = null;

	// Admin Users
	@observable public articles: api.Article[] = [];

	// Create or Edit
	@observable public title: string = "";
	@observable public text: string = "";

	// MARK: Methods

	protected formatDataToRow(rowItem: api.Article): IRowItem {
		return {
			id: rowItem.id,
			columns: [
				{ value: rowItem.id },
				{ value: rowItem.title },
				{ value: rowItem.text.slice(0, 50).concat("...") },
			],
		};
	}

	protected formatTableHeader(): string[] {
		const header = strings.fields;

		return [
			header.id,
			header.title,
			header.text,
		];
	}

	protected getDataItemsPerPage(pageOffset: number): Promise<api.Article[]> {
		return api.getArticles(pageOffset);
	}

	protected getSingleItemById(id: string): Promise<api.Article> {
		throw new Error("Method not implemented.");
	}

	@action
	public getArticle = async (articleId: string) => {
		if (this.articlesLoading) {
			return;
		}

		this.articlesLoading = true;

		try {
			this.selectedArticle  = await api.getArticle(articleId);
			this.setEditorFields(this.selectedArticle);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.articlesLoading = false;
		}
	};

	@action
	public createOrEditArticle = async () => {
		if (this.articlesLoading) {
			return;
		}

		this.articlesLoading = true;

		try {
			const uploadedImage = this.imageService.getSingleUploadedImage();

			if (this.selectedArticle) {
				const editArticle: api.EditedArticle = {
					image: uploadedImage ? { bytes: null, image: uploadedImage } : null,
					title: this.title,
					text: this.text,
				};

				await api.editArticle(this.selectedArticle.id, editArticle);

				uiStore.showSnackbar(strings.articles.successEdited);
			} else {
				const newArticle: api.NewArticle = {
					image: uploadedImage ? { bytes: null, image: uploadedImage } : null,
					title: this.title,
					text: this.text,
				};

				await api.createArticle(newArticle);

				uiStore.showSnackbar(strings.articles.successCreated);
			}

			routerStore.replace("/dashboard/articles");

			this.clear();
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.articlesLoading = false;
		}
	}

	@action
	public startArticleCreateOrEdit = (articleId?: string) => {
		if (articleId) {
			routerStore.push(`/dashboard/articles/editor/${articleId}`);
		} else {
			this.clear();
			this.clearArticle();
			routerStore.push("/dashboard/articles/editor");
		}
	}

	@action
	public setEditorFields = (article: api.Article) => {
		this.clear();

		if (article.image) {
			this.imageService.setSingleImage(article.image);
		}

		this.title = article.title;
		this.text = article.text;
	}

	@action
	public deleteArticle = async (articleId: string) => {

		if (this.articlesLoading) {
			return;
		}

		this.articlesLoading = true;

		try {
			uiStore.showDialog(
				strings.articles.deleteArticleDialog,
				async () => {
					try {
						await api.deleteArticle(articleId);
						this.rowItems = this.rowItems.filter((admin) => admin.id !== articleId);
					} catch (e) {
						uiStore.showErrorSnackbar(e);
					}
				},
			);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.articlesLoading = false;
		}
	}

	@action
	public clearArticle = () => {
		this.selectedArticle = null;
	}

	@action
	public clear = () => {
		this.title = "";
		this.text = "";
	}
}
