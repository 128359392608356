// MARK: Api
import * as api from "@startapp/apuama-admin-api";

// MARK: Mobx
import { action } from "mobx";

// MARK: Resources
import strings from "../../resources/strings";

// MARK: Stores
import { uiStore } from "../_rootStore";

// 	MARK: Types
import { IRowItem } from "../../components/Table/TableRow";
import { default as TableDataStore } from "../../resources/TableDataStore";

export default class CreditsUserStore extends TableDataStore<api.CreditUser> {
	private getUserId: () => string | null;

	constructor(props: { getUserId: () => string | null }) {
		super();

		this.getUserId = props.getUserId;
	}

	// MARK: Methods
	protected formatDataToRow(rowItem: api.CreditUser): IRowItem {
		return {
			id: rowItem.id,
			columns: [
				{ value: rowItem.id },
				{ value: rowItem.user.name },
				{ value: rowItem.credit.name },
			],
		};
	}

	protected formatTableHeader(): string[] {
		const header = strings.fields;

		return [
			header.id,
			header.name,
			header.credit,
		];
	}
	protected getDataItemsPerPage(pageOffset: number): Promise<api.CreditUser[]> {
		const userId = this.getUserId();

		return userId ? api.getCreditsUsersForUser(userId, pageOffset) : Promise.resolve([]);
	}

	protected getSingleItemById(id: string): Promise<api.CreditUser> {
		throw new Error("Method not implemented.");
	}

	@action
	public deleteCreditUser = async (creditUserId: string) => {
		const onConfirm = async () => {
			try {
				await api.deleteCreditUser(creditUserId);
				this.rowItems = this.rowItems.filter((creditLog) => creditLog.id !== creditUserId);
				uiStore.showSnackbar(strings.components.table.deletedSuccessfully);
			} catch (e) {
				uiStore.showErrorSnackbar(e);
			}
		};

		uiStore.showDialog(
			strings.credits.deleteDialogCreditLogs,
			onConfirm,
		);
	}

	@action
	public refresh = async () => {
		this.rowItems = [];
		await this.getData();
	}
}
