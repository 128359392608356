// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Resources
import strings from "../../../resources/strings";

interface IProps { }

export default class Home extends React.Component<IProps> {
	public render() {
		return (
			<div className="home">
				<h1>{strings.home.welcome}</h1>
			</div>
		);
	}
}
