// MARK: React
import * as React from "react";
import { observer, inject } from "mobx-react";
import "./style.scss";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";

// MARK: Components
import CreditUserCreateOrEdit from "./CreditUserCreateOrEdit";

// MARK: Implementation
interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class CreditsUsers extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.authStore.authenticate();
	};

	public render() {
		return (
			<div className="creditsUsers">
				<Switch>
					<Route path="/dashboard/creditsUsers/editor/:creditUserId" component={CreditUserCreateOrEdit}/>
					<Route path="/dashboard/creditsUsers/editor" component={CreditUserCreateOrEdit}/>
				</Switch>
			</div>
		);
	}
}
