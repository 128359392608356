// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Resources
import strings from "../../resources/strings";

// Stores
import AuthStore from "../../stores/AuthStore";
import { routerStore } from "../../stores/_rootStore";

// MARK: Layout
import MainLayout from "../../layouts/MainLayout";

// MARK: Components
import TextField from "../../components/TextField";
import Button from "../../components/Button";
import CircularProgress from "../../components/CircularProgress";
import Typography from "@material-ui/core/Typography";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Login extends React.Component<IProps> {
	public componentWillMount = async () => {
		await this.props.authStore.openDashboardIfAuthenticated();
	};

	public render() {
		const { authStore } = this.props;

		return (
			<MainLayout
				hiddenMenu={true}
				hiddenLanguageSelector={true}
			>
				<div className="login">
					{authStore.loading ?
						<CircularProgress /> : (
							<form className="login" onSubmit={(e) => e.preventDefault()}>
								<div className="loginCard">
									<Typography className="loginTitle" variant="h5">
										{strings.nav.title}
									</Typography>
									<TextField
										label={strings.fields.email}
										type="email"
										name="email"
										value={authStore.email}
										onChange={authStore.handleValueChange}
										disabled={authStore.loading}
									/>
									<TextField
										label={strings.password}
										type="password"
										name="password"
										value={authStore.password}
										onChange={authStore.handleValueChange}
										disabled={authStore.loading}
									/>
									<Button type="submit" variant="outlined" className="loginButton" onClick={() => authStore.login()}>
										{strings.login}
									</Button>

									<div className="groupButton">
										<Button onClick={() => routerStore.push("./register")}>
											{strings.register.create}
										</Button>
										<Button
											onClick={() => routerStore.push("./recovery")}
											className="recoveryLink"
										>
											{strings.recovery}
										</Button>
									</div>
								</div>
							</form>
						)
					}
				</div>
			</MainLayout>
		);
	}
}
