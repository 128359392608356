import * as api from "@startapp/apuama-admin-api";
import { observable, action, computed } from "mobx";
import { craftFileBuffer } from "../fileManager";

export default class FileUploader {

	@observable
	// tslint:disable-next-line: max-union-size
	public status: "failed" | "loading" | "success" | "uploading" | "created" = "created";

	@observable
	public uploadedFile: api.File;

	@observable
	public id: string;

	@observable
	public name: string;

	@observable
	public onUpload: boolean = false;

	@observable
	public progress: number = 0;

	@observable
	public filePath: string = "";

	@observable
	public file: File;

	@computed
	public get src() {
		if (this.uploadedFile) {
			return this.uploadedFile.url;
		} else {
			return this.filePath;
		}
	}

	@computed
	public get uploadedUncertainFile() {
		return this.uploadedFile ? {
			file: this.uploadedFile,
			bytes: null,
		} : null;
	}

	private reasonableUploadPercentage: number = 5;

	public static createUploaded(file: api.File) {
		const uploaded = new FileUploader();
		uploaded.uploadedFile = file;
		uploaded.status = "success";
		uploaded.id = file.url ;
		uploaded.name = file.name ;

		return uploaded;
	}

	constructor(file?: File) {
		if (file) {
			this.file = file;
			this.filePath = URL.createObjectURL(file);
			this.id = this.filePath;
			this.name = file.name;
		}
	}

	@action
	public uploadFile = async () => {

		const onProgress = (progress) => {
			this.progress = progress;
		};

		this.status = "loading";
		await craftFileBuffer( this.file, async (buffer) => {
			try {

				this.uploadedFile = await api.uploadFile({
					bytes: buffer,
					name: this.file.name,
				},
				(p) => onProgress(p));

				this.status = "success";
			} catch (error) {
				this.status = "failed";
				this.progress = 0;
			}
		});
	}

	@action
	private updateProgress = (progress: number) => {
		if ((progress > this.reasonableUploadPercentage) && this.status !== "failed") {
			this.status = "uploading";
		}
		this.progress = progress;
	}

	@action
	public getSendableFile() {
		return this.uploadedFile;
	}

}
