// MARK: React
import * as React from "react";

// MARK: Styles
import "./styles.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Components
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

// MARK: Implementation
interface IProps {
	title: string;
	checked: boolean;
	onChange: (value: any) => void;
}

@observer
export default class ToggleComponent extends React.Component<IProps> {
	public render() {
		const { title, checked, onChange } = this.props;
		return (
			<div className="toggleComponent">
				<FormControl component="fieldset" className="checkboxForm">
					<FormLabel component="legend">{title}</FormLabel>
					<Switch
						checked={checked}
						onChange={onChange}
						name="checked"
						color={"primary"}
						size={"medium"}
						inputProps={{ "aria-label": "secondary checkbox" }}
					/>
				</FormControl>
			</div>
		);
	}
}
