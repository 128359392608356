// MARK: React
import React from "react";
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MArk: store
import { routerStore } from "../../../../stores/_rootStore";
import CreditStore from "../../../../stores/CreditStore";

// MARK: Router
import { match } from "react-router";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Components
import Button from "../../../../components/Button";
import TextField from "../../../../components/TextField";
import { PageTitle } from "../../../../components/PageTitle";
import MaskedTextField from "../../../../components/MaskedTextField";
import FileCase from "../../../../components/FileCase";
import ImageCase from "../../../../components/ImageCase";

// MARK: Implementation
interface IProps {
	creditStore: CreditStore;
	match: match<{ creditLogId?: string, creditId?: string}>;
}

@inject("creditStore")
@observer
export default class CreditLogCreateOrEdit extends React.Component<IProps> {
	private createOrEditCreditLog = async () => {
		const creditId = this.props.match.params.creditId;
		if (creditId) {
			this.props.creditStore.createOrEditCreditLogs(creditId);
		} else {
			this.props.creditStore.createOrEditCreditLogs();
		}
	}

	public componentDidMount = async () => {
		const creditLogId = this.props.match.params.creditLogId;
		if (creditLogId) {
			await this.props.creditStore.getCreditLog(creditLogId);
		}
	};

	public render() {
		const { creditStore } = this.props;
		const { fileService } = creditStore;

		return (
			<>
				<div className="userCreateOrEditWrapper">
					<PageTitle text={creditStore.selectedCreditLog ? "Editar Aviso" : "Cadastrar Aviso"} />
					<div className="userFormContainer">
						<TextField
							label={strings.fields.title}
							name="title"
							value={creditStore.title}
							onChange={creditStore.handleValueChange}
							disabled={creditStore.creditLogsLoading}
						/>
						<FileCase
							fileUploaders={fileService.fileUploaders}
							onAddItem={fileService.selectFiles}
							onReloadItem={fileService.reloadFile}
							onRemoveItem={fileService.removeFile}
						/>
						<TextField
							label={strings.fields.text}
							name="text"
							type="text"
							value={creditStore.text}
							onChange={creditStore.handleValueChange}
							disabled={creditStore.creditLogsLoading}
							multiline={true}
							rows={10}
						/>
					</div>
					<div className="buttons">
						<Button
							type="submit"
							variant="outlined"
							color="primary"
							onClick={() => routerStore.push(`/dashboard/credits`)}
							loading={creditStore.creditLogsLoading}
						>
							{strings.register.back}
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							onClick={this.createOrEditCreditLog}
							loading={creditStore.creditLogsLoading}
						>
							{strings.register.finish}
						</Button>
					</div>
				</div>
			</>
		);
	}
}
