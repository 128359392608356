// MARK: Api
import * as api from "@startapp/apuama-admin-api";

// MARK: Mobx
import { action, observable } from "mobx";

// MARK: Resources
import strings from "../../resources/strings";

// 	MARK: Types
import { IRowItem } from "../../components/Table/TableRow";
import { default as TableDataStore } from "../../resources/TableDataStore";

export default class CreditPickerStore extends TableDataStore<api.Credit> {
	@observable public open: boolean;
	@observable public search: string;

	@action
	public toggleOpen = () => {
		this.open = !this.open;
	}

	constructor() {
		super();

		this.clear();
	}

	// MARK: Methods
	@action
	public clear = () => {
		this.open = false;
		this.search = "";
	}

	@action
	public refreshData = () => {
		this.pageOffset = 0;
		this.rowItems = [];
		this.getData();
	}

	protected formatDataToRow(rowItem: api.Credit): IRowItem {
		return {
			id: rowItem.id,
			columns: [
				{ value: rowItem.id },
				{ value: rowItem.name },
			],
		};
	}

	protected formatTableHeader(): string[] {
		const header = strings.fields;

		return [
			header.id,
			header.name,
		];
	}
	protected getDataItemsPerPage(pageOffset: number): Promise<api.Credit[]> {
		return api.getCredits(
			pageOffset,
			{ name: this.search.trim().length > 0 ? this.search : null, ended: false },
		);
	}

	protected getSingleItemById(id: string): Promise<api.Credit> {
		throw new Error("Method not implemented.");
	}
}
