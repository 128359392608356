import React from "react";

interface IProps {
	text: string;
}

export function PageTitle(props: IProps) {

	return (
		<h1
			style={{
				fontSize: "2em",
				fontWeight: "bold",
				boxSizing: "border-box",
				color: "black",
			}}
		>
			{props.text}
		</h1>
	);
}
