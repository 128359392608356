// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Material Components
import { Button as MButton } from "@material-ui/core";
import { Typography as MTypography } from "@material-ui/core";

// MARK: Components
import ImageItem from "../ImageItem";

// MARK: Icons
import { AddPhotoAlternate as MAddPhotoAlternateIcon } from "@material-ui/icons";

// MARK: Resources
import strings from "../../resources/strings";
import ImageUploader from "../../resources/models/ImageUploader";

interface IProps {
	onAddItem: () => void;
	onRemoveItem: (fileId: string) => void;
	onReloadItem: (fileId: string) => void;
	imageUploaders: ImageUploader[] | ImageUploader | null;
	title?: string;
}

@observer
export default class ImageCase extends React.Component<IProps> {

	public renderIfNoImagesYet = () => {
		if (Array.isArray(this.props.imageUploaders) && this.props.imageUploaders.length === 0) {
			return <h4>{strings.imageCase.addImagesPlaceholder}</h4>;
		} else if (!this.props.imageUploaders) {
			return <h4>{strings.imageCase.addImagePlaceholder}</h4>;
		}
		return;
	};

	public render() {

		const { onAddItem, imageUploaders, onRemoveItem, onReloadItem, title } = this.props;

		return (
			<>
				<div className="imageCaseHeader">
					<MTypography variant="h5">{title ? title : "Imagens"}</MTypography>
					<MButton
						size="small"
						variant="outlined"
						onClick={onAddItem}
						className="imageCaseHeaderButton"
					>
						<MAddPhotoAlternateIcon style={{ marginRight: 12 }} /> {strings.imageCase.addImageBtn}
					</MButton>
				</div>
				{
					imageUploaders ?
						Array.isArray(imageUploaders) ? (
							<div className="imageCase">
								{imageUploaders.map((uploader) => (
									<ImageItem
										key={uploader.id}
										uploader={uploader}
										removeItem={() => onRemoveItem(uploader.id)}
										reloadItem={() => onReloadItem(uploader.id)}
									/>
								))}
							</div>
						) : (
								<div className="imageCase" style={{ border: "solid 1px gray" }}>
									<ImageItem
										key={imageUploaders.id}
										uploader={imageUploaders}
										removeItem={() => onRemoveItem(imageUploaders.id)}
										reloadItem={() => onReloadItem(imageUploaders.id)}
									/>
								</div>
							) :
						null
				}
				{this.renderIfNoImagesYet()}
			</>
		);
	}
}
