// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Material Components
import { Button as MButton } from "@material-ui/core";
import { Typography as MTypography } from "@material-ui/core";

// MARK: Components
import FileItem from "../FileItem";

// MARK: Icons
import { Add as MAaddIcon } from "@material-ui/icons";

// MARK: Resources
import strings from "../../resources/strings";
import FileUploader from "../../resources/models/FileUploader";

interface IProps {
	onAddItem: () => void;
	onRemoveItem: (fileId: string) => void;
	onReloadItem: (fileId: string) => void;
	fileUploaders: FileUploader[] | FileUploader | null;
	title?: string;
}

@observer
export default class FileCase extends React.Component<IProps> {

	public renderIfNoImagesYet = () => {
		if (Array.isArray(this.props.fileUploaders) && this.props.fileUploaders.length === 0) {
			return <h4>{strings.imageCase.addFilesPlaceholder}</h4>;
		} else if (!this.props.fileUploaders) {
			return <h4>{strings.imageCase.addFilePlaceholder}</h4>;
		}
		return;
	};

	public render() {

		const { onAddItem, fileUploaders, onRemoveItem, onReloadItem, title } = this.props;

		return (
			<>
				<div className="fileCaseHeader">
					<MTypography variant="h5">{title ? title : "Arquivos"}</MTypography>
					<MButton
						size="small"
						variant="outlined"
						onClick={onAddItem}
						className="fileCaseHeaderButton"
					>
						<MAaddIcon style={{ marginRight: 12 }} /> {strings.imageCase.addImageBtn}
					</MButton>
				</div>
				{
					fileUploaders ?
						Array.isArray(fileUploaders) ? (
							<div className="fileCase">
								{fileUploaders.map((uploader) => (
									<FileItem
										key={uploader.id}
										uploader={uploader}
										name={uploader.name}
										removeItem={() => onRemoveItem(uploader.id)}
										reloadItem={() => onReloadItem(uploader.id)}
									/>
								))}
							</div>
						) : (
								<div className="fileCase" style={{ border: "solid 1px gray" }}>
									<FileItem
										key={fileUploaders.id}
										uploader={fileUploaders}
										name={fileUploaders.name}
										removeItem={() => onRemoveItem(fileUploaders.id)}
										reloadItem={() => onReloadItem(fileUploaders.id)}
									/>
								</div>
							) :
						null
				}
				{this.renderIfNoImagesYet()}
			</>
		);
	}
}
