// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Router
import { match } from "react-router";

// MARK: Components
import PageTable from "../../../../components/PageTable";

// MARK: Stores
import CreditStore from "../../../../stores/CreditStore";

// MARK: Resources
import strings from "../../../../resources/strings";

interface IProps {
	creditStore: CreditStore;
	match: match<{ userId?: string }>;
}

@inject("creditStore")
@observer
export default class CreditTable extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.creditStore.fetchInitialData();
	}

	public render() {
		const { creditStore } = this.props;
		return (
			<div className="creditsTableContainer">
				<div className="creditsTable">
					<PageTable
						filter={{
							clearFilter: creditStore.onClearFilter,
							onFilter: creditStore.onFilter,
							filters: [
								{
									title: strings.filter.nameFilterTitle,
									type: "text",
									filterTextService: creditStore.filterNameService,
								},
								{
									title: strings.filter.statusFilterTitle,
									type: "boolean",
									filterBooleanService: creditStore.filterEndedService,
								},
							],
							badgesFilter: creditStore.badgesFilter,
						}}
						tableConfig={{
							title: strings.credits.title,
							loading: creditStore.loading,
							header: creditStore.tableHeader,
							nextPage: creditStore.nextPage,
							prevPage: creditStore.previousPage,
							rows: creditStore.tableRows,
							pageOffset: creditStore.pageOffset,
							onEditClick: (creditId) => creditStore.startCreateOrEdit(creditId),
							onAddClick: () => creditStore.startCreateOrEdit(),
							onDetailClick: (creditId) => creditStore.startCreditDetails(creditId),
						}}
						onExportTable={() => creditStore.getCreditsExport()}
					/>
				</div>
			</div>
		);
	}
}
