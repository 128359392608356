import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface LatLng {
    lat: number;
    lng: number;
}

export interface File {
    name: string;
    url: string;
}

export interface AdminUser {
    id: string;
    name: string;
    email: string;
    phone: string | null;
    whatsapp: string | null;
}

export interface AdminUserDetails {
    name: string;
    email: string;
    phone: string | null;
    whatsapp: string | null;
}

export interface NewAdminUser {
    password: string;
    name: string;
    email: string;
    phone: string | null;
    whatsapp: string | null;
}

export interface EditAdminUser {
    name: string;
    email: string;
    phone: string | null;
    whatsapp: string | null;
}

export interface AdminUserFilter {
    name: string | null;
}

export interface ImageCrop {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface UncertainFile {
    fileData: UploadFile | null;
    file: File | null;
}

export interface UploadFile {
    bytes: Buffer;
    name: string;
}

export interface Article {
    id: string;
    image: Image | null;
    title: string;
    text: string;
}

export interface ArticleDetails {
    image: UncertainImage | null;
    title: string;
    text: string;
}

export interface NewArticle {
    image: UncertainImage | null;
    title: string;
    text: string;
}

export interface EditedArticle {
    image: UncertainImage | null;
    title: string;
    text: string;
}

export interface BankAccount {
    bank: string;
    agency: string;
    agencyDV: string | null;
    account: string;
    accountDV: string;
}

export interface User {
    id: string;
    documentNumber: string;
    bankAccount: BankAccount | null;
    adminUser: AdminUser;
    name: string;
    email: string;
    phone: string | null;
    whatsapp: string | null;
}

export interface UserDetails {
    name: string;
    email: string;
    phone: string | null;
    whatsapp: string | null;
}

export interface CreditUserDetails {
    investedAmount: number;
    investedAt: Date;
    expectedReturn: number;
    attachedFiles: File[];
    fulfilled: CreditUserDetailsFulfilled | null;
}

export interface CreditUserDetailsFulfilled {
    amount: number;
    taxAmount: number;
    date: Date;
}

export interface Credit {
    id: string;
    image: Image | null;
    name: string;
    description: string;
    incomeTaxDisclaimer: string | null;
    ended: boolean;
    deadlineDate: Date;
}

export interface CreditDetails {
    name: string;
    description: string;
    incomeTaxDisclaimer: string | null;
    ended: boolean;
    deadlineDate: Date;
}

export interface CreditLog {
    id: string;
    attachedFiles: File[];
    createdAt: Date;
    title: string;
    text: string;
}

export interface CreditLogDetails {
    title: string;
    text: string;
}

export interface CreditsFilter {
    name: string | null;
    ended: boolean | null;
}

export interface CreditUser {
    id: string;
    credit: Credit;
    user: User;
    fulfilled: CreditUserFulfilled | null;
    investedAmount: number;
    investedAt: Date;
    expectedReturn: number;
    attachedFiles: File[];
}

export interface CreditUserFulfilled {
    amount: number;
    taxAmount: number;
    date: Date;
}

export interface NewCredit {
    image: UncertainImage | null;
    name: string;
    description: string;
    incomeTaxDisclaimer: string | null;
    ended: boolean;
    deadlineDate: Date;
}

export interface EditedCredit {
    image: UncertainImage | null;
    name: string;
    description: string;
    incomeTaxDisclaimer: string | null;
    ended: boolean;
    deadlineDate: Date;
}

export interface NewCreditUser {
    userId: string;
    creditId: string;
    attachedFiles: UncertainFile[];
    investedAmount: number;
    investedAt: Date;
    expectedReturn: number;
    fulfilled: CreditUserDetailsFulfilled | null;
}

export interface EditedCreditUser {
    attachedFiles: UncertainFile[];
    investedAmount: number;
    investedAt: Date;
    expectedReturn: number;
    fulfilled: CreditUserDetailsFulfilled | null;
}

export interface NewCreditLog {
    creditId: string;
    attachedFiles: UncertainFile[];
    title: string;
    text: string;
}

export interface EditedCreditLog {
    attachedFiles: UncertainFile[];
    title: string;
    text: string;
}

export interface UserFilter {
    name: string | null;
    email: string | null;
}

export interface NewUser {
    adminUserId: string;
    documentNumber: string;
    bankAccount: BankAccount | null;
    password: string;
    name: string;
    email: string;
    phone: string | null;
    whatsapp: string | null;
}

export interface EditUser {
    adminUserId: string;
    bankAccount: BankAccount | null;
    name: string;
    email: string;
    phone: string | null;
    whatsapp: string | null;
}

export enum Language {
    ptBr = "ptBr",
}

export function translateLanguage(enumLanguage: Language): string {
    switch (enumLanguage) {
        case Language.ptBr: {
            return strings ? strings["enum"]["Language"]["ptBr"] || Language.ptBr : Language.ptBr;
        }
    }
    return "";
}

export function allValuesLanguage(): Language[] {
    return [
        Language.ptBr,
    ];
}

export function allTranslatedValuesLanguage(): string[] {
    return [
        translateLanguage(Language.ptBr),
    ];
}

export function allDisplayableValuesLanguage(): string[] {
    return allTranslatedValuesLanguage().sort();
}

export function valueFromTranslationLanguage(translation: string): Language {
    const index = allTranslatedValuesLanguage().indexOf(translation);
    return allValuesLanguage()[index] || Language.ptBr;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    BadFormattedResponse = "BadFormattedResponse",
    InvalidDate = "InvalidDate",
    FailedUpload = "FailedUpload",
    NotLoggedIn = "NotLoggedIn",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    EmailAlreadyInUse = "EmailAlreadyInUse",
    InvalidEmail = "InvalidEmail",
    ExpiredResetPasswordToken = "ExpiredResetPasswordToken",
    LoginError = "LoginError",
    UserDoesntExist = "UserDoesntExist",
    AlreadyRegistered = "AlreadyRegistered",
    CreditFulfilled = "CreditFulfilled",
    AccessNotAllowed = "AccessNotAllowed",
    ActionNotAllowed = "ActionNotAllowed",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InvalidDate: {
            return strings ? strings["enum"]["ErrorType"]["InvalidDate"] || ErrorType.InvalidDate : ErrorType.InvalidDate;
        }
        case ErrorType.FailedUpload: {
            return strings ? strings["enum"]["ErrorType"]["FailedUpload"] || ErrorType.FailedUpload : ErrorType.FailedUpload;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.EmailAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyInUse"] || ErrorType.EmailAlreadyInUse : ErrorType.EmailAlreadyInUse;
        }
        case ErrorType.InvalidEmail: {
            return strings ? strings["enum"]["ErrorType"]["InvalidEmail"] || ErrorType.InvalidEmail : ErrorType.InvalidEmail;
        }
        case ErrorType.ExpiredResetPasswordToken: {
            return strings ? strings["enum"]["ErrorType"]["ExpiredResetPasswordToken"] || ErrorType.ExpiredResetPasswordToken : ErrorType.ExpiredResetPasswordToken;
        }
        case ErrorType.LoginError: {
            return strings ? strings["enum"]["ErrorType"]["LoginError"] || ErrorType.LoginError : ErrorType.LoginError;
        }
        case ErrorType.UserDoesntExist: {
            return strings ? strings["enum"]["ErrorType"]["UserDoesntExist"] || ErrorType.UserDoesntExist : ErrorType.UserDoesntExist;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.CreditFulfilled: {
            return strings ? strings["enum"]["ErrorType"]["CreditFulfilled"] || ErrorType.CreditFulfilled : ErrorType.CreditFulfilled;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.ActionNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["ActionNotAllowed"] || ErrorType.ActionNotAllowed : ErrorType.ActionNotAllowed;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.BadFormattedResponse,
        ErrorType.InvalidDate,
        ErrorType.FailedUpload,
        ErrorType.NotLoggedIn,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.EmailAlreadyInUse,
        ErrorType.InvalidEmail,
        ErrorType.ExpiredResetPasswordToken,
        ErrorType.LoginError,
        ErrorType.UserDoesntExist,
        ErrorType.AlreadyRegistered,
        ErrorType.CreditFulfilled,
        ErrorType.AccessNotAllowed,
        ErrorType.ActionNotAllowed,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InvalidDate),
        translateErrorType(ErrorType.FailedUpload),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.EmailAlreadyInUse),
        translateErrorType(ErrorType.InvalidEmail),
        translateErrorType(ErrorType.ExpiredResetPasswordToken),
        translateErrorType(ErrorType.LoginError),
        translateErrorType(ErrorType.UserDoesntExist),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.CreditFulfilled),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.ActionNotAllowed),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function getCurrentAdminUser(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminUser", args: {}, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
    };
}

export async function getAdminUser(adminUserId: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
    };
    const ret = await makeRequest({name: "getAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
    };
}

export async function getAdminUsers(pageOffset: number, adminUserFilter: AdminUserFilter | null, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
        adminUserFilter: adminUserFilter === null || adminUserFilter === undefined ? null : {
            name: adminUserFilter.name === null || adminUserFilter.name === undefined ? null : adminUserFilter.name,
        },
    };
    const ret = await makeRequest({name: "getAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        email: e.email,
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        whatsapp: e.whatsapp === null || e.whatsapp === undefined ? null : e.whatsapp,
    }));
}

export async function createAdminUser(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            password: newAdminUser.password,
            name: newAdminUser.name,
            email: newAdminUser.email,
            phone: newAdminUser.phone === null || newAdminUser.phone === undefined ? null : newAdminUser.phone,
            whatsapp: newAdminUser.whatsapp === null || newAdminUser.whatsapp === undefined ? null : newAdminUser.whatsapp,
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
    };
}

export async function editAdminUser(adminUserId: string, editedAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
        editedAdminUser: {
            name: editedAdminUser.name,
            email: editedAdminUser.email,
            phone: editedAdminUser.phone === null || editedAdminUser.phone === undefined ? null : editedAdminUser.phone,
            whatsapp: editedAdminUser.whatsapp === null || editedAdminUser.whatsapp === undefined ? null : editedAdminUser.whatsapp,
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
    };
}

export async function deleteAdminUser(adminUserId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        adminUserId: adminUserId,
    };
    await makeRequest({name: "deleteAdminUser", args, progress});
    return undefined;
}

export async function login(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "login", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
    };
}

export async function logout(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logout", args: {}, progress});
    return undefined;
}

export async function sendResetPasswordEmail(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendResetPasswordEmail", args, progress});
    return undefined;
}

export async function resetPasswordEmail(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPasswordEmail", args, progress});
    return undefined;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, imageCrop: ImageCrop | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
        imageCrop: imageCrop === null || imageCrop === undefined ? null : {
            x: imageCrop.x || 0,
            y: imageCrop.y || 0,
            width: imageCrop.width || 0,
            height: imageCrop.height || 0,
        },
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncertainImage(image: UncertainImage, imageFormat: ImageFormat, imageCrop: ImageCrop | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat,
        imageCrop: imageCrop === null || imageCrop === undefined ? null : {
            x: imageCrop.x || 0,
            y: imageCrop.y || 0,
            width: imageCrop.width || 0,
            height: imageCrop.height || 0,
        },
    };
    const ret = await makeRequest({name: "uploadUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function cropImage(src: string, imageCrop: ImageCrop, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        src: src,
        imageCrop: {
            x: imageCrop.x || 0,
            y: imageCrop.y || 0,
            width: imageCrop.width || 0,
            height: imageCrop.height || 0,
        },
    };
    const ret = await makeRequest({name: "cropImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadFile(file: UploadFile, progress?: (progress: number) => void): Promise<File> {
    const args = {
        file: {
            bytes: file.bytes.toString("base64"),
            name: file.name,
        },
    };
    const ret = await makeRequest({name: "uploadFile", args, progress});
    return {
        name: ret.name,
        url: ret.url,
    };
}

export async function uploadUncertainFile(file: UncertainFile, progress?: (progress: number) => void): Promise<File> {
    const args = {
        file: {
            fileData: file.fileData === null || file.fileData === undefined ? null : {
                bytes: file.fileData.bytes.toString("base64"),
                name: file.fileData.name,
            },
            file: file.file === null || file.file === undefined ? null : {
                name: file.file.name,
                url: file.file.url,
            },
        },
    };
    const ret = await makeRequest({name: "uploadUncertainFile", args, progress});
    return {
        name: ret.name,
        url: ret.url,
    };
}

export async function getArticles(pageOffset: number | null, progress?: (progress: number) => void): Promise<Article[]> {
    const args = {
        pageOffset: pageOffset === null || pageOffset === undefined ? null : pageOffset || 0,
    };
    const ret = await makeRequest({name: "getArticles", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        title: e.title,
        text: e.text,
    }));
}

export async function getArticle(articleId: string, progress?: (progress: number) => void): Promise<Article> {
    const args = {
        articleId: articleId,
    };
    const ret = await makeRequest({name: "getArticle", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        text: ret.text,
    };
}

export async function createArticle(newArticle: NewArticle, progress?: (progress: number) => void): Promise<Article> {
    const args = {
        newArticle: {
            image: newArticle.image === null || newArticle.image === undefined ? null : {
                bytes: newArticle.image.bytes === null || newArticle.image.bytes === undefined ? null : newArticle.image.bytes.toString("base64"),
                image: newArticle.image.image === null || newArticle.image.image === undefined ? null : {
                    thumb: {
                        width: newArticle.image.image.thumb.width || 0,
                        height: newArticle.image.image.thumb.height || 0,
                        url: newArticle.image.image.thumb.url,
                    },
                    width: newArticle.image.image.width || 0,
                    height: newArticle.image.image.height || 0,
                    url: newArticle.image.image.url,
                },
            },
            title: newArticle.title,
            text: newArticle.text,
        },
    };
    const ret = await makeRequest({name: "createArticle", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        text: ret.text,
    };
}

export async function editArticle(articleId: string, editedArticle: EditedArticle, progress?: (progress: number) => void): Promise<Article> {
    const args = {
        articleId: articleId,
        editedArticle: {
            image: editedArticle.image === null || editedArticle.image === undefined ? null : {
                bytes: editedArticle.image.bytes === null || editedArticle.image.bytes === undefined ? null : editedArticle.image.bytes.toString("base64"),
                image: editedArticle.image.image === null || editedArticle.image.image === undefined ? null : {
                    thumb: {
                        width: editedArticle.image.image.thumb.width || 0,
                        height: editedArticle.image.image.thumb.height || 0,
                        url: editedArticle.image.image.thumb.url,
                    },
                    width: editedArticle.image.image.width || 0,
                    height: editedArticle.image.image.height || 0,
                    url: editedArticle.image.image.url,
                },
            },
            title: editedArticle.title,
            text: editedArticle.text,
        },
    };
    const ret = await makeRequest({name: "editArticle", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        text: ret.text,
    };
}

export async function deleteArticle(articleId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        articleId: articleId,
    };
    await makeRequest({name: "deleteArticle", args, progress});
    return undefined;
}

export async function getCredit(creditId: string, progress?: (progress: number) => void): Promise<Credit> {
    const args = {
        creditId: creditId,
    };
    const ret = await makeRequest({name: "getCredit", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        name: ret.name,
        description: ret.description,
        incomeTaxDisclaimer: ret.incomeTaxDisclaimer === null || ret.incomeTaxDisclaimer === undefined ? null : ret.incomeTaxDisclaimer,
        ended: !!ret.ended,
        deadlineDate: new Date(parseInt(ret.deadlineDate.split("-")[0], 10), parseInt(ret.deadlineDate.split("-")[1], 10) - 1, parseInt(ret.deadlineDate.split("-")[2], 10)),
    };
}

export async function getCredits(pageOffset: number | null, creditsFilter: CreditsFilter | null, progress?: (progress: number) => void): Promise<Credit[]> {
    const args = {
        pageOffset: pageOffset === null || pageOffset === undefined ? null : pageOffset || 0,
        creditsFilter: creditsFilter === null || creditsFilter === undefined ? null : {
            name: creditsFilter.name === null || creditsFilter.name === undefined ? null : creditsFilter.name,
            ended: creditsFilter.ended === null || creditsFilter.ended === undefined ? null : !!creditsFilter.ended,
        },
    };
    const ret = await makeRequest({name: "getCredits", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        name: e.name,
        description: e.description,
        incomeTaxDisclaimer: e.incomeTaxDisclaimer === null || e.incomeTaxDisclaimer === undefined ? null : e.incomeTaxDisclaimer,
        ended: !!e.ended,
        deadlineDate: new Date(parseInt(e.deadlineDate.split("-")[0], 10), parseInt(e.deadlineDate.split("-")[1], 10) - 1, parseInt(e.deadlineDate.split("-")[2], 10)),
    }));
}

export async function createCredit(newCredit: NewCredit, progress?: (progress: number) => void): Promise<Credit> {
    const args = {
        newCredit: {
            image: newCredit.image === null || newCredit.image === undefined ? null : {
                bytes: newCredit.image.bytes === null || newCredit.image.bytes === undefined ? null : newCredit.image.bytes.toString("base64"),
                image: newCredit.image.image === null || newCredit.image.image === undefined ? null : {
                    thumb: {
                        width: newCredit.image.image.thumb.width || 0,
                        height: newCredit.image.image.thumb.height || 0,
                        url: newCredit.image.image.thumb.url,
                    },
                    width: newCredit.image.image.width || 0,
                    height: newCredit.image.image.height || 0,
                    url: newCredit.image.image.url,
                },
            },
            name: newCredit.name,
            description: newCredit.description,
            incomeTaxDisclaimer: newCredit.incomeTaxDisclaimer === null || newCredit.incomeTaxDisclaimer === undefined ? null : newCredit.incomeTaxDisclaimer,
            ended: !!newCredit.ended,
            deadlineDate: typeof(newCredit.deadlineDate) === "string" ? new Date(new Date(newCredit.deadlineDate).getTime() - new Date(newCredit.deadlineDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newCredit.deadlineDate.getTime() - newCredit.deadlineDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "createCredit", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        name: ret.name,
        description: ret.description,
        incomeTaxDisclaimer: ret.incomeTaxDisclaimer === null || ret.incomeTaxDisclaimer === undefined ? null : ret.incomeTaxDisclaimer,
        ended: !!ret.ended,
        deadlineDate: new Date(parseInt(ret.deadlineDate.split("-")[0], 10), parseInt(ret.deadlineDate.split("-")[1], 10) - 1, parseInt(ret.deadlineDate.split("-")[2], 10)),
    };
}

export async function editCredit(creditId: string, editedCredit: EditedCredit, progress?: (progress: number) => void): Promise<Credit> {
    const args = {
        creditId: creditId,
        editedCredit: {
            image: editedCredit.image === null || editedCredit.image === undefined ? null : {
                bytes: editedCredit.image.bytes === null || editedCredit.image.bytes === undefined ? null : editedCredit.image.bytes.toString("base64"),
                image: editedCredit.image.image === null || editedCredit.image.image === undefined ? null : {
                    thumb: {
                        width: editedCredit.image.image.thumb.width || 0,
                        height: editedCredit.image.image.thumb.height || 0,
                        url: editedCredit.image.image.thumb.url,
                    },
                    width: editedCredit.image.image.width || 0,
                    height: editedCredit.image.image.height || 0,
                    url: editedCredit.image.image.url,
                },
            },
            name: editedCredit.name,
            description: editedCredit.description,
            incomeTaxDisclaimer: editedCredit.incomeTaxDisclaimer === null || editedCredit.incomeTaxDisclaimer === undefined ? null : editedCredit.incomeTaxDisclaimer,
            ended: !!editedCredit.ended,
            deadlineDate: typeof(editedCredit.deadlineDate) === "string" ? new Date(new Date(editedCredit.deadlineDate).getTime() - new Date(editedCredit.deadlineDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editedCredit.deadlineDate.getTime() - editedCredit.deadlineDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "editCredit", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        name: ret.name,
        description: ret.description,
        incomeTaxDisclaimer: ret.incomeTaxDisclaimer === null || ret.incomeTaxDisclaimer === undefined ? null : ret.incomeTaxDisclaimer,
        ended: !!ret.ended,
        deadlineDate: new Date(parseInt(ret.deadlineDate.split("-")[0], 10), parseInt(ret.deadlineDate.split("-")[1], 10) - 1, parseInt(ret.deadlineDate.split("-")[2], 10)),
    };
}

export async function getCreditUser(creditUserId: string, progress?: (progress: number) => void): Promise<CreditUser> {
    const args = {
        creditUserId: creditUserId,
    };
    const ret = await makeRequest({name: "getCreditUser", args, progress});
    return {
        id: ret.id,
        credit: {
            id: ret.credit.id,
            image: ret.credit.image === null || ret.credit.image === undefined ? null : {
                thumb: {
                    width: ret.credit.image.thumb.width || 0,
                    height: ret.credit.image.thumb.height || 0,
                    url: ret.credit.image.thumb.url,
                },
                width: ret.credit.image.width || 0,
                height: ret.credit.image.height || 0,
                url: ret.credit.image.url,
            },
            name: ret.credit.name,
            description: ret.credit.description,
            incomeTaxDisclaimer: ret.credit.incomeTaxDisclaimer === null || ret.credit.incomeTaxDisclaimer === undefined ? null : ret.credit.incomeTaxDisclaimer,
            ended: !!ret.credit.ended,
            deadlineDate: new Date(parseInt(ret.credit.deadlineDate.split("-")[0], 10), parseInt(ret.credit.deadlineDate.split("-")[1], 10) - 1, parseInt(ret.credit.deadlineDate.split("-")[2], 10)),
        },
        user: {
            id: ret.user.id,
            documentNumber: ret.user.documentNumber,
            bankAccount: ret.user.bankAccount === null || ret.user.bankAccount === undefined ? null : {
                bank: ret.user.bankAccount.bank,
                agency: ret.user.bankAccount.agency,
                agencyDV: ret.user.bankAccount.agencyDV === null || ret.user.bankAccount.agencyDV === undefined ? null : ret.user.bankAccount.agencyDV,
                account: ret.user.bankAccount.account,
                accountDV: ret.user.bankAccount.accountDV,
            },
            adminUser: {
                id: ret.user.adminUser.id,
                name: ret.user.adminUser.name,
                email: ret.user.adminUser.email,
                phone: ret.user.adminUser.phone === null || ret.user.adminUser.phone === undefined ? null : ret.user.adminUser.phone,
                whatsapp: ret.user.adminUser.whatsapp === null || ret.user.adminUser.whatsapp === undefined ? null : ret.user.adminUser.whatsapp,
            },
            name: ret.user.name,
            email: ret.user.email,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            whatsapp: ret.user.whatsapp === null || ret.user.whatsapp === undefined ? null : ret.user.whatsapp,
        },
        fulfilled: ret.fulfilled === null || ret.fulfilled === undefined ? null : {
            amount: ret.fulfilled.amount || 0,
            taxAmount: ret.fulfilled.taxAmount || 0,
            date: new Date(parseInt(ret.fulfilled.date.split("-")[0], 10), parseInt(ret.fulfilled.date.split("-")[1], 10) - 1, parseInt(ret.fulfilled.date.split("-")[2], 10)),
        },
        investedAmount: ret.investedAmount || 0,
        investedAt: new Date(parseInt(ret.investedAt.split("-")[0], 10), parseInt(ret.investedAt.split("-")[1], 10) - 1, parseInt(ret.investedAt.split("-")[2], 10)),
        expectedReturn: ret.expectedReturn,
        attachedFiles: ret.attachedFiles.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
    };
}

export async function getCreditsUsersForUser(userId: string, pageOffset: number | null, progress?: (progress: number) => void): Promise<CreditUser[]> {
    const args = {
        userId: userId,
        pageOffset: pageOffset === null || pageOffset === undefined ? null : pageOffset || 0,
    };
    const ret = await makeRequest({name: "getCreditsUsersForUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        credit: {
            id: e.credit.id,
            image: e.credit.image === null || e.credit.image === undefined ? null : {
                thumb: {
                    width: e.credit.image.thumb.width || 0,
                    height: e.credit.image.thumb.height || 0,
                    url: e.credit.image.thumb.url,
                },
                width: e.credit.image.width || 0,
                height: e.credit.image.height || 0,
                url: e.credit.image.url,
            },
            name: e.credit.name,
            description: e.credit.description,
            incomeTaxDisclaimer: e.credit.incomeTaxDisclaimer === null || e.credit.incomeTaxDisclaimer === undefined ? null : e.credit.incomeTaxDisclaimer,
            ended: !!e.credit.ended,
            deadlineDate: new Date(parseInt(e.credit.deadlineDate.split("-")[0], 10), parseInt(e.credit.deadlineDate.split("-")[1], 10) - 1, parseInt(e.credit.deadlineDate.split("-")[2], 10)),
        },
        user: {
            id: e.user.id,
            documentNumber: e.user.documentNumber,
            bankAccount: e.user.bankAccount === null || e.user.bankAccount === undefined ? null : {
                bank: e.user.bankAccount.bank,
                agency: e.user.bankAccount.agency,
                agencyDV: e.user.bankAccount.agencyDV === null || e.user.bankAccount.agencyDV === undefined ? null : e.user.bankAccount.agencyDV,
                account: e.user.bankAccount.account,
                accountDV: e.user.bankAccount.accountDV,
            },
            adminUser: {
                id: e.user.adminUser.id,
                name: e.user.adminUser.name,
                email: e.user.adminUser.email,
                phone: e.user.adminUser.phone === null || e.user.adminUser.phone === undefined ? null : e.user.adminUser.phone,
                whatsapp: e.user.adminUser.whatsapp === null || e.user.adminUser.whatsapp === undefined ? null : e.user.adminUser.whatsapp,
            },
            name: e.user.name,
            email: e.user.email,
            phone: e.user.phone === null || e.user.phone === undefined ? null : e.user.phone,
            whatsapp: e.user.whatsapp === null || e.user.whatsapp === undefined ? null : e.user.whatsapp,
        },
        fulfilled: e.fulfilled === null || e.fulfilled === undefined ? null : {
            amount: e.fulfilled.amount || 0,
            taxAmount: e.fulfilled.taxAmount || 0,
            date: new Date(parseInt(e.fulfilled.date.split("-")[0], 10), parseInt(e.fulfilled.date.split("-")[1], 10) - 1, parseInt(e.fulfilled.date.split("-")[2], 10)),
        },
        investedAmount: e.investedAmount || 0,
        investedAt: new Date(parseInt(e.investedAt.split("-")[0], 10), parseInt(e.investedAt.split("-")[1], 10) - 1, parseInt(e.investedAt.split("-")[2], 10)),
        expectedReturn: e.expectedReturn,
        attachedFiles: e.attachedFiles.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
    }));
}

export async function getCreditsUsersForCredit(creditId: string, pageOffset: number | null, progress?: (progress: number) => void): Promise<CreditUser[]> {
    const args = {
        creditId: creditId,
        pageOffset: pageOffset === null || pageOffset === undefined ? null : pageOffset || 0,
    };
    const ret = await makeRequest({name: "getCreditsUsersForCredit", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        credit: {
            id: e.credit.id,
            image: e.credit.image === null || e.credit.image === undefined ? null : {
                thumb: {
                    width: e.credit.image.thumb.width || 0,
                    height: e.credit.image.thumb.height || 0,
                    url: e.credit.image.thumb.url,
                },
                width: e.credit.image.width || 0,
                height: e.credit.image.height || 0,
                url: e.credit.image.url,
            },
            name: e.credit.name,
            description: e.credit.description,
            incomeTaxDisclaimer: e.credit.incomeTaxDisclaimer === null || e.credit.incomeTaxDisclaimer === undefined ? null : e.credit.incomeTaxDisclaimer,
            ended: !!e.credit.ended,
            deadlineDate: new Date(parseInt(e.credit.deadlineDate.split("-")[0], 10), parseInt(e.credit.deadlineDate.split("-")[1], 10) - 1, parseInt(e.credit.deadlineDate.split("-")[2], 10)),
        },
        user: {
            id: e.user.id,
            documentNumber: e.user.documentNumber,
            bankAccount: e.user.bankAccount === null || e.user.bankAccount === undefined ? null : {
                bank: e.user.bankAccount.bank,
                agency: e.user.bankAccount.agency,
                agencyDV: e.user.bankAccount.agencyDV === null || e.user.bankAccount.agencyDV === undefined ? null : e.user.bankAccount.agencyDV,
                account: e.user.bankAccount.account,
                accountDV: e.user.bankAccount.accountDV,
            },
            adminUser: {
                id: e.user.adminUser.id,
                name: e.user.adminUser.name,
                email: e.user.adminUser.email,
                phone: e.user.adminUser.phone === null || e.user.adminUser.phone === undefined ? null : e.user.adminUser.phone,
                whatsapp: e.user.adminUser.whatsapp === null || e.user.adminUser.whatsapp === undefined ? null : e.user.adminUser.whatsapp,
            },
            name: e.user.name,
            email: e.user.email,
            phone: e.user.phone === null || e.user.phone === undefined ? null : e.user.phone,
            whatsapp: e.user.whatsapp === null || e.user.whatsapp === undefined ? null : e.user.whatsapp,
        },
        fulfilled: e.fulfilled === null || e.fulfilled === undefined ? null : {
            amount: e.fulfilled.amount || 0,
            taxAmount: e.fulfilled.taxAmount || 0,
            date: new Date(parseInt(e.fulfilled.date.split("-")[0], 10), parseInt(e.fulfilled.date.split("-")[1], 10) - 1, parseInt(e.fulfilled.date.split("-")[2], 10)),
        },
        investedAmount: e.investedAmount || 0,
        investedAt: new Date(parseInt(e.investedAt.split("-")[0], 10), parseInt(e.investedAt.split("-")[1], 10) - 1, parseInt(e.investedAt.split("-")[2], 10)),
        expectedReturn: e.expectedReturn,
        attachedFiles: e.attachedFiles.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
    }));
}

export async function createCreditUser(newCreditUser: NewCreditUser, progress?: (progress: number) => void): Promise<CreditUser> {
    const args = {
        newCreditUser: {
            userId: newCreditUser.userId,
            creditId: newCreditUser.creditId,
            attachedFiles: newCreditUser.attachedFiles.map(e => ({
                fileData: e.fileData === null || e.fileData === undefined ? null : {
                    bytes: e.fileData.bytes.toString("base64"),
                    name: e.fileData.name,
                },
                file: e.file === null || e.file === undefined ? null : {
                    name: e.file.name,
                    url: e.file.url,
                },
            })),
            investedAmount: newCreditUser.investedAmount || 0,
            investedAt: typeof(newCreditUser.investedAt) === "string" ? new Date(new Date(newCreditUser.investedAt).getTime() - new Date(newCreditUser.investedAt).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newCreditUser.investedAt.getTime() - newCreditUser.investedAt.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            expectedReturn: newCreditUser.expectedReturn,
            fulfilled: newCreditUser.fulfilled === null || newCreditUser.fulfilled === undefined ? null : {
                amount: newCreditUser.fulfilled.amount || 0,
                taxAmount: newCreditUser.fulfilled.taxAmount || 0,
                date: typeof(newCreditUser.fulfilled.date) === "string" ? new Date(new Date(newCreditUser.fulfilled.date).getTime() - new Date(newCreditUser.fulfilled.date).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newCreditUser.fulfilled.date.getTime() - newCreditUser.fulfilled.date.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
        },
    };
    const ret = await makeRequest({name: "createCreditUser", args, progress});
    return {
        id: ret.id,
        credit: {
            id: ret.credit.id,
            image: ret.credit.image === null || ret.credit.image === undefined ? null : {
                thumb: {
                    width: ret.credit.image.thumb.width || 0,
                    height: ret.credit.image.thumb.height || 0,
                    url: ret.credit.image.thumb.url,
                },
                width: ret.credit.image.width || 0,
                height: ret.credit.image.height || 0,
                url: ret.credit.image.url,
            },
            name: ret.credit.name,
            description: ret.credit.description,
            incomeTaxDisclaimer: ret.credit.incomeTaxDisclaimer === null || ret.credit.incomeTaxDisclaimer === undefined ? null : ret.credit.incomeTaxDisclaimer,
            ended: !!ret.credit.ended,
            deadlineDate: new Date(parseInt(ret.credit.deadlineDate.split("-")[0], 10), parseInt(ret.credit.deadlineDate.split("-")[1], 10) - 1, parseInt(ret.credit.deadlineDate.split("-")[2], 10)),
        },
        user: {
            id: ret.user.id,
            documentNumber: ret.user.documentNumber,
            bankAccount: ret.user.bankAccount === null || ret.user.bankAccount === undefined ? null : {
                bank: ret.user.bankAccount.bank,
                agency: ret.user.bankAccount.agency,
                agencyDV: ret.user.bankAccount.agencyDV === null || ret.user.bankAccount.agencyDV === undefined ? null : ret.user.bankAccount.agencyDV,
                account: ret.user.bankAccount.account,
                accountDV: ret.user.bankAccount.accountDV,
            },
            adminUser: {
                id: ret.user.adminUser.id,
                name: ret.user.adminUser.name,
                email: ret.user.adminUser.email,
                phone: ret.user.adminUser.phone === null || ret.user.adminUser.phone === undefined ? null : ret.user.adminUser.phone,
                whatsapp: ret.user.adminUser.whatsapp === null || ret.user.adminUser.whatsapp === undefined ? null : ret.user.adminUser.whatsapp,
            },
            name: ret.user.name,
            email: ret.user.email,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            whatsapp: ret.user.whatsapp === null || ret.user.whatsapp === undefined ? null : ret.user.whatsapp,
        },
        fulfilled: ret.fulfilled === null || ret.fulfilled === undefined ? null : {
            amount: ret.fulfilled.amount || 0,
            taxAmount: ret.fulfilled.taxAmount || 0,
            date: new Date(parseInt(ret.fulfilled.date.split("-")[0], 10), parseInt(ret.fulfilled.date.split("-")[1], 10) - 1, parseInt(ret.fulfilled.date.split("-")[2], 10)),
        },
        investedAmount: ret.investedAmount || 0,
        investedAt: new Date(parseInt(ret.investedAt.split("-")[0], 10), parseInt(ret.investedAt.split("-")[1], 10) - 1, parseInt(ret.investedAt.split("-")[2], 10)),
        expectedReturn: ret.expectedReturn,
        attachedFiles: ret.attachedFiles.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
    };
}

export async function editCreditUser(creditUserId: string, editedCreditUser: EditedCreditUser, progress?: (progress: number) => void): Promise<CreditUser> {
    const args = {
        creditUserId: creditUserId,
        editedCreditUser: {
            attachedFiles: editedCreditUser.attachedFiles.map(e => ({
                fileData: e.fileData === null || e.fileData === undefined ? null : {
                    bytes: e.fileData.bytes.toString("base64"),
                    name: e.fileData.name,
                },
                file: e.file === null || e.file === undefined ? null : {
                    name: e.file.name,
                    url: e.file.url,
                },
            })),
            investedAmount: editedCreditUser.investedAmount || 0,
            investedAt: typeof(editedCreditUser.investedAt) === "string" ? new Date(new Date(editedCreditUser.investedAt).getTime() - new Date(editedCreditUser.investedAt).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editedCreditUser.investedAt.getTime() - editedCreditUser.investedAt.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            expectedReturn: editedCreditUser.expectedReturn,
            fulfilled: editedCreditUser.fulfilled === null || editedCreditUser.fulfilled === undefined ? null : {
                amount: editedCreditUser.fulfilled.amount || 0,
                taxAmount: editedCreditUser.fulfilled.taxAmount || 0,
                date: typeof(editedCreditUser.fulfilled.date) === "string" ? new Date(new Date(editedCreditUser.fulfilled.date).getTime() - new Date(editedCreditUser.fulfilled.date).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editedCreditUser.fulfilled.date.getTime() - editedCreditUser.fulfilled.date.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
        },
    };
    const ret = await makeRequest({name: "editCreditUser", args, progress});
    return {
        id: ret.id,
        credit: {
            id: ret.credit.id,
            image: ret.credit.image === null || ret.credit.image === undefined ? null : {
                thumb: {
                    width: ret.credit.image.thumb.width || 0,
                    height: ret.credit.image.thumb.height || 0,
                    url: ret.credit.image.thumb.url,
                },
                width: ret.credit.image.width || 0,
                height: ret.credit.image.height || 0,
                url: ret.credit.image.url,
            },
            name: ret.credit.name,
            description: ret.credit.description,
            incomeTaxDisclaimer: ret.credit.incomeTaxDisclaimer === null || ret.credit.incomeTaxDisclaimer === undefined ? null : ret.credit.incomeTaxDisclaimer,
            ended: !!ret.credit.ended,
            deadlineDate: new Date(parseInt(ret.credit.deadlineDate.split("-")[0], 10), parseInt(ret.credit.deadlineDate.split("-")[1], 10) - 1, parseInt(ret.credit.deadlineDate.split("-")[2], 10)),
        },
        user: {
            id: ret.user.id,
            documentNumber: ret.user.documentNumber,
            bankAccount: ret.user.bankAccount === null || ret.user.bankAccount === undefined ? null : {
                bank: ret.user.bankAccount.bank,
                agency: ret.user.bankAccount.agency,
                agencyDV: ret.user.bankAccount.agencyDV === null || ret.user.bankAccount.agencyDV === undefined ? null : ret.user.bankAccount.agencyDV,
                account: ret.user.bankAccount.account,
                accountDV: ret.user.bankAccount.accountDV,
            },
            adminUser: {
                id: ret.user.adminUser.id,
                name: ret.user.adminUser.name,
                email: ret.user.adminUser.email,
                phone: ret.user.adminUser.phone === null || ret.user.adminUser.phone === undefined ? null : ret.user.adminUser.phone,
                whatsapp: ret.user.adminUser.whatsapp === null || ret.user.adminUser.whatsapp === undefined ? null : ret.user.adminUser.whatsapp,
            },
            name: ret.user.name,
            email: ret.user.email,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            whatsapp: ret.user.whatsapp === null || ret.user.whatsapp === undefined ? null : ret.user.whatsapp,
        },
        fulfilled: ret.fulfilled === null || ret.fulfilled === undefined ? null : {
            amount: ret.fulfilled.amount || 0,
            taxAmount: ret.fulfilled.taxAmount || 0,
            date: new Date(parseInt(ret.fulfilled.date.split("-")[0], 10), parseInt(ret.fulfilled.date.split("-")[1], 10) - 1, parseInt(ret.fulfilled.date.split("-")[2], 10)),
        },
        investedAmount: ret.investedAmount || 0,
        investedAt: new Date(parseInt(ret.investedAt.split("-")[0], 10), parseInt(ret.investedAt.split("-")[1], 10) - 1, parseInt(ret.investedAt.split("-")[2], 10)),
        expectedReturn: ret.expectedReturn,
        attachedFiles: ret.attachedFiles.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
    };
}

export async function deleteCreditUser(creditUserId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        creditUserId: creditUserId,
    };
    await makeRequest({name: "deleteCreditUser", args, progress});
    return undefined;
}

export async function getCreditLog(logId: string, progress?: (progress: number) => void): Promise<CreditLog> {
    const args = {
        logId: logId,
    };
    const ret = await makeRequest({name: "getCreditLog", args, progress});
    return {
        id: ret.id,
        attachedFiles: ret.attachedFiles.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        title: ret.title,
        text: ret.text,
    };
}

export async function getCreditLogs(creditId: string, pageOffset: number | null, progress?: (progress: number) => void): Promise<CreditLog[]> {
    const args = {
        creditId: creditId,
        pageOffset: pageOffset === null || pageOffset === undefined ? null : pageOffset || 0,
    };
    const ret = await makeRequest({name: "getCreditLogs", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        attachedFiles: e.attachedFiles.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
        createdAt: new Date(parseInt(e.createdAt.split("-")[0], 10), parseInt(e.createdAt.split("-")[1], 10) - 1, parseInt(e.createdAt.split("-")[2], 10)),
        title: e.title,
        text: e.text,
    }));
}

export async function createCreditLog(newCreditLog: NewCreditLog, progress?: (progress: number) => void): Promise<CreditLog> {
    const args = {
        newCreditLog: {
            creditId: newCreditLog.creditId,
            attachedFiles: newCreditLog.attachedFiles.map(e => ({
                fileData: e.fileData === null || e.fileData === undefined ? null : {
                    bytes: e.fileData.bytes.toString("base64"),
                    name: e.fileData.name,
                },
                file: e.file === null || e.file === undefined ? null : {
                    name: e.file.name,
                    url: e.file.url,
                },
            })),
            title: newCreditLog.title,
            text: newCreditLog.text,
        },
    };
    const ret = await makeRequest({name: "createCreditLog", args, progress});
    return {
        id: ret.id,
        attachedFiles: ret.attachedFiles.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        title: ret.title,
        text: ret.text,
    };
}

export async function editCreditLog(creditLogId: string, editedCreditLog: EditedCreditLog, progress?: (progress: number) => void): Promise<CreditLog> {
    const args = {
        creditLogId: creditLogId,
        editedCreditLog: {
            attachedFiles: editedCreditLog.attachedFiles.map(e => ({
                fileData: e.fileData === null || e.fileData === undefined ? null : {
                    bytes: e.fileData.bytes.toString("base64"),
                    name: e.fileData.name,
                },
                file: e.file === null || e.file === undefined ? null : {
                    name: e.file.name,
                    url: e.file.url,
                },
            })),
            title: editedCreditLog.title,
            text: editedCreditLog.text,
        },
    };
    const ret = await makeRequest({name: "editCreditLog", args, progress});
    return {
        id: ret.id,
        attachedFiles: ret.attachedFiles.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        title: ret.title,
        text: ret.text,
    };
}

export async function deleteCreditLog(creditLogId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        creditLogId: creditLogId,
    };
    await makeRequest({name: "deleteCreditLog", args, progress});
    return undefined;
}

export async function getUserCredits(userId: string, pageOffset: number, progress?: (progress: number) => void): Promise<CreditUser[]> {
    const args = {
        userId: userId,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getUserCredits", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        credit: {
            id: e.credit.id,
            image: e.credit.image === null || e.credit.image === undefined ? null : {
                thumb: {
                    width: e.credit.image.thumb.width || 0,
                    height: e.credit.image.thumb.height || 0,
                    url: e.credit.image.thumb.url,
                },
                width: e.credit.image.width || 0,
                height: e.credit.image.height || 0,
                url: e.credit.image.url,
            },
            name: e.credit.name,
            description: e.credit.description,
            incomeTaxDisclaimer: e.credit.incomeTaxDisclaimer === null || e.credit.incomeTaxDisclaimer === undefined ? null : e.credit.incomeTaxDisclaimer,
            ended: !!e.credit.ended,
            deadlineDate: new Date(parseInt(e.credit.deadlineDate.split("-")[0], 10), parseInt(e.credit.deadlineDate.split("-")[1], 10) - 1, parseInt(e.credit.deadlineDate.split("-")[2], 10)),
        },
        user: {
            id: e.user.id,
            documentNumber: e.user.documentNumber,
            bankAccount: e.user.bankAccount === null || e.user.bankAccount === undefined ? null : {
                bank: e.user.bankAccount.bank,
                agency: e.user.bankAccount.agency,
                agencyDV: e.user.bankAccount.agencyDV === null || e.user.bankAccount.agencyDV === undefined ? null : e.user.bankAccount.agencyDV,
                account: e.user.bankAccount.account,
                accountDV: e.user.bankAccount.accountDV,
            },
            adminUser: {
                id: e.user.adminUser.id,
                name: e.user.adminUser.name,
                email: e.user.adminUser.email,
                phone: e.user.adminUser.phone === null || e.user.adminUser.phone === undefined ? null : e.user.adminUser.phone,
                whatsapp: e.user.adminUser.whatsapp === null || e.user.adminUser.whatsapp === undefined ? null : e.user.adminUser.whatsapp,
            },
            name: e.user.name,
            email: e.user.email,
            phone: e.user.phone === null || e.user.phone === undefined ? null : e.user.phone,
            whatsapp: e.user.whatsapp === null || e.user.whatsapp === undefined ? null : e.user.whatsapp,
        },
        fulfilled: e.fulfilled === null || e.fulfilled === undefined ? null : {
            amount: e.fulfilled.amount || 0,
            taxAmount: e.fulfilled.taxAmount || 0,
            date: new Date(parseInt(e.fulfilled.date.split("-")[0], 10), parseInt(e.fulfilled.date.split("-")[1], 10) - 1, parseInt(e.fulfilled.date.split("-")[2], 10)),
        },
        investedAmount: e.investedAmount || 0,
        investedAt: new Date(parseInt(e.investedAt.split("-")[0], 10), parseInt(e.investedAt.split("-")[1], 10) - 1, parseInt(e.investedAt.split("-")[2], 10)),
        expectedReturn: e.expectedReturn,
        attachedFiles: e.attachedFiles.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
    }));
}

export async function getCreditsExport(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "getCreditsExport", args: {}, progress});
    return ret;
}

export async function getUser(userId: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        id: ret.id,
        documentNumber: ret.documentNumber,
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            bank: ret.bankAccount.bank,
            agency: ret.bankAccount.agency,
            agencyDV: ret.bankAccount.agencyDV === null || ret.bankAccount.agencyDV === undefined ? null : ret.bankAccount.agencyDV,
            account: ret.bankAccount.account,
            accountDV: ret.bankAccount.accountDV,
        },
        adminUser: {
            id: ret.adminUser.id,
            name: ret.adminUser.name,
            email: ret.adminUser.email,
            phone: ret.adminUser.phone === null || ret.adminUser.phone === undefined ? null : ret.adminUser.phone,
            whatsapp: ret.adminUser.whatsapp === null || ret.adminUser.whatsapp === undefined ? null : ret.adminUser.whatsapp,
        },
        name: ret.name,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
    };
}

export async function getUsers(pageOffset: number | null, userFilter: UserFilter | null, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffset: pageOffset === null || pageOffset === undefined ? null : pageOffset || 0,
        userFilter: userFilter === null || userFilter === undefined ? null : {
            name: userFilter.name === null || userFilter.name === undefined ? null : userFilter.name,
            email: userFilter.email === null || userFilter.email === undefined ? null : userFilter.email,
        },
    };
    const ret = await makeRequest({name: "getUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        documentNumber: e.documentNumber,
        bankAccount: e.bankAccount === null || e.bankAccount === undefined ? null : {
            bank: e.bankAccount.bank,
            agency: e.bankAccount.agency,
            agencyDV: e.bankAccount.agencyDV === null || e.bankAccount.agencyDV === undefined ? null : e.bankAccount.agencyDV,
            account: e.bankAccount.account,
            accountDV: e.bankAccount.accountDV,
        },
        adminUser: {
            id: e.adminUser.id,
            name: e.adminUser.name,
            email: e.adminUser.email,
            phone: e.adminUser.phone === null || e.adminUser.phone === undefined ? null : e.adminUser.phone,
            whatsapp: e.adminUser.whatsapp === null || e.adminUser.whatsapp === undefined ? null : e.adminUser.whatsapp,
        },
        name: e.name,
        email: e.email,
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        whatsapp: e.whatsapp === null || e.whatsapp === undefined ? null : e.whatsapp,
    }));
}

export async function createUser(user: NewUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        user: {
            adminUserId: user.adminUserId,
            documentNumber: user.documentNumber,
            bankAccount: user.bankAccount === null || user.bankAccount === undefined ? null : {
                bank: user.bankAccount.bank,
                agency: user.bankAccount.agency,
                agencyDV: user.bankAccount.agencyDV === null || user.bankAccount.agencyDV === undefined ? null : user.bankAccount.agencyDV,
                account: user.bankAccount.account,
                accountDV: user.bankAccount.accountDV,
            },
            password: user.password,
            name: user.name,
            email: user.email,
            phone: user.phone === null || user.phone === undefined ? null : user.phone,
            whatsapp: user.whatsapp === null || user.whatsapp === undefined ? null : user.whatsapp,
        },
    };
    const ret = await makeRequest({name: "createUser", args, progress});
    return {
        id: ret.id,
        documentNumber: ret.documentNumber,
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            bank: ret.bankAccount.bank,
            agency: ret.bankAccount.agency,
            agencyDV: ret.bankAccount.agencyDV === null || ret.bankAccount.agencyDV === undefined ? null : ret.bankAccount.agencyDV,
            account: ret.bankAccount.account,
            accountDV: ret.bankAccount.accountDV,
        },
        adminUser: {
            id: ret.adminUser.id,
            name: ret.adminUser.name,
            email: ret.adminUser.email,
            phone: ret.adminUser.phone === null || ret.adminUser.phone === undefined ? null : ret.adminUser.phone,
            whatsapp: ret.adminUser.whatsapp === null || ret.adminUser.whatsapp === undefined ? null : ret.adminUser.whatsapp,
        },
        name: ret.name,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
    };
}

export async function editUser(userId: string, user: EditUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
        user: {
            adminUserId: user.adminUserId,
            bankAccount: user.bankAccount === null || user.bankAccount === undefined ? null : {
                bank: user.bankAccount.bank,
                agency: user.bankAccount.agency,
                agencyDV: user.bankAccount.agencyDV === null || user.bankAccount.agencyDV === undefined ? null : user.bankAccount.agencyDV,
                account: user.bankAccount.account,
                accountDV: user.bankAccount.accountDV,
            },
            name: user.name,
            email: user.email,
            phone: user.phone === null || user.phone === undefined ? null : user.phone,
            whatsapp: user.whatsapp === null || user.whatsapp === undefined ? null : user.whatsapp,
        },
    };
    const ret = await makeRequest({name: "editUser", args, progress});
    return {
        id: ret.id,
        documentNumber: ret.documentNumber,
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            bank: ret.bankAccount.bank,
            agency: ret.bankAccount.agency,
            agencyDV: ret.bankAccount.agencyDV === null || ret.bankAccount.agencyDV === undefined ? null : ret.bankAccount.agencyDV,
            account: ret.bankAccount.account,
            accountDV: ret.bankAccount.accountDV,
        },
        adminUser: {
            id: ret.adminUser.id,
            name: ret.adminUser.name,
            email: ret.adminUser.email,
            phone: ret.adminUser.phone === null || ret.adminUser.phone === undefined ? null : ret.adminUser.phone,
            whatsapp: ret.adminUser.whatsapp === null || ret.adminUser.whatsapp === undefined ? null : ret.adminUser.whatsapp,
        },
        name: ret.name,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
    };
}

export async function deleteUser(userId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        userId: userId,
    };
    await makeRequest({name: "deleteUser", args, progress});
    return undefined;
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
