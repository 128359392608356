// MARK: React
import React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: API
import * as api from "@startapp/apuama-admin-api";

// MARK: Resources
import strings from "../../../../../resources/strings";

// MARK: Components
import { Modal } from "@material-ui/core";
import TextField from "../../../../../components/TextField";
import Button from "../../../../../components/Button";

// MARK: Stores
import CreditPickerStore from "../../../../../stores/CreditsUsersStore/CreditPickerStore";

// MARK: Implementation
interface IProps {
	creditPickerStore: CreditPickerStore;
	credit: api.Credit | null;
	selectCredit: (credit: api.Credit) => void;
	disabled: boolean;
	editing: boolean;
}

@observer
export default class CreditPicker extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.creditPickerStore.getData();
	}

	public render() {
		const { creditPickerStore, disabled, editing, credit, selectCredit } = this.props;
		const { open, toggleOpen, search, handleValueChange } = creditPickerStore;

		return (
			<>
				<Modal open={open} onClose={toggleOpen}>
					<div className="creditPickerWrapper">
						<div className="serachWrapper">
							<TextField
								className="serachTextField"
								label={strings.fields.name}
								name="search"
								value={search}
								onChange={handleValueChange}
								disabled={disabled}
							/>
							<Button
								type="button"
								variant="outlined"
								color="primary"
								onClick={creditPickerStore.refreshData}
								loading={disabled}
							>
								{strings.creditsUsers.editor.changeCredit.filter}
							</Button>
						</div>
						<div className="creditsWrapper">
							{creditPickerStore.rowItems.map((creditRow) => (
								<div key={creditRow.id} className="creditRow">
									<p>{creditRow.name}</p>
									<Button
										type="button"
										variant="outlined"
										color="primary"
										onClick={() => {
											selectCredit(creditRow);
											creditPickerStore.clear();
											creditPickerStore.refreshData();
										}}
										loading={disabled}
									>
										{strings.creditsUsers.editor.changeCredit.pick}
									</Button>
								</div>
							))}
							<div className="paginationWrapper">
								<Button
									type="button"
									variant="text"
									color="primary"
									onClick={creditPickerStore.previousPage}
									loading={disabled}
								>
									{strings.creditsUsers.editor.changeCredit.previous}
								</Button>
								<p>{creditPickerStore.pageOffset + 1}</p>
								<Button
									type="button"
									variant="text"
									color="primary"
									onClick={creditPickerStore.nextPage}
									loading={disabled}
								>
									{strings.creditsUsers.editor.changeCredit.next}
								</Button>
							</div>
						</div>
						<Button
							type="button"
							variant="contained"
							color="primary"
							onClick={toggleOpen}
							loading={disabled}
						>
							{strings.creditsUsers.editor.changeCredit.close}
						</Button>
					</div>
				</Modal>
				<div className="selectedCreditWrapper">
					<h3>{strings.creditsUsers.editor.changeCredit.title}</h3>
					<div className="selectedCreditContent">
						{credit && (
							<p>{credit.name}</p>
						)}
						{!editing && (
							<Button
								type="submit"
								variant="outlined"
								color="primary"
								onClick={toggleOpen}
								loading={disabled}
							>
								{credit ?
									strings.creditsUsers.editor.changeCredit.change :
									strings.creditsUsers.editor.changeCredit.select}
							</Button>
						)}
					</div>
				</div>
			</>
		);
	}
}
