// MARK: React
import React from "react";
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MArk: store
import { routerStore } from "../../../../stores/_rootStore";
import UserStore from "../../../../stores/UserStore";

// MARK: Router
import { match } from "react-router";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Components
import Button from "../../../../components/Button";
import TextField from "../../../../components/TextField";
import { PageTitle } from "../../../../components/PageTitle";
import MaskedTextField from "../../../../components/MaskedTextField";

import AdminUserPicker from "./AdminUserPicker";

// MARK: Implementation
interface IProps {
	userStore: UserStore;
	match: match<{ userId?: string }>;
}

@inject("userStore")
@observer
export default class UserCreateOrEdit extends React.Component<IProps> {
	private createOrEditUser = async () => {
		this.props.userStore.createOrEditUser();
	}

	public componentDidMount = async () => {
		const userId = this.props.match.params.userId;
		if (userId) {
			await this.props.userStore.getUser(userId);
		}
	};

	public render() {
		const { userStore } = this.props;

		return (
			<>
				<div className="userCreateOrEditWrapper">
					<PageTitle text={userStore.selectedUser ? "Editar Usuário" : "Cadastrar Usuário"} />
					<div className="userFormContainer">
						<AdminUserPicker
							adminUserPickerStore={userStore.adminUserPickerStore}
							adminUser={userStore.admin}
							selectAdminUser={userStore.selectAdminUser}
							disabled={userStore.loading}
						/>
						<TextField
							label={strings.fields.name}
							name="name"
							value={userStore.name}
							onChange={userStore.handleValueChange}
							disabled={userStore.usersLoading}
						/>
						<TextField
							label={strings.fields.email}
							name="email"
							value={userStore.email}
							onChange={userStore.handleValueChange}
							disabled={userStore.usersLoading}
						/>
						<MaskedTextField
							label={strings.fields.cpfOrCnpj}
							mask={(() => {
								function onlyNumbersAndTrim(value: string): string {
									return value.replace(/[^0-9]/g, "").trim();
								}

								const cpfMask = "999.999.999-99";
								const cnpjMask = "99.999.999/9999-99";

								const documentNumberNO = onlyNumbersAndTrim(userStore.documentNumber);
								const cpfMaskNO = onlyNumbersAndTrim(cpfMask);

								return documentNumberNO.length >= cpfMaskNO.length ?
									(documentNumberNO.length >= cpfMaskNO.length + 1 ?
										cnpjMask :
										cpfMask + "9"
									) :
									cpfMask;
							})()}
							name="documentNumber"
							value={userStore.documentNumber}
							onChange={userStore.handleValueChange}
							disabled={userStore.usersLoading}
						/>
						<MaskedTextField
							label={strings.fields.phone}
							mask="(99) 9999-9999"
							name="phone"
							value={userStore.phone}
							onChange={userStore.handleValueChange}
							disabled={userStore.usersLoading}
						/>
						<MaskedTextField
							label={strings.fields.whatsapp}
							mask="(99) 99999-9999"
							name="whatsapp"
							value={userStore.whatsapp}
							onChange={userStore.handleValueChange}
							disabled={userStore.usersLoading}
						/>
						<div className="twoFieldsLabel">
							<TextField
								label={strings.fields.agency}
								name="agency"
								type="number"
								className="textFieldLabel"
								value={userStore.agency}
								onChange={userStore.handleValueChange}
								disabled={userStore.usersLoading}
							/>
							<TextField
								label={strings.fields.agencyDV}
								name="agencyDV"
								type="number"
								value={userStore.agencyDV}
								onChange={userStore.handleValueChange}
								disabled={userStore.usersLoading}
							/>
						</div>
						<TextField
							label={strings.fields.bank}
							name="bank"
							value={userStore.bank}
							onChange={userStore.handleValueChange}
							disabled={userStore.usersLoading}
						/>
						<div className="twoFieldsLabel">
							<TextField
								label={strings.fields.account}
								name="account"
								type="number"
								value={userStore.account}
								className="textFieldLabel"
								onChange={userStore.handleValueChange}
								disabled={userStore.usersLoading}
							/>
							<TextField
								label={strings.fields.accountDV}
								name="accountDV"
								type="number"
								value={userStore.accountDV}
								onChange={userStore.handleValueChange}
								disabled={userStore.usersLoading}
							/>
						</div>
						{!userStore.selectedUser && (
							<TextField
								label={strings.password}
								name="password"
								value={userStore.password}
								type="password"
								onChange={userStore.handleValueChange}
								disabled={userStore.usersLoading}
							/>
						)}
					</div>
					<div className="buttons">
						<Button
							type="submit"
							variant="outlined"
							color="primary"
							onClick={() => routerStore.push("/dashboard/users")}
							loading={userStore.usersLoading}
						>
							{strings.register.back}
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							onClick={this.createOrEditUser}
							loading={userStore.usersLoading}
						>
							{strings.register.finish}
						</Button>
					</div>
				</div>
			</>
		);
	}
}
