// MARK: React
import * as React from "react";
import { observer, inject } from "mobx-react";
import "./style.scss";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";

// MARK: Components
import UsersTable from "./UsersTable";
import UserCreateOrEdit from "./UserCreateOrEdit";
import UserDetails from "./UserDetails";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Users extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.authStore.authenticate();
	};

	public render() {
		return (
			<div className="users">
				<Switch>
					<Route exact={true} path="/dashboard/users" component={UsersTable}/>
					<Route path="/dashboard/users/editor/:userId" component={UserCreateOrEdit}/>
					<Route path="/dashboard/users/editor" component={UserCreateOrEdit}/>
					<Route path="/dashboard/users/userDetails/:userId" component={UserDetails}/>
				</Switch>
			</div>
		);
	}
}
