// MARK: React
import * as React from "react";

// MARK: Style
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MARK: Router
import { match } from "react-router";

// MARK: Components
import CircularProgress from "../../../../components/CircularProgress";
import Button from "../../../../components/Button";
import TextField from "../../../../components/TextField";
import ImageCase from "../../../../components/ImageCase";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Stores
import { routerStore } from "../../../../stores/_rootStore";
import AuthStore from "../../../../stores/AuthStore";
import ArticlesStore from "../../../../stores/ArticlesStore";

interface IProps {
	authStore: AuthStore;
	articlesStore: ArticlesStore;
	match: match<{ articleId?: string }>;
}

@inject("authStore", "articlesStore")
@observer
export default class ArticleCreateOrEdit extends React.Component<IProps> {
	private createOrEditArticle = async () => {
		await this.props.articlesStore.createOrEditArticle();
	}

	public componentDidMount = async () => {
		const articleId = this.props.match.params.articleId;

		if (articleId) {
			await this.props.articlesStore.getArticle(articleId);
		}
	};

	public render() {
		const { articlesStore } = this.props;

		return (
			<>
				{articlesStore.articlesLoading ?
					<CircularProgress /> : (
						<div className="articleCreateOrEditWrapper">
							<div className="articleCreateOrEditHeader">
								<h1 className="articleCreateOrEditTitle">{strings.articles.editorTitle(!!articlesStore.selectedArticle)}</h1>
							</div>
							<div className="articleFormContainer">
								<ImageCase
									onAddItem={articlesStore.imageService.selectSingleImage}
									onRemoveItem={articlesStore.imageService.removeImage}
									onReloadItem={articlesStore.imageService.reloadImage}
									imageUploaders={articlesStore.imageService.singleImageUploader}
								/>
								<TextField
									label={strings.fields.title}
									name="title"
									value={articlesStore.title}
									onChange={articlesStore.handleValueChange}
									disabled={articlesStore.articlesLoading}
								/>
								<TextField
									label={strings.fields.text}
									name="text"
									value={articlesStore.text}
									onChange={articlesStore.handleValueChange}
									disabled={articlesStore.articlesLoading}
									multiline={true}
									rows={20}
								/>
							</div>
							<div className="buttons">
								<Button
									type="submit"
									variant="outlined"
									color="primary"
									onClick={() => routerStore.push("/dashboard/articles")}
									loading={articlesStore.articlesLoading}
								>
									{strings.register.back}
								</Button>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									onClick={this.createOrEditArticle}
									loading={articlesStore.articlesLoading}
								>
									{strings.register.finish}
								</Button>
							</div>
						</div>
					)
				}
			</>
		);
	}
}
