// MARK: React
import React from "react";
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";
import { routerStore } from "../../../../stores/_rootStore";
import CreditStore from "../../../../stores/CreditStore";

// MARK: Router
import { match } from "react-router";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Components
import Button from "../../../../components/Button";
import TextField from "../../../../components/TextField";
import DateSelect from "../../../../components/DateSelect";
import { PageTitle } from "../../../../components/PageTitle";
import MaskedTextField from "../../../../components/MaskedTextField";
import ImageCase from "../../../../components/ImageCase";
import ToggleComponent from "../../../../components/ToggleComponent";

// MARK: Implementation
interface IProps {
	creditStore: CreditStore;
	match: match<{ creditId?: string }>;
}

@inject("creditStore")
@observer
export default class CreditCreateOrEdit extends React.Component<IProps> {
	private creditCreateOrEdit = async () => {
		this.props.creditStore.creditCreateOrEdit();
	}

	public componentDidMount = async () => {
		const creditId = this.props.match.params.creditId;
		if (creditId) {
			await this.props.creditStore.getCredit(creditId);
		}
	};

	public render() {
		const { creditStore } = this.props;
		const onEditMode = !!creditStore.selectedCredit;

		return (
			<>
				<div className="creditCreateWrapper">
					<PageTitle text={strings.credits.editor.getTitle(onEditMode)} />
					<div className="creditFormContainer">
						<ImageCase
							imageUploaders={creditStore.imageService.singleImageUploader}
							onAddItem={creditStore.imageService.selectSingleImage}
							onReloadItem={creditStore.imageService.reloadImage}
							onRemoveItem={creditStore.imageService.removeImage}
						/>
						<TextField
							label={strings.fields.name}
							name="name"
							value={creditStore.name}
							onChange={creditStore.handleValueChange}
							disabled={creditStore.creditLoading}
						/>
						<TextField
							label={strings.fields.description}
							name="description"
							value={creditStore.description}
							onChange={creditStore.handleValueChange}
							disabled={creditStore.creditLoading}
						/>
						<ToggleComponent
							checked={creditStore.ended}
							title={strings.fields.ended}
							onChange={creditStore.changeEndedValue}
						/>
						<TextField
							label={strings.fields.incomeTaxDisclaimer}
							name="incomeTaxDisclaimer"
							value={creditStore.incomeTaxDisclaimer}
							onChange={creditStore.handleValueChange}
							disabled={creditStore.creditLoading}
							multiline={true}
							rows={10}
						/>
						<DateSelect
							label={strings.fields.deadlineDate}
							name="deadlineDate"
							value={creditStore.deadlineDate}
							handleChangeDate={creditStore.handleDeadlineDateChange}
							disable={creditStore.creditLoading}
						/>
					</div>
					<div className="buttons">
						<Button
							type="submit"
							variant="outlined"
							color="primary"
							onClick={() => routerStore.push("/dashboard/credits")}
							loading={creditStore.creditLoading}
						>
							{strings.register.back}
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							onClick={this.creditCreateOrEdit}
							loading={creditStore.creditLoading}
						>
							{strings.register.finish}
						</Button>
					</div>
				</div>
			</>
		);
	}
}
