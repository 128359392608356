// MARK: React
import * as React from "react";
import { observer, inject } from "mobx-react";
import "./style.scss";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";

// MARK: Components
import AdminUsersTable from "./AdminUsersTable";
import AdminUserCreateOrEdit from "./AdminUserCreateOrEdit";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class AdminUsers extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.authStore.authenticate();
	};

	public render() {
		return (
			<div className="adminUsers">
				<Switch>
					<Route exact={true} path="/dashboard/adminUsers" component={AdminUsersTable}/>
					<Route path="/dashboard/adminUsers/editor/:adminUserId" component={AdminUserCreateOrEdit}/>
					<Route path="/dashboard/adminUsers/editor" component={AdminUserCreateOrEdit}/>
				</Switch>
			</div>
		);
	}
}
