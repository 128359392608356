import * as React from "react";
import "./style.scss";

// Mobx
import { observer } from "mobx-react";

// Material-UI Components
import { Button as MButton } from "@material-ui/core";
import { Typography as MTypography } from "@material-ui/core";
import { IconButton as MIconButton } from "@material-ui/core";

// Material-UI Icons
import { ChevronLeft as MChevronLeftIcon } from "@material-ui/icons";
import { ChevronRight as MChevronRightIcon } from "@material-ui/icons";
import { Add as MAddIcon } from "@material-ui/icons";
import { Unarchive as MExportIcon } from "@material-ui/icons";

// MARK: Resources
import strings from "../../../resources/strings";
import { CSVLink } from "react-csv";

export interface ICsv {
	filename: string;
	headers: Array<{label: string, key: string}>;
	data: string;
}

interface IProps {
	pageOffset: number;
	onNextClick?: () => void;
	onPrevClick?: () => void;
	onAddClick?: () => void;
	csv?: ICsv;
	numberOfColumns: number;
}

@observer
export default class TableFooter extends React.Component<IProps> {
	public render() {
		const { pageOffset, onNextClick, onPrevClick, onAddClick, csv } = this.props;

		return (
			<div className="footerDiv">
				<div>
					{onAddClick && (
						<MButton
							style={{ marginLeft: 12 }}
							onClick={onAddClick}
							variant="contained"
						>
							<MAddIcon />
							{strings.components.table.create}
						</MButton>
					)}
					{csv && (
						<CSVLink
							data={csv.data}
							headers={csv.headers}
							filename={csv.filename}
							target="_blank"
						>
							<MButton
								style={{ marginLeft: 12 }}
								variant="contained"
							>
								<MExportIcon />
								{strings.components.table.export}
							</MButton>
						</CSVLink>
					)}
				</div>
				{this.props.onNextClick ? (
					<div className="paginationControl">
						<MIconButton onClick={onPrevClick}>
							<MChevronLeftIcon />
						</MIconButton>
						<MTypography variant="subtitle2">{pageOffset}</MTypography>
						<MIconButton onClick={onNextClick}>
							<MChevronRightIcon />
						</MIconButton>
					</div>
				) : (
						null
					)}
			</div>
		);
	}
}
