// MARK: Api
import * as api from "@startapp/apuama-admin-api";

// MARK: Mobx
import { observable, action, computed } from "mobx";
import CreditUsersStore from "./CreditUsersStore";

// MARK: Resources
import strings from "../../resources/strings";

// Mark: LIB
import moment from "moment";

// 	MARK: Types
import { ColumnType, IRowItem } from "../../components/Table/TableRow";
import { default as TableDataStore } from "../../resources/TableDataStore";
import { routerStore, uiStore } from "../_rootStore";
import FileService from "../../services/FileService";
import ImageService from "../../services/ImageService";
import FilterTextService from "../../services/FilterService/FilterTextService";
import FilterBooleanService from "../../services/FilterService/FilterBooleanService";
import { IBadgesFilter } from "../../components/PageTable";

export default class CreditStore extends TableDataStore<api.Credit> {
	// SubStores
	public creditUsersStore: CreditUsersStore;

	// Services
	public filterNameService: FilterTextService;
	public filterEndedService: FilterBooleanService;

	// Constructor
	constructor() {
		super();

		this.creditUsersStore = new CreditUsersStore({
			getCreditId: () => this.selectedCredit ? this.selectedCredit.id : null,
		});

		this.filterNameService = new FilterTextService(this.onFilter);
		this.filterEndedService = new FilterBooleanService(
			this.onFilter,
			strings.credits.filters.isActive,
			strings.credits.filters.isNotActive,
		);
	}

	// MARK: Methods
	protected formatDataToRow(rowItem: api.Credit): IRowItem {
		return {
			id: rowItem.id,
			columns: [
				{ value: rowItem.id },
				{ value: rowItem.name },
				{ value: moment(rowItem.deadlineDate).format(strings.moment.date) },
				{
					value: rowItem.image ? rowItem.image.url : null,
					type: ColumnType.image,
				},
				{ value: rowItem.ended ? "Finalizado" : "Em andamento" },
			],
		};
	}

	protected formatTableHeader(): string[] {
		const header = strings.fields;

		return [
			header.id,
			header.name,
			header.deadlineDate,
			header.image,
			header.expectedReturn,
			header.endedStatus,
		];
	}
	protected getDataItemsPerPage(pageOffset: number): Promise<api.Credit[]> {
		return api.getCredits(pageOffset, this.filter);
	}

	protected getSingleItemById(id: string): Promise<api.Credit> {
		throw new Error("Method not implemented.");
	}

	@action
	public changeEndedValue = () => {
		if (this.ended === false) {
			this.ended = true;
		} else {
			this.ended = false;
		}
	}

	@action
	public redirectToCreditDetails = (creditId: string) => {
		routerStore.push(`/dashboard/credits/creditDetails/${creditId}`);
	};

	@computed
	public get filter(): api.CreditsFilter {
		return {
			name: this.filterNameService.searchText,
			ended: this.filterEndedService.isValue,
		};
	}

	// Filter config
	@computed
	public get badgesFilter(): IBadgesFilter[] {
		let listBadges: IBadgesFilter[] = [];
		if (this.filterNameService.searchText) {
			listBadges = listBadges.concat({
				label: strings.filter.badges.name(this.filterNameService.searchText),
				onClear: this.filterNameService.clear,
			});
		}
		if (this.filterEndedService.isValue !== null) {
			listBadges = listBadges.concat({
				label: strings.filter.badges.endedFilter(this.filterEndedService.isValue),
				onClear: () => {
					this.filterEndedService.clear();
					this.onFilter();
				},
			});
		}

		return listBadges;
	}

	@action
	public onFilter = async () => {
		this.fetchData(0);
	}

	@action
	public onClearFilter = async () => {
		this.filterNameService.clear();
		this.filterEndedService.clear();
		await this.onFilter();
	};

	@action
	public setEditorFields = (credit: api.Credit) => {
		this.clearCredit();
		this.description = credit.description;
		this.name = credit.name;
		this.incomeTaxDisclaimer = credit.incomeTaxDisclaimer;
		this.deadlineDate = credit.deadlineDate;
		this.ended = credit.ended;
		if (credit.image) {
			this.imageService.setSingleImage(credit.image);
		}
	}

	// Mark: HandleChange
	@action
	public handleDeadlineDateChange = (deadlineDate: Date) => {
		this.deadlineDate = deadlineDate;
	}

	// SelectedCredit
	@observable public selectedCredit: api.Credit | null = null;
	@observable public creditLoading: boolean = false;

	@action
	public getCredit = async (creditId: string) => {
		if (this.creditLoading) {
			return;
		}

		this.creditLoading = true;

		try {
			this.selectedCredit = await api.getCredit(creditId);
			this.setEditorFields(this.selectedCredit);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.creditLoading = false;
		}
	};

	// Mark: Credits
	@observable public deadlineDate: Date = new Date();
	@observable public imageService: ImageService = new ImageService();
	@observable public incomeTaxDisclaimer: string | null = null;
	@observable public description: string = "";
	@observable public name: string = "";
	@observable public ended: boolean = false;

	@action
	public creditCreateOrEdit = async () => {
		if (this.creditLoading) {
			return;
		}

		this.creditLoading = true;

		try {
			const uploadedImage = this.imageService.getSingleUploadedImage();

			if (!uploadedImage) {
				uiStore.showErrorSnackbar(strings.users.noFile);
				return;
			}
			if (this.selectedCredit) {
				await api.editCredit(this.selectedCredit.id, {
					deadlineDate: this.deadlineDate,
					description: this.description,
					incomeTaxDisclaimer: this.incomeTaxDisclaimer,
					image: { bytes: null, image: uploadedImage },
					name: this.name,
					ended: this.ended,
				});
				this.clearCredit();
				uiStore.showSnackbar(strings.register.successEditCredit);
			} else {
				await api.createCredit({
					deadlineDate: this.deadlineDate,
					description: this.description,
					incomeTaxDisclaimer: this.incomeTaxDisclaimer,
					image: { bytes: null, image: uploadedImage },
					name: this.name,
					ended: this.ended,
				});
				this.clearCredit();
				uiStore.showSnackbar(strings.register.successCredit);
			}
			routerStore.push("/dashboard/credits");
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.creditLoading = false;
		}
	}

	// Mark: Delete Credit
	@action
	public deleteCreditUser = async (creditUserId: string) => {
		const onConfirm = async () => {
			if (this.loading) {
				return;
			}
			this.loading = true;
			try {
				await api.deleteCreditUser(creditUserId);
				this.rowItems = this.rowItems.filter((credit) => credit.id !== creditUserId);
				uiStore.showSnackbar(strings.components.table.deletedSuccessfully);
			} catch (e) {
				uiStore.showErrorSnackbar(e);
			} finally {
				this.loading = false;
			}
		};

		uiStore.showDialog(
			strings.credits.deleteDialogCredit,
			onConfirm,
		);
	}

	// MARK: Credits Export
	@action
	public getCreditsExport = async () => {
		if (this.loading) {
			return;
		}

		this.loading = true;

		try {
			const csvUrl = await api.getCreditsExport();

			window.open(csvUrl, "blank");
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	}

	// Config Table Credit Logs
	@computed
	public get headerCreditLogs() {
		const header = strings.fields;

		return [
			header.id,
			header.title,
			header.text,
			header.startDate,
		];
	}

	private static formatCreditLogs(creditLog: api.CreditLog): IRowItem {
		return {
			id: creditLog.id,
			columns: [
				{ value: creditLog.id },
				{ value: creditLog.title },
				{ value: creditLog.text.slice(0, 30).concat("...") },
				{ value: moment(creditLog.createdAt).format(strings.moment.date) },
			],
		};
	}

	@computed
	public get creditLogsTableRows(): IRowItem[] {
		return this.creditLogs.map(CreditStore.formatCreditLogs);
	}

	// credits for user
	@observable public creditLogs: api.CreditLog[] = [];
	@observable public creditLogsLoading: boolean = false;
	@observable public pageOffsetCredit: number = 0;

	@action
	public getCreditLogs = async (pageOffset?: number, creditId?: string) => {
		if (this.creditLogsLoading) {
			return;
		}

		this.creditLogsLoading = true;

		if (!pageOffset) {
			pageOffset = 0;
		}
		try {
			if (creditId) {
				this.creditLogs = await api.getCreditLogs(creditId, pageOffset);
				this.pageOffsetCredit = pageOffset;
			}
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.creditLogsLoading = false;
		}
	};

	@action
	public nextPageCredit = async () => {
		await this.getCreditLogs(this.pageOffsetCredit + 1);
	}

	@action
	public previousPageCredit = async () => {
		if (this.pageOffsetCredit > 0) {
			await this.getCreditLogs(this.pageOffsetCredit - 1);
		}
	}

	@action
	public setEditorCreditLog = (creditLog: api.CreditLog) => {
		this.clearCreditLogs();
		this.title = creditLog.title;
		this.text = creditLog.text;
		if (creditLog.attachedFiles) {
			this.fileService.setFiles(creditLog.attachedFiles);
		}
	}

	// Selected CreditLog
	@observable public selectedCreditLog: api.CreditLog | null = null;

	@action
	public getCreditLog = async (creditId: string) => {
		if (this.creditLogsLoading) {
			return;
		}

		this.creditLogsLoading = true;

		try {
			this.selectedCreditLog = await api.getCreditLog(creditId);
			this.setEditorCreditLog(this.selectedCreditLog);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.creditLogsLoading = false;
		}
	};

	// Create Or Edit CreditLogs
	@observable public fileService: FileService = new FileService();
	@observable public title: string = "";
	@observable public attachedFiles: api.UncertainFile[] | null = null;
	@observable public text: string = "";

	@action
	public createOrEditCreditLogs = async (creditId?: string) => {
		if (this.creditLogsLoading) {
			return;
		}

		this.creditLogsLoading = true;

		try {
			const uploadedFile = this.fileService.getUncertainfiedFile();

			if (!uploadedFile.map((file) => file)) {
				uiStore.showErrorSnackbar(strings.users.noFile);
				return;
			}

			if (this.selectedCreditLog) {
				await api.editCreditLog(this.selectedCreditLog.id, {
					title: this.title,
					attachedFiles: uploadedFile.map((file) => {
						return {
							file,
							fileData: null,
						};
					}),
					text: this.text,
				});
				uiStore.showSnackbar(strings.register.editedUser);
			} else if (creditId) {
				await api.createCreditLog({
					title: this.title,
					// tslint:disable-next-line:no-identical-functions
					attachedFiles: uploadedFile.map((file) => {
						return {
							file,
							fileData: null,
						};
					}),
					text: this.text,
					creditId,
				});
				uiStore.showSnackbar(strings.register.creditLogSucess);
			}

			this.clearCreditLogs();
			if (creditId) {
				routerStore.replace(`/dashboard/credits/creditDetails/${creditId}`);
			} else {
				routerStore.replace(`/dashboard/credits`);
			}
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.creditLogsLoading = false;
		}
	}

	// Mark: Delete CreditLog
	@action
	public deleteCreditLogs = async (creditLogId: string) => {
		const onConfirm = async () => {
			if (this.creditLogsLoading) {
				return;
			}
			this.creditLogsLoading = true;
			try {
				await api.deleteCreditLog(creditLogId);
				this.creditLogs = this.creditLogs.filter((creditLog) => creditLog.id !== creditLogId);
				uiStore.showSnackbar(strings.components.table.deletedSuccessfully);
			} catch (e) {
				uiStore.showErrorSnackbar(e);
			} finally {
				this.creditLogsLoading = false;
			}
		};

		uiStore.showDialog(
			strings.credits.deleteDialogCreditLogs,
			onConfirm,
		);
	}

	// MArk: Routes
	@action
	public startCreateOrEdit = (creditId?: string) => {
		if (creditId) {
			routerStore.push(`/dashboard/credits/editor/${creditId}`);
		} else {
			routerStore.push(`/dashboard/credits/editor`);
			this.clearCredit();
			this.selectedCredit = null;
		}
	}

	@action
	public startCreditDetails = (creditId: string) => {
		routerStore.push(`/dashboard/credits/creditDetails/${creditId}`);
	}

	@action
	public startEditCreditLog = (creditLogId: string) => {
		routerStore.push(`/dashboard/credits/creditLog/editor/${creditLogId}`);
	}

	@action
	public redirectCreditLogDetails = (creditLogId: string) => {
		routerStore.push(`/dashboard/credits/creditLogDetails/${creditLogId}`);
	}

	@action
	public startCreateCreditLog = (creditId: string) => {
		this.clearCreditLogs();
		this.selectedCreditLog = null;
		routerStore.push(`/dashboard/credits/creditLog/${creditId}`);
	}

	// MArk: Clear
	@action
	public clearCredit = () => {
		this.deadlineDate = new Date();
		this.description = "";
		this.incomeTaxDisclaimer = "";
		this.name = "";
		this.ended = false;
		this.imageService.clear();
	}

	@action
	public clearCreditLogs = () => {
		this.title = "";
		this.text = "";
		this.fileService.clear();
	}
}
