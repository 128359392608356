// Mobx
import { observable, action } from "mobx";
import { uiStore } from "../../stores/_rootStore";

// Tools
import { openMultipleInputImageDialog, openInputImageDialog } from "../../resources/imageManager";
import ImageUploader from "../../resources/models/ImageUploader";
import { Image } from "@startapp/apuama-admin-api";

export interface IUploadImage {
	imageFile?: File;
	withCrop?: boolean;
}

export default class ImageService {
	// tslint:disable-next-line: max-union-size
	@observable public status: "failed" | "loading" | "success" | "uploading" | "created" = "created";
	@observable public progress: number = 0;

	@observable public loading: boolean = false;
	@observable public imageUploaders: ImageUploader[] = [];
	@observable public singleImageUploader: ImageUploader | null = null;

	@action
	public setImages = (images: Image[]) => {
		this.imageUploaders = images.map((image) => ImageUploader.createUploaded(image));
	}

	@action
	public everythingIsUploaded = () => {
		const found = this.imageUploaders.find((uploader) => uploader.status !== "success");

		if (found) {
			return false;
		}

		if (this.singleImageUploader && this.singleImageUploader.status !== "success") {
			return false;
		}

		return true;
	}

	public getSingleUploadedImage(): Image | null {
		return this.singleImageUploader ? (
			this.singleImageUploader.uploadedImage
		) : null;
	}

	@action
	public getUncertainfiedImages = () => {
		return this.imageUploaders.map((uploader) => uploader.getSendableImage());
	}
	@action
	public getSingleUncertainfiedImage = () => {
		if (this.singleImageUploader) {
			return this.singleImageUploader.getSendableImage();
		}
		return;
	}

	@action
	public removeImage = (fileId: string) => {
		this.imageUploaders = this.imageUploaders.filter((uploader) => uploader.id !== fileId);

		if (this.singleImageUploader && this.singleImageUploader.id === fileId) {
			this.singleImageUploader = null;
		}
	}

	@action
	public reloadImage = async (fileId: string) => {
		try {
			for (const uploader of this.imageUploaders) {
				if (uploader.id === fileId) {
					await uploader.uploadImage();
				}
			}
			if (this.singleImageUploader && this.singleImageUploader.id === fileId) {
				await this.singleImageUploader.uploadImage();
			}
		} catch (error) {
			uiStore.showSnackbar(error.message);
		}
	}

	@action
	public clear = () => {
		this.imageUploaders = [];
		this.singleImageUploader = null;
	}

	@action
	public selectImages = () => {
		openMultipleInputImageDialog(async (imageFiles) => {
			this.imageUploaders = this.imageUploaders.concat(imageFiles.map((imageFile) => {
				return new ImageUploader(imageFile);
			}));

			await Promise.all(this.imageUploaders.map(async (uploader) => {
				if (uploader.imageFile) {
					// The uploader contains a file to upload
					return await uploader.uploadImage();
				}
			}));
		}, (errMsg) => uiStore.showSnackbar(errMsg));
	}

	@action
	public selectSingleImage = async () => {
		openInputImageDialog(async (file) => {
			this.singleImageUploader = new ImageUploader(file);
			await this.singleImageUploader.uploadImage();
		}, (error) => uiStore.showSnackbar(error));
	}

	@action public setSingleImage = (image: Image) => {
		this.singleImageUploader = ImageUploader.createUploaded(image);
	}
}
