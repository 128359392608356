// MARK: Api
import * as api from "@startapp/apuama-admin-api";

// MARK: Mobx
import { observable, action } from "mobx";
import CreditsUserStore from "./CreditsUserStore";
import AdminUserPickerStore from "./AdminUserPickerStore";

// MARK: Stores
import { uiStore, routerStore } from "../_rootStore";

import { IRowItem } from "../../components/Table/TableRow";

// MARK: Resources
import strings from "../../resources/strings";

// 	MARK: Types
import { default as TableDataStore } from "../../resources/TableDataStore";

// MARK: Implementation
export default class UserStore extends TableDataStore<api.User> {
	// SubStores
	public creditsUserStore: CreditsUserStore;
	public adminUserPickerStore: AdminUserPickerStore;

	// Constructor
	constructor() {
		super();

		this.creditsUserStore = new CreditsUserStore({
			getUserId: () => this.selectedUser ? this.selectedUser.id : null,
		});
		this.adminUserPickerStore = new AdminUserPickerStore();
	}

	// MARK: Methods
	protected formatDataToRow(rowItem: api.User): IRowItem {
		return {
			id: rowItem.id,
			columns: [
				{ value: rowItem.id },
				{ value: rowItem.name },
				{ value: rowItem.email },
				{value: rowItem.phone ? rowItem.phone : strings.fields.notRegistered},
				{value: rowItem.whatsapp ? rowItem.whatsapp : strings.fields.notRegistered},
			],
		};
	}

	protected formatTableHeader(): string[] {
		const header = strings.users.table.header;

		return [
			header.id,
			header.name,
			header.email,
			header.phone,
			header.whatsapp,
		];
	}
	protected getDataItemsPerPage(pageOffset: number): Promise<api.User[]> {
		return api.getUsers(pageOffset, null);
	}

	protected getSingleItemById(id: string): Promise<api.User> {
		throw new Error("Method not implemented.");
	}

	@action
	public setEditorFields = (user: api.User) => {
		this.clear();
		this.name = user.name;
		this.email = user.email;
		this.documentNumber = user.documentNumber;
		if (user.whatsapp) {
			this.whatsapp = user.whatsapp;
		}
		if (user.phone) {
			this.phone = user.phone;
		}
		if (user.bankAccount) {
			this.account = user.bankAccount.account;
			this.accountDV = user.bankAccount.accountDV;
			this.agency = user.bankAccount.agency;
			if (user.bankAccount.agencyDV) {
				this.agencyDV = user.bankAccount.agencyDV;
			}
			this.bank = user.bankAccount.bank;
		}

		this.selectAdminUser(user.adminUser);
	}

	// SelectedUser
	@observable public selectedUser: api.User | null = null;

	// Users
	@observable public users: api.User[] = [];
	@observable public usersLoading: boolean = false;

	@action
	public getUser = async (userId: string) => {
		if (this.usersLoading) {
			return;
		}

		this.usersLoading = true;

		try {
			this.selectedUser = await api.getUser(userId);
			this.setEditorFields(this.selectedUser);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.usersLoading = false;
		}
	};

	// Create Or Edit User
	@observable public admin: api.AdminUser | null = null;

	@observable public name: string = "";
	@observable public email: string = "";
	@observable public password: string = "";
	@observable public documentNumber: string = "";
	@observable public phone: string = "";
	@observable public whatsapp: string = "";
	@observable public bankAccount: api.BankAccount | null = null;
	@observable public agencyDV: string = "";
	@observable public bank: string = "";
	@observable public agency: string = "";
	@observable public account: string = "";
	@observable public accountDV: string = "";

	@action
	public selectAdminUser = async (adminUser: api.AdminUser) => {
		this.admin = adminUser;
	}

	@action
	public createOrEditUser = async () => {
		if (this.usersLoading) {
			return;
		}

		if (this.admin === null) {
			uiStore.showSnackbar(strings.register.missingAdmin);
			return;
		}

		this.usersLoading = true;

		try {
			this.bankAccount = {
				bank: this.bank,
				agency: this.agency,
				agencyDV: this.agencyDV,
				account: this.account,
				accountDV: this.accountDV,
			};

			const adminUserDetails = {
				adminUserId: this.admin.id,
				name: this.name,
				email: this.email,
				phone: this.phone === "" ? null : this.phone.replace(/[^0-9]/g, "").trim(),
				whatsapp: this.whatsapp === "" ? null : this.whatsapp.replace(/[^0-9]/g, "").trim(),
				bankAccount: this.bankAccount,
			};

			if (this.selectedUser) {
				await api.editUser(this.selectedUser.id, adminUserDetails);

				uiStore.showSnackbar(strings.register.editedUser);
			} else {
				await api.createUser({
					...adminUserDetails,
					documentNumber: this.documentNumber,
					password: this.password,
				});

				uiStore.showSnackbar(strings.register.successUser);
			}

			this.clear();
			routerStore.replace("/dashboard/users");
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.usersLoading = false;
		}
	}

	// Mark: Delete User
	@action
	public deleteUser = async (userId: string) => {
		const onConfirm = async () => {
			if (this.loading) {
				return;
			}
			this.loading = true;
			try {
				await api.deleteUser(userId);
				this.rowItems = this.rowItems.filter((user) => user.id !== userId);
				uiStore.showSnackbar(strings.components.table.deletedSuccessfully);
			} catch (e) {
				uiStore.showErrorSnackbar(e);
			} finally {
				this.loading = false;
			}
		};

		uiStore.showDialog(
			strings.users.deleteDialogUser,
			onConfirm,
		);
	}

	// MARK: Routes
	@action
	public redirectToUserDetails = (userId: string) => {
		routerStore.push(`/dashboard/users/userDetails/${userId}`);
	};

	@action
	public startUserCreateOrEdit = (userId?: string) => {
		if (userId) {
			routerStore.push(`/dashboard/users/editor/${userId}`);
		} else {
			this.clear();
			this.selectedUser = null;
			routerStore.push("/dashboard/users/editor");
		}
	}

	// Mark: CLear
	@action
	public clear = () => {
		this.name = "";
		this.email = "";
		this.password = "";
		this.documentNumber = "";
		this.account = "";
		this.bank = "";
		this.accountDV = "";
		this.agency = "";
		this.accountDV = "";
		this.phone = "";
		this.whatsapp = "";
	}
}
