// Mobx
import { observable, action } from "mobx";
import { uiStore } from "../../stores/_rootStore";

// Tools
import FileUploader from "../../resources/models/FileUploader";
import * as api from "@startapp/apuama-admin-api";
import { openMultipleInputFileDialog, openInputFileDialog } from "../../resources/fileManager";

export default class FileService {
	// tslint:disable-next-line: max-union-size
	@observable public status: "failed" | "loading" | "success" | "uploading" | "created" = "created";
	@observable public progress: number = 0;

	@observable public loading: boolean = false;
	@observable public fileUploaders: FileUploader[] = [];
	@observable public singleFileUploader: FileUploader | null = null;

	@action
	public setFiles = (files: api.File[]) => {
		this.fileUploaders = files.map((file) => FileUploader.createUploaded(file));
	}

	@action
	public everythingIsUploaded = () => {
		const found = this.fileUploaders.find((uploader) => uploader.status !== "success");

		if (found) {
			return false;
		}

		if (this.singleFileUploader && this.singleFileUploader.status !== "success") {
			return false;
		}

		return true;
	}

	public getSingleUploadedFile(): api.File | null {
		return this.singleFileUploader ? (
			this.singleFileUploader.uploadedFile
		) : null;
	}

	@action
	public getUncertainfiedFile = () => {
		return this.fileUploaders.map((uploader) => uploader.getSendableFile());
	}

	@action
	public getSingleUncertainfiedFile = () => {
		if (this.singleFileUploader) {
			return this.singleFileUploader.getSendableFile();
		}
		return;
	}

	@action
	public removeFile = (fileId: string) => {
		this.fileUploaders = this.fileUploaders.filter((uploader) => uploader.id !== fileId);

		if (this.singleFileUploader && this.singleFileUploader.id === fileId) {
			this.singleFileUploader = null;
		}
	}

	@action
	public reloadFile = async (fileId: string) => {
		try {
			for (const uploader of this.fileUploaders) {
				if (uploader.id === fileId) {
					await uploader.uploadFile();
				}
			}
			if (this.singleFileUploader && this.singleFileUploader.id === fileId) {
				await this.singleFileUploader.uploadFile();
			}
		} catch (error) {
			uiStore.showSnackbar(error.message);
		}
	}

	@action
	public clear = () => {
		this.fileUploaders = [];
		this.singleFileUploader = null;
	}

	@action
	public selectFiles = async () => {
		openMultipleInputFileDialog(async (files) => {
			this.fileUploaders = this.fileUploaders.concat(files.map((file) => {
				return new FileUploader(file);
			}));
			await Promise.all(this.fileUploaders.map(async (uploader) => {
				if (uploader.file) {
					return await uploader.uploadFile();
				}
			}));
		}, (errMsg) => uiStore.showSnackbar(errMsg));
	}

	@action
	public selectSingleFile = async () => {
		openInputFileDialog(async (file) => {
			this.singleFileUploader = new FileUploader(file);
			await this.singleFileUploader.uploadFile();
		}, (error) => uiStore.showSnackbar(error));
	}

	@action public setSingleFile = (file: api.File) => {
		this.singleFileUploader = FileUploader.createUploaded(file);
	}
}
